import React from 'react';
import styles from '../../assets/styles/pages/PaymentSuccessPage.module.scss';
import { Container, Typography } from '@mui/material';
import { PrimaryButton } from '../../components/Buttons';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/images/payment-success.svg';
import { XsOnly } from '../../utils/breakpoints';

const PaymentSuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const pathName: string = searchParams.get('path');
  const transactionType: string = searchParams.get('transaction');

  const navigate = useNavigate();

  const returnToPreviousPage = () => {
    navigate(`${pathName}?cardPayment=true`);
  };

  return (
    <div className={styles['payment-success']}>
      <div className={styles.base}>
        <Container maxWidth="sm">
          <img
            src={logo}
            alt=""
            className={`${styles.image} ${
              XsOnly() ? `${styles['-mobile']}` : ''
            }`}
          />

          <div>
            <Typography variant="body1">
              {transactionType === 'payment'
                ? `Congratulations! Your payment has been successfully processed. Thank you for your purchase. You should receive a confirmation email shortly with details of your order. If you have any questions or concerns, please don't hesitate to contact our customer support team.`
                : 'Congratulations! You successfully added a payment method.'}
            </Typography>
          </div>

          <div className={styles.action}>
            <PrimaryButton
              title="Back to Billings"
              startIcon={<ArrowBackOutlinedIcon />}
              rounded
              handleOnClick={returnToPreviousPage}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
