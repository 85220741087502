import axios from 'axios';
import { StripePaymentMethodForm } from '../../types';
import { apiBaseUrl } from '../api';

export const updateStripePaymentMethod = async (
  paymentMethodId: string,
  params: StripePaymentMethodForm,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/payment/stripe/payment-method/update`,
    params,
    { params: { paymentMethodId } },
  );

  return data?.data;
};
