import React, { FormEvent, useState } from 'react';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import { XsOnly } from '../../utils/breakpoints';
import { Box, Grid, TextField } from '@mui/material';
import FormHeader from './FormHeader';
import { ChangeEventType } from '../../types';
import { PrimaryButton } from '../Buttons';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import { getOpenAiResponse } from '../../services/ai';
import CircularLoading from '../CircularLoading';

const ChatGptForm: React.FC = () => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState<string>(null);
  const [response, setResponse] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({ question: '' });

  const getAiResponse = async (e: FormEvent) => {
    setError({ ...error, question: '' });
    setResponse(null);
    e.preventDefault();
    setLoading(true);
    try {
      const response = await getOpenAiResponse(question);

      setResponse(response.data);
    } catch (error: any) {
      const err = error.response.data;

      if (err?.message) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: err.question,
            type: 'error',
          }),
        );
      } else {
        setError({ ...error, question: err?.question });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={getAiResponse}
      sx={{ width: '100%' }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <CircularLoading loading={loading} />

      <FormHeader title="AI Tools" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            size="small"
            value={question}
            name="question"
            onChange={(e: ChangeEventType) => setQuestion(e.target.value)}
            InputLabelProps={{ shrink: true }}
            multiline
            label="Message"
            minRows={4}
            error={error?.question ? true : false}
            helperText={error?.question ? error?.question : ''}
          />
        </Grid>

        {response ? (
          <Grid item xs={12} sx={{ flexDirection: 'column', display: 'flex' }}>
            <TextField
              fullWidth
              size="small"
              value={response}
              name="response"
              InputLabelProps={{ shrink: true }}
              multiline
              label="Response"
              minRows={4}
              disabled
              sx={{
                '.MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: 'black',
                },
              }}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <PrimaryButton title="Get Response" loading={loading} marginRight5 />

          {response ? (
            <PrimaryButton
              title="Clear"
              handleOnClick={() => setResponse(null)}
              type="button"
              theme="red"
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatGptForm;
