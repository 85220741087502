import { DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { toCurrency } from '../../../utils/numberFormatter';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { defaultProps } from '../../../utils/helpers/TableHelpers';
import { humanizeString } from '../../../utils/stringModifier';

export const budgetReportGroupingColumn = (isMobile: boolean) => {
  const gridGroupColumn: DataGridPremiumProps['groupingColDef'] = {
    ...defaultProps(isMobile, 500),
    headerName: 'Campaigns',
    headerClassName: `${styles.header}`,
    sortable: true,
  };

  return gridGroupColumn;
};

export const budgetReportColumns = (isMobile: boolean) => {
  let columns: GridColDef[] = [
    {
      field: 'level',
      headerClassName: `${styles.header}`,
      headerName: 'Level',
      width: 90,
      renderCell: (params) => {
        const { level } = params.row;

        return <div>{humanizeString(level)}</div>;
      },
    },
    {
      field: 'budget',
      headerClassName: `${styles.header}`,
      headerName: 'Budget',
      width: 90,
      valueGetter: (_, row) => row.budget,
      renderCell: (params) => {
        const { budget } = params.row;

        return <div>{toCurrency('USD', budget)}</div>;
      },
    },
    {
      field: 'percentage',
      headerClassName: `${styles.header}`,
      headerName: 'Percentage',
      width: 100,
      valueGetter: (_, row) => row.percentage,
      renderCell: (params) => {
        const { percentage } = params.row;

        return <div>{`${percentage}%`}</div>;
      },
    },
    {
      field: 'spend',
      headerClassName: `${styles.header}`,
      headerName: 'Spend',
      width: 90,
      valueGetter: (_, row) => row.spend,
      renderCell: (params) => {
        const { spend } = params.row;

        return <div>{toCurrency('USD', spend)}</div>;
      },
    },
    {
      field: 'currentDaily',
      headerClassName: `${styles.header}`,
      headerName: 'Current Daily',
      width: 120,
      valueGetter: (_, row) => row.daily,
      renderCell: (params) => {
        const { currentDaily } = params.row;

        return <div>{toCurrency('USD', currentDaily)}</div>;
      },
    },
    {
      field: 'daily',
      headerClassName: `${styles.header}`,
      headerName: 'Reco. Daily',
      description: 'Recommended Daily',
      width: 120,
      valueGetter: (_, row) => row.daily,
      renderCell: (params) => {
        const { daily } = params.row;

        return <div>{toCurrency('USD', daily)}</div>;
      },
    },
  ];

  return columns;
};
