import axios from 'axios';
import { StripeSubAccountForm, StripeUpdateAccountForm } from '../../types';
import { apiBaseUrl } from '../api';

export const createAgencyStripeAccount = async (
  agencyId: string,
  body: StripeSubAccountForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/sub-account?agencyId=${agencyId}`,
    body,
  );

  return data?.data;
};

export const createBrandStripeAccount = async (
  brandId: string,
  body: StripeSubAccountForm,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/sub-account?brandId=${brandId}`,
    body,
  );

  return data?.data;
};

export const deleteAgencyStripeAccount = async (agencyId: string) => {
  const data = await axios.delete(
    `${apiBaseUrl}/payment/stripe/sub-account?agencyId=${agencyId}`,
  );

  return data?.data;
};

export const deleteBrandStripeAccount = async (brandId: string) => {
  const data = await axios.delete(
    `${apiBaseUrl}/payment/stripe/sub-account?brandId=${brandId}`,
  );

  return data?.data;
};

export const fetchAllStripeAccounts = async () => {
  const data = await axios.get(`${apiBaseUrl}/payment/stripe/sub-accounts`);

  return data?.data;
};

export const fetchSingleStripeAccountByAgency = async (agencyId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/sub-account?agencyId=${agencyId}`,
  );

  return data?.data;
};

export const fetchSingleStripeAccountByBrand = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/sub-account?brandId=${brandId}`,
  );

  return data?.data;
};

export const generateStripeAgencyOnboardingLink = async (agencyId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/sub-account/generate/account-link?agencyId=${agencyId}`,
  );

  return data?.data;
};

export const generateStripeBrandOnboardingLink = async (brandId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/sub-account/generate/account-link?brandId=${brandId}`,
  );

  return data?.data;
};

export const generateStripeAgencyLoginLink = async (agencyId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/sub-account/generate/login-link?agencyId=${agencyId}`,
  );

  return data?.data;
};

export const generateStripeBrandLoginLink = async (brandId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/sub-account/generate/login-link?brandId=${brandId}`,
  );

  return data?.data;
};

export const updateAgencyStripeAccount = async (
  agencyId: string,
  obj: StripeUpdateAccountForm,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/payment/stripe/sub-account?agencyId=${agencyId}`,
    obj,
  );

  return data?.data;
};

export const updateBrandStripeAccount = async (
  brandId: string,
  obj: StripeUpdateAccountForm,
) => {
  const data = await axios.put(
    `${apiBaseUrl}/payment/stripe/sub-account?brandId=${brandId}`,
    obj,
  );

  return data?.data;
};

export const fetchAllStripeAccountPayouts = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/sub-account/payouts`,
    {
      params: {
        brandId,
      },
    },
  );

  return data?.data;
};
