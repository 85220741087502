import React, { FormEvent, useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthForm from '../../components/Forms/AuthForm';
import { AuthContext } from '../../context';
import { ChangeEventType, IUser } from '../../types';
import { createUser } from '../../services/user';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../../services/axios-client';
import { AUTH_TOKEN, IS_DIY_ADZ, LOGGED_IN_USER } from '../../utils';
import styles from '../../assets/styles/components/Forms/AuthForm.module.scss';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

interface DecodeToken {
  email: string;
  invitationId: string;
  roleBasedId: string;
}

const Join: React.FC = () => {
  const reduxDispatch = useDispatch();
  const { token } = useParams();
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [roleBasedId, setRoleBasedId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    password: '',
    email: '',
    confirmPassword: '',
  });

  useEffect(() => {
    const decodeToken = async () => {
      try {
        const decoded: DecodeToken = await jwtDecode(token ? token : '');
        setEmail(decoded.email);
        setRoleBasedId(decoded.roleBasedId);
      } catch (error: any) {
        reduxDispatch(
          toggleAlert({
            toggle: true,
            message: 'Invalid Token',
            type: 'error',
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
    };

    decodeToken();
  }, [token]);

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const obj: IUser = {
        email,
        password,
        token,
        roleBasedId,
        confirmPassword,
        clientUri: window.location.host,
        diy: IS_DIY_ADZ,
      };
      createUser(obj)
        .then(async (data) => {
          if (data) {
            const decode: any = jwtDecode(data.token);
            dispatch({
              type: LOGGED_IN_USER,
              // TODO: need set the user info on payload obj
              payload: {
                token: data.token,
                role: data.user.role,
                roleBasedId: data.user.roleBasedId,
                capabilities: decode.capabilities,
                user: {
                  id: data.user._id,
                  email: data.user.email,
                  page: data.page,
                  brand: data.brand,
                  name: data.name,
                  redirectUrl: data.redirectUrl,
                },
              },
            });
            localStorage.setItem(AUTH_TOKEN, data.token);
            setAuthToken(data.token);
            reduxDispatch(
              toggleAlert({
                toggle: true,
                message: data.message,
              }),
            );

            return data.redirectUrl;
          }
        })
        .then((redirectUrl) => {
          navigate(IS_DIY_ADZ ? '/scorecardz' : redirectUrl, {
            replace: true,
          });
        })
        .catch((error) => {
          const err = error.response.data;
          setErrors({
            ...errors,
            password: err?.password,
            email: err?.email,
            confirmPassword: err?.confirmPassword,
          });

          if (err?.message) {
            reduxDispatch(
              toggleAlert({
                toggle: true,
                message: err?.message,
                type: 'error',
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error: any) {
      console.log(error.message);

      setLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    }
  };

  return (
    <div className={`${styles.join}`}>
      <AuthForm
        handleOnSubmit={handleOnSubmit}
        handleOnChange={handleOnChange}
        title="Join Us"
        email={email}
        password={password}
        isLogin={false}
        isJoin={true}
        errors={errors}
        loading={loading}
        role={null}
        confirmPassword={confirmPassword}
      />
    </div>
  );
};

export default Join;
