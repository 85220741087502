import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { aiReportTableColumns } from './AIReportTableColumns';
import { CustomLoadingOverlay } from '../../Loading';

interface AIReportTableProps {
  insights: any[];
  loading: boolean;
  onOpenInsightz: (insight: any) => void;
  onOpenPreview: (insight: any) => void;
}

const AIReportTable: React.FC<AIReportTableProps> = ({
  insights,
  loading,
  onOpenInsightz,
  onOpenPreview,
}) => {
  const theme = useTheme();
  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const rows = insights.map((insight: any) => {
    return {
      id: insight.id,
      name: insight.name,
      acquisitionScore: insight.acquisitionScore,
      audienceScore: insight.audienceScore,
      adScore: insight.adScore,
      insight,
      onOpenInsightz,
      onOpenPreview,
    };
  });

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={aiReportTableColumns(mdAndDown)}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default AIReportTable;
