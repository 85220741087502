import React from 'react';
import { IconButton } from '@mui/material';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

interface ScrollButtonProps {
  handleScrollDown: () => void;
}

const ScrollButton: React.FC<ScrollButtonProps> = ({ handleScrollDown }) => {
  return (
    <IconButton
      size="medium"
      aria-label="account of current user"
      onClick={handleScrollDown}
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1), 0 8px 20px rgba(0,0,0,0.1)',
        color: 'rgb(0, 132, 255)',
        '&:hover': {
          backgroundColor: '#fff',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1), 0 8px 20px rgba(0,0,0,0.1)',
        },
      }}
    >
      <ArrowDownwardRoundedIcon />
    </IconButton>
  );
};

export default ScrollButton;
