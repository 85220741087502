import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  FbCreativeForm,
  FBLeadGenData,
  FBLeadGenQuestionForm,
  FBLeadQuestionOption,
} from '../../../types';
import FormHeader from '../../Forms/FormHeader';
import Spinner from '../../Spinner';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { ThankYouPageButtonType } from '../../../utils/helpers/LeadHelpers';
import { Objectives } from '../../../utils/constants/facebookAds';

interface FacebookConversionDetailsProps {
  leadForm: FBLeadGenData;
  loading: boolean;
  objective: string;
  creative: FbCreativeForm;
}

const FacebookConversionDetails: React.FC<FacebookConversionDetailsProps> = ({
  leadForm,
  loading,
  objective,
  creative,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormHeader title="CTA" />
      </Grid>

      {loading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : objective === Objectives.OUTCOME_LEADS ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Form ID
            </Typography>

            <Typography variant="body2">{leadForm?.id}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Form Name
            </Typography>

            <Typography variant="body2">{leadForm?.name}</Typography>
          </Grid>

          {leadForm?.questions?.filter(
            (question: FBLeadGenQuestionForm) => question.type === 'CUSTOM',
          )?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Custom Questions
                </Typography>
              </Grid>

              {leadForm?.questions
                ?.filter(
                  (question: FBLeadGenQuestionForm) =>
                    question.type === 'CUSTOM',
                )
                ?.map(
                  (question: FBLeadGenQuestionForm, questionIndex: number) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={`custom-questions-${questionIndex}`}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          {`Question #${questionIndex + 1}: ${question.key}`}
                        </Typography>

                        <Typography variant="body2">
                          {question?.label}
                        </Typography>

                        {question?.options?.map(
                          (
                            option: FBLeadQuestionOption,
                            optionIndex: number,
                          ) => {
                            return (
                              <Typography
                                variant="body2"
                                key={`option-${questionIndex}-${optionIndex}`}
                              >
                                {`- ${option?.value}`}
                              </Typography>
                            );
                          },
                        )}
                      </Grid>
                    );
                  },
                )}
            </>
          ) : null}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {leadForm?.questions?.filter(
            (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
          )?.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Prefilled Questions
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {leadForm?.questions
                  ?.filter(
                    (question: FBLeadGenQuestionForm) =>
                      question.type !== 'CUSTOM',
                  )
                  ?.map(
                    (
                      question: FBLeadGenQuestionForm,
                      questionIndex: number,
                    ) => {
                      return (
                        <Typography
                          variant="body2"
                          key={`prefilled-questions-${questionIndex}`}
                        >
                          {constantStringToHumanized(question.type)}
                        </Typography>
                      );
                    },
                  )}
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" textAlign="center">
              Privacy Policy
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold">
              Privacy Policy Link Text
            </Typography>

            <Typography variant="body2">
              {leadForm?.legal_content?.privacy_policy?.link_text}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold">
              Privacy Policy URL
            </Typography>

            <Typography variant="body2">
              {leadForm?.legal_content?.privacy_policy?.url}
            </Typography>
          </Grid>

          {leadForm?.thank_you_page ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  CTA Action
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  Headline
                </Typography>

                <Typography variant="body2">
                  {leadForm?.thank_you_page?.title}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  Body
                </Typography>

                <Typography variant="body2">
                  {leadForm?.thank_you_page?.body}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  CTA Button Type
                </Typography>

                <Typography variant="body2">
                  {constantStringToHumanized(
                    leadForm?.thank_you_page?.button_type,
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold">
                  CTA Button Text
                </Typography>

                <Typography variant="body2">
                  {constantStringToHumanized(
                    leadForm?.thank_you_page?.button_text,
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {leadForm?.thank_you_page?.button_type ===
                ThankYouPageButtonType.VIEW_WEBSITE ? (
                  <>
                    <Typography variant="body2" fontWeight="bold">
                      Website URL
                    </Typography>

                    <Typography variant="body2">
                      {leadForm?.thank_you_page?.website_url}
                    </Typography>
                  </>
                ) : null}

                {leadForm?.thank_you_page?.button_type ===
                ThankYouPageButtonType.CALL_BUSINESS ? (
                  <>
                    <Typography variant="body2" fontWeight="bold">
                      Country Code
                    </Typography>

                    <Typography variant="body2">
                      {leadForm?.thank_you_page?.country_code}
                    </Typography>

                    <Typography variant="body2" fontWeight="bold">
                      Phone Number
                    </Typography>

                    <Typography variant="body2">
                      {leadForm?.thank_you_page?.business_phone_number}
                    </Typography>
                  </>
                ) : null}
              </Grid>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Headline
            </Typography>

            <Typography variant="body2">
              {creative?.asset_feed_spec?.titles[0]?.text}
            </Typography>
          </Grid>

          {!(
            creative?.asset_feed_spec?.descriptions[0]?.text?.trim() === ''
          ) ? (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Description
              </Typography>

              <Typography variant="body2">
                {creative?.asset_feed_spec?.descriptions[0]?.text}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              CTA Button Text
            </Typography>

            <Typography variant="body2">
              {constantStringToHumanized(
                creative?.asset_feed_spec?.call_to_action_types[0],
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold">
              Website URL
            </Typography>

            <Typography variant="body2">
              {creative?.asset_feed_spec?.link_urls[0]?.website_url}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FacebookConversionDetails;
