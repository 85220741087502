import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Brand,
  ChangeEventType,
  FBLeadGenQuestionForm,
  LeadNotification,
  LeadNotificationBodyField,
} from '../../../../types';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { getPlaceholderValue } from '../../../../utils/helpers/LeadHelpers';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import { PrimaryButton } from '../../../Buttons';
import AddIcon from '@mui/icons-material/Add';
import { validateEmailList } from '../../../../utils/validation';
import { sendTestLeadEmail } from '../../../../services/lead';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import { errorMessageParser } from '../../../../utils/helpers/ToastHelper';
import PopupModal from '../../../Modal';
import ModalHeader from '../../../Modal/ModalHeader';
import { DiySettings } from '../../../../types/IDiy';
import { useNavigate } from 'react-router-dom';
import { createOrUpdateDiySettings } from '../../../../services/diy';
import { DEFAULT_COLOR_THEME } from '../../../../utils/Styling';
import { topfireMediaId } from '../../../../services/api';

interface LeadFormNotificationProps {
  formValues: LeadNotification;
  setFormValues: Dispatch<SetStateAction<LeadNotification>>;
  register: any;
  clearErrors: any;
  errors: any;
  setValue: any;
  setErrors: any;
  questions: FBLeadGenQuestionForm[];
  brand: Brand;
  handleSubmit: any;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  defaultNotification: LeadNotification;
  errorFields: any[];
  setErrorFields: Dispatch<SetStateAction<any>>;
  defaultBodyFields: LeadNotificationBodyField[];
  showClearDefaultFields: boolean;
  setShowClearDefaultFields: Dispatch<SetStateAction<boolean>>;
}

const LeadFormNotification: React.FC<LeadFormNotificationProps> = ({
  formValues,
  setFormValues,
  register,
  clearErrors,
  errors,
  setValue,
  setErrors,
  questions,
  brand,
  handleSubmit,
  diySettings,
  setDiySettings,
  defaultNotification,
  errorFields,
  setErrorFields,
  defaultBodyFields,
  showClearDefaultFields,
  setShowClearDefaultFields,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const [fields, setFields] = useState<string[]>([]);
  const [testEmailLoading, setTestEmailLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const isTFM =
    (brand?.__type === 'location'
      ? brand?.franchisor?.agency?._id
      : brand?.agency?._id) === topfireMediaId;

  useEffect(() => {
    if (questions?.length > 0) {
      buildFields();
    }
  }, [questions]);

  useEffect(() => {
    if (
      diySettings?.defaultEmailRecipients?.filter((setting) => setting !== '')
        ?.length === 0
    ) {
      handleOpen();
    }
  }, [diySettings]);

  useEffect(() => {
    if (errorFields.length > 0) {
      errorFields.forEach((error: any) => {
        setErrors(error.name, {
          type: 'custom',
          message: `Missing question field ${error.field}`,
        });
      });
    }
  }, [errorFields]);

  const buildFields = () => {
    const customQuestions = questions?.map(
      (question: FBLeadGenQuestionForm) => {
        if (question.type === 'CUSTOM') {
          return question.key;
        }

        return question.type.toLowerCase();
      },
    );

    setFields([...customQuestions, 'AD_NAME', 'PLATFORM']);
  };

  const handleOnDrag = (result: any) => {
    let temp: any = [...formValues?.bodyFields];

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [reorderBody] = temp.splice(startIndex, 1);
    temp.splice(endIndex, 0, reorderBody);

    setFormValues({ ...formValues, bodyFields: temp });
  };

  const handleDeleteField = (index: number) => {
    const temp: LeadNotificationBodyField[] = (
      formValues?.bodyFields || []
    ).filter(
      (value: LeadNotificationBodyField, bodyIndex: number) =>
        index !== bodyIndex,
    );

    const name = `body-value-${index}`;
    const errors = errorFields.filter((error: any) => error.name !== name);
    setErrorFields(errors);

    setFormValues({ ...formValues, bodyFields: temp });
  };

  const handleAddField = () => {
    const temp: LeadNotificationBodyField[] = formValues?.bodyFields || [];

    const newField: LeadNotificationBodyField = {
      label: '',
      value: '',
    };

    temp.push(newField);

    setFormValues({ ...formValues, bodyFields: temp });
  };

  const handleOnChange = (e: ChangeEventType, type: string, index: number) => {
    let temp: LeadNotificationBodyField[] = [...(formValues?.bodyFields || [])];
    const name = e.target.name;

    if (type === 'label') {
      temp[index] = {
        ...temp[index],
        label: e.target.value,
      };
    } else {
      const errors = errorFields.filter((error: any) => error.name !== name);
      setErrorFields(errors);
      temp[index] = {
        ...temp[index],
        value: e.target.value,
      };
    }

    setFormValues({ ...formValues, bodyFields: temp });
  };

  const handleSelectField = (value: string, index: number) => {
    let temp: LeadNotificationBodyField[] = [...(formValues?.bodyFields || [])];

    temp[index] = {
      ...temp[index],
      value: `${temp[index]?.value || ''}<<${value}>>`,
    };

    setValue(`body-value-${index}`, temp[index]?.value);
    setFormValues({ ...formValues, bodyFields: temp });
  };

  const getPreviewValue = (fieldValue: string) => {
    const matches = fieldValue?.match(/<<(.+?)>>/g);
    let value: string = fieldValue;

    if (matches) {
      matches.forEach((match) => {
        value = value.replace(match, getPlaceholderValue(match));
      });
    }

    return value;
  };

  const handleTestEmail = async () => {
    setTestEmailLoading(true);
    try {
      if (diySettings?.defaultEmailRecipients?.length === 0) {
        return dispatch(
          toggleAlert({
            toggle: true,
            message: 'Please set your default email recipient first.',
          }),
        );
      }

      const response = await sendTestLeadEmail(
        brand?._id,
        formValues,
        questions,
      );

      dispatch(toggleAlert({ toggle: true, message: response.data.message }));
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setTestEmailLoading(false);
    }
  };

  const handleOnUpdateDiySettings = async () => {
    setLoading(true);
    try {
      const response = await createOrUpdateDiySettings(brand?._id, diySettings);

      setDiySettings(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Default email recipients updated successfully',
        }),
      );
      handleOpen();
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleOnClearDefaultFields = () => {
    setFormValues({
      ...formValues,
      bodyFields: defaultBodyFields,
      subject: `${isTFM ? 'TopFire Media' : 'Sales Chatz'} Facebook Lead: ${
        brand?.__type === 'location'
          ? `${brand?.franchisor?.brand}-${brand?.brand}`
          : brand?.brand
      }`,
    });

    if (errorFields.length > 0) {
      errorFields.forEach((error: any) => {
        clearErrors(error.name);
      });

      setErrorFields([]);
    }

    defaultBodyFields.forEach(
      (field: LeadNotificationBodyField, index: number) => {
        setValue(`body-label-${index}`, field.label);
        setValue(`body-value-${index}`, field.value);
      },
    );

    setShowClearDefaultFields(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              name="defaultEmailRecipients"
              label="Email Recipients"
              value={diySettings?.defaultEmailRecipients?.join(',')}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline
              helperText="Enter list of email addresses separated by comma"
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="bcc"
              label="BCC"
              onChange={(e: ChangeEventType) => {
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value.trim().split(','),
                });
              }}
              value={formValues?.bcc?.join(',')}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText="Enter list of email addresses separated by comma"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...(open
                ? {}
                : register('subject', {
                    required: true,
                  }))}
              fullWidth
              required
              variant="standard"
              type="text"
              name="subject"
              label="Email Subject"
              onChange={(e: ChangeEventType) => {
                clearErrors('subject');
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
              }}
              value={formValues?.subject}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText={errors?.subject && 'Subject is required'}
              error={!!errors.subject}
            />
          </Grid>

          <Grid item xs={12} mt={1}>
            <Typography variant="body2" fontWeight="bold">
              Email Body Fields
            </Typography>
          </Grid>

          {defaultNotification && showClearDefaultFields ? (
            <Grid item xs={12} mt={1}>
              <PrimaryButton
                title="Clear Default Fields"
                type="button"
                handleOnClick={handleOnClearDefaultFields}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} mt={1}>
            <Typography variant="caption">
              {`Configure the body fields for your email. Any text enclosed within
              arrow brackets (<< >>) will be automatically replaced with the
              corresponding lead details. Refer to the mock preview on the right
              section for the expected output.`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DragDropContext onDragEnd={handleOnDrag}>
              <Droppable droppableId="bodyFields">
                {(droppableProvider) => (
                  <ul
                    ref={droppableProvider.innerRef}
                    {...droppableProvider.droppableProps}
                  >
                    {formValues?.bodyFields?.map(
                      (item: LeadNotificationBodyField, index: number) => {
                        return (
                          <Draggable
                            index={index}
                            key={`draggable-${index}`}
                            draggableId={`${index}`}
                          >
                            {(draggableProvider) => (
                              <Grid container spacing={2}>
                                <Grid item xs={12} mt={1}>
                                  <Box
                                    component="div"
                                    ref={draggableProvider.innerRef}
                                    {...draggableProvider.draggableProps}
                                    {...draggableProvider.dragHandleProps}
                                  >
                                    <Card>
                                      <CardContent>
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Grid item xs={1}>
                                            <DragIndicatorOutlinedIcon />
                                          </Grid>

                                          <Grid item xs={11}>
                                            <Grid container spacing={2}>
                                              <Grid item xs={12} sm={6}>
                                                <Autocomplete
                                                  id={`question-field-${index}`}
                                                  renderOption={(
                                                    props,
                                                    option,
                                                  ) => {
                                                    return (
                                                      <li
                                                        {...props}
                                                        key={option}
                                                      >
                                                        {option}
                                                      </li>
                                                    );
                                                  }}
                                                  options={fields}
                                                  value={null}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      {...params}
                                                      variant="standard"
                                                      label="Question Field"
                                                      placeholder="Select a question field"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }}
                                                      fullWidth
                                                      size="small"
                                                    />
                                                  )}
                                                  disableClearable
                                                  onChange={(
                                                    e: any,
                                                    value: any,
                                                  ) =>
                                                    handleSelectField(
                                                      value,
                                                      index,
                                                    )
                                                  }
                                                />
                                              </Grid>

                                              {!xsOnly ? (
                                                <Grid item xs={6} />
                                              ) : null}

                                              <Grid item xs={12}>
                                                <TextField
                                                  {...(open
                                                    ? {}
                                                    : register(
                                                        `body-label-${index}`,
                                                        {
                                                          required: true,
                                                        },
                                                      ))}
                                                  fullWidth
                                                  required
                                                  variant="standard"
                                                  type="text"
                                                  name={`body-label-${index}`}
                                                  label={`Label #${index + 1}`}
                                                  onChange={(
                                                    e: ChangeEventType,
                                                  ) => {
                                                    clearErrors(
                                                      `body-label-${index}`,
                                                    );
                                                    handleOnChange(
                                                      e,
                                                      'label',
                                                      index,
                                                    );
                                                  }}
                                                  value={item.label}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  size="small"
                                                  helperText={
                                                    errors[
                                                      `body-label-${index}`
                                                    ] &&
                                                    `Body label #${
                                                      index + 1
                                                    } is required`
                                                  }
                                                  error={
                                                    !!errors[
                                                      `body-label-${index}`
                                                    ]
                                                  }
                                                />
                                              </Grid>

                                              <Grid item xs={12}>
                                                <TextField
                                                  {...(open
                                                    ? {}
                                                    : register(
                                                        `body-value-${index}`,
                                                        {
                                                          required: true,
                                                        },
                                                      ))}
                                                  fullWidth
                                                  required
                                                  variant="standard"
                                                  type="text"
                                                  name={`body-value-${index}`}
                                                  label={`Value #${index + 1}`}
                                                  multiline
                                                  onChange={(
                                                    e: ChangeEventType,
                                                  ) => {
                                                    clearErrors(
                                                      `body-value-${index}`,
                                                    );
                                                    handleOnChange(
                                                      e,
                                                      'value',
                                                      index,
                                                    );
                                                  }}
                                                  value={item.value}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                  size="small"
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <IconButton
                                                          onClick={() =>
                                                            handleDeleteField(
                                                              index,
                                                            )
                                                          }
                                                        >
                                                          <DeleteIcon
                                                            sx={{
                                                              color: red[500],
                                                            }}
                                                          />
                                                        </IconButton>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  helperText={
                                                    errors[
                                                      `body-value-${index}`
                                                    ]?.message ||
                                                    (errors[
                                                      `body-value-${index}`
                                                    ] &&
                                                      `Body value #${
                                                        index + 1
                                                      } is required`)
                                                  }
                                                  error={
                                                    !!errors[
                                                      `body-value-${index}`
                                                    ]
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </Draggable>
                        );
                      },
                    )}
                    {droppableProvider.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>

          <Grid item xs={12} mt={1}>
            <PrimaryButton
              type="button"
              title="Add Body Field"
              handleOnClick={handleAddField}
              startIcon={<AddIcon />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card sx={{ position: 'sticky', top: 0 }}>
          <CardHeader
            subheader="Email Preview"
            subheaderTypographyProps={{
              textAlign: 'center',
              color: DEFAULT_COLOR_THEME,
              fontWeight: 'bold',
            }}
          />

          <CardContent sx={{ flexDirection: 'column' }}>
            {formValues?.bodyFields?.map(
              (field: LeadNotificationBodyField, index: number) => {
                return (
                  <Typography
                    variant="body1"
                    key={`preview-field-${index}`}
                    sx={{
                      color: !field.value ? 'red' : '',
                      fontWeight: !field.value ? 'bold' : 'normal',
                    }}
                  >
                    {`${field.label}: ${
                      getPreviewValue(field.value) || 'Invalid Value'
                    }`}
                  </Typography>
                );
              },
            )}
          </CardContent>

          <CardActions sx={{ justifyContent: 'center' }}>
            <PrimaryButton
              title="Test Email"
              type="button"
              handleOnClick={handleSubmit(handleTestEmail)}
              loading={testEmailLoading}
              disabled={errorFields.length > 0}
            />
          </CardActions>
        </Card>
      </Grid>

      <PopupModal open={open} handleClose={null}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalHeader title="Default Email Recipients" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center">
              Before proceeding with the setup of your lead notification, please
              first set your default email recipients.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center">
              {`You can also configure this setting by navigating to the 'Preferencez' page.`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...(open
                ? register('defaultEmailRecipients', {
                    required: true,
                    validate: validateEmailList,
                  })
                : {})}
              fullWidth
              variant="standard"
              type="text"
              name="defaultEmailRecipients"
              label="Default Email Recipients"
              onChange={(e: ChangeEventType) => {
                clearErrors('defaultEmailRecipients');
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value.trim().split(','),
                });
              }}
              value={diySettings?.defaultEmailRecipients?.join(',')}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline
              helperText={
                errors?.defaultEmailRecipients?.message ||
                (errors?.defaultEmailRecipients &&
                  'Please enter at least 1 email recipient') ||
                'Enter list of email addresses separated by comma'
              }
              error={!!errors.defaultEmailRecipients}
            />
          </Grid>

          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            <PrimaryButton
              title="Save"
              type="button"
              marginRight5
              handleOnClick={handleSubmit(handleOnUpdateDiySettings)}
              loading={loading}
            />

            <PrimaryButton
              title="Cancel"
              type="button"
              theme="red"
              handleOnClick={() => navigate('/preferencez')}
              loading={loading}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </Grid>
  );
};

export default LeadFormNotification;
