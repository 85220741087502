import { startRange, endRange } from './BrandReportDate';
import { brands, brand, brandFilter, locations, location } from './Brands';
import { campaignProvider, budgetReportProvider } from './Campaigns';
import { toggleAlert } from './Alert';
import { combineReducers } from 'redux';
import { agencies, agency } from './Agencies';

const reducers = combineReducers({
  startRange,
  endRange,
  brands,
  brand,
  toggleAlert,
  campaignProvider,
  agencies,
  agency,
  brandFilter,
  budgetReportProvider,
  location,
  locations,
});

export default reducers;
