import React from 'react';
import styles from '../../assets/styles/pages/NoContent.module.scss';
import { Typography } from '@mui/material';
import noContent from '../../assets/images/no-content.svg';
import restricted from '../../assets/images/restricted.svg';
import noData from '../../assets/images/no-data.svg';
import { XsOnly } from '../../utils/breakpoints';

const NoContent: React.FC = () => {
  return (
    <div className={styles['no-content']}>
      <div className={styles.base}>
        <Typography variant="h4" fontWeight="bold" color="#096F4D">
          404 NOT FOUND
        </Typography>

        <Typography variant="body2" color="#096F4D">
          Sorry! it seems like you are lost. You are not supposed to be here.
        </Typography>

        <img
          src={noContent}
          alt=""
          className={`${styles.image} ${
            XsOnly() ? `${styles['-mobile']}` : ''
          }`}
        />
      </div>
    </div>
  );
};

const RestrictedAccess: React.FC = () => {
  return (
    <div className={styles['no-content']}>
      <div
        className={`${styles.base} ${styles['-restricted']} ${
          XsOnly() ? `${styles['-mobile']}` : ''
        }`}
      >
        <img
          src={restricted}
          alt=""
          className={`${styles.image} ${
            XsOnly() ? `${styles['-mobile']}` : ''
          }`}
        />

        <div className={styles.text}>
          <Typography variant="h4" fontWeight="bold" color="#096F4D">
            403 RESTRICTED ACCESS
          </Typography>

          <Typography variant="body2" color="#096F4D">
            The page you're trying to access has restricted access. Please refer
            to your system administrator.
          </Typography>
        </div>
      </div>
    </div>
  );
};

const NoData: React.FC = () => {
  return (
    <div className={styles['no-content']}>
      <div className={styles.base}>
        <img
          src={noData}
          alt=""
          className={`${styles.image} ${
            XsOnly() ? `${styles['-mobile']}` : ''
          }`}
        />

        <Typography variant="body2" color="#096F4D" fontWeight="bold" mt={2}>
          No Data to Show
        </Typography>
      </div>
    </div>
  );
};

export { NoContent, RestrictedAccess, NoData };
