import React from "react";
import styles from "../../assets/styles/components/Details/BrandAnalyticsDetails.module.scss";
import { AverageResponse, BrandAnalytics } from "../../types";

interface BrandAnalyticsDetailsProps {
  averageResponseTime: AverageResponse;
  analyticsData: BrandAnalytics;
  leadInitiativeRate: number;
  completedEngagementRate: number;
  messengerCampaigns: any;
}

const BrandAnalyticsDetails: React.FC<BrandAnalyticsDetailsProps> = ({
  averageResponseTime,
  analyticsData,
  leadInitiativeRate,
  completedEngagementRate,
  messengerCampaigns,
}) => {
  return (
    <>
      <div className={styles.base}>
        <p className={styles.title}>Engagement</p>
      </div>

      <div className={styles.base}>
        <p className={styles.content}>
          Leads:
          <span className={styles.value}>
            {`${analyticsData?.leadCount || 0}`}
          </span>
        </p>

        {messengerCampaigns ? (
          <p className={styles.content}>
            Cost Per Lead:
            <span className={styles.value}>
              $
              {`${Number(
                analyticsData?.leadCount === 0
                  ? 0
                  : (messengerCampaigns?.totalSpent || 0) /
                      (analyticsData?.leadCount || 0)
              ).toFixed(2)}`}
            </span>
          </p>
        ) : null}
      </div>

      <div className={styles.base}>
        <p className={styles.content}>
          Average Response Time:
          <span className={styles.value}>
            {`${averageResponseTime?.average.toFixed(2) || 0} ${
              averageResponseTime?.unit || ""
            }`}
          </span>
        </p>
      </div>

      <div className={styles.base}>
        <p className={styles.content}>
          # Sent:
          <span className={styles.value}>
            {analyticsData?.partialEngagementCount || 0}
          </span>
        </p>

        <p className={styles.content}>
          # Received:
          <span className={styles.value}>
            {analyticsData?.completedEngagementCount || 0}
          </span>
        </p>
      </div>

      <div className={styles.base}>
        <p className={styles.content}>
          Initiative Rate:
          <span className={styles.value}>{leadInitiativeRate}%</span>
        </p>

        <p className={styles.content}>
          Engagement Rate:
          <span className={styles.value}>{completedEngagementRate}%</span>
        </p>
      </div>
    </>
  );
};

export default BrandAnalyticsDetails;
