import { Card } from '@mui/material';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface LineChartProps {
  trend: string;
  chartData: any;
}

const LineChart: React.FC<LineChartProps> = ({ trend, chartData }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: [`${trend} Day Trend`],
        font: {
          size: 14,
        },
      },
      subtitle: {
        display: true,
        text: `Most recent ${trend} days compared to previous ${trend} days`,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <Card
      sx={{
        padding: '20px',
        minHeight: '400px',
        marginBottom: '20px',
        minWidth: '100%',
        overflowY: 'auto',
      }}
    >
      <Line options={options} data={chartData} />
    </Card>
  );
};

export default LineChart;
