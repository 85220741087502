import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { AgencyErrors, ChangeEventType, ProfileFormValues } from '../../types';
import AgencyLogoField from '../AgencyLogoField';
import { PrimaryButton } from '../Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../PageHeader';
import { XsOnly } from '../../utils/breakpoints';

interface ProfileFormProps {
  handleOnSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  handleOnChange: (e: ChangeEventType) => void;
  formValues: ProfileFormValues;
  isAgency: boolean;
  handleUploadImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveLogo: (public_id: string) => void;
  errors: AgencyErrors;
  isSalesperson: boolean;
  setFormValues: Dispatch<SetStateAction<ProfileFormValues>>;
  editLoading: boolean;
  uploadLoading: boolean;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  handleOnSubmit,
  handleOnChange,
  formValues,
  isAgency,
  handleUploadImage,
  handleRemoveLogo,
  errors,
  isSalesperson,
  setFormValues,
  editLoading,
  uploadLoading,
}) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%' }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <PageHeader title="Profile" />

      <Grid container spacing={2}>
        {isAgency ? (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              variant="standard"
              size="small"
              type="text"
              id="agency"
              name="agency"
              label="Agency"
              onChange={handleOnChange}
              value={formValues?.agency}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
        ) : null}

        {isAgency ? (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              variant="standard"
              size="small"
              type="text"
              id="name"
              name="name"
              label={isAgency ? 'Agency holder name' : 'Name'}
              onChange={handleOnChange}
              value={formValues?.name}
              InputLabelProps={{ shrink: true }}
              disabled={isAgency ? true : false}
            />
          </Grid>
        ) : null}

        {!isAgency ? (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              variant="standard"
              size="small"
              type="text"
              id="firstname"
              name="firstname"
              label="First Name"
              onChange={handleOnChange}
              value={formValues?.firstname}
              InputLabelProps={{ shrink: true }}
              disabled={isAgency ? true : false}
            />
          </Grid>
        ) : null}

        {!isAgency ? (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              variant="standard"
              size="small"
              type="text"
              id="lastname"
              name="lastname"
              label="Last Name"
              onChange={handleOnChange}
              value={formValues?.lastname}
              InputLabelProps={{ shrink: true }}
              disabled={isAgency ? true : false}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            variant="standard"
            size="small"
            type="email"
            id="email"
            name="email"
            label="Email"
            onChange={handleOnChange}
            value={formValues?.email}
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            variant="standard"
            size="small"
            type="text"
            id="cell"
            name="cell"
            label="Cell"
            onChange={handleOnChange}
            value={formValues?.cell}
            InputLabelProps={{ shrink: true }}
            disabled={isAgency ? true : false}
          />
        </Grid>

        {isSalesperson ? (
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="zipcodereq">Receive Lead Notification</FormLabel>

              <RadioGroup
                row
                aria-labelledby="receiveNotification"
                name="receiveNotification"
                value={formValues.receiveNotification}
                onChange={(e: ChangeEventType) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: e.target.value,
                  })
                }
                defaultValue={true}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="Yes"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : null}

        {isAgency ? (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              required
              variant="standard"
              size="small"
              type="text"
              id="url"
              name="url"
              label="URL"
              onChange={handleOnChange}
              value={formValues?.url}
              InputLabelProps={{ shrink: true }}
              disabled={true}
            />
          </Grid>
        ) : null}
      </Grid>

      {isAgency ? (
        <AgencyLogoField
          logo={formValues.logo}
          handleUploadImage={handleUploadImage}
          handleRemoveLogo={handleRemoveLogo}
          errors={errors}
          uploadLoading={uploadLoading}
        />
      ) : null}

      {isAgency ? null : (
        <div className={`${styles.action} ${styles['-unsticky']}`}>
          <PrimaryButton title="Save" loading={editLoading} rounded />
        </div>
      )}
    </Box>
  );
};

export default ProfileForm;
