import React from 'react';
import { Button, Fab } from '@mui/material';
import Spinner from '../Spinner';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

/**
 * Primary Button Component
 * @reference https://mui.com/material-ui/react-button/
 *            https://mui.com/material-ui/api/button/
 */
interface PrimaryButtonProps {
  loading?: boolean;
  title: string;
  variant?: 'text' | 'outlined' | 'contained';
  type?: 'button' | 'submit' | 'reset';
  color?: string;
  handleOnClick?: () => void;
  startIcon?: React.ReactNode | null;
  isMr?: boolean;
  theme?: string;
  marginTop?: string | null;
  disabled?: boolean;
  width?: string | null;
  marginBot20?: boolean;
  marginRight5?: boolean;
  marginRight10?: boolean;
  endIcon?: React.ReactNode | null;
  rounded?: boolean;
  fontSize?: string;
  size?: string;
  flexDirection?: string;
  height?: string | null;
  bold?: boolean | false;
  underlined?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  loading = false,
  title,
  variant,
  type,
  handleOnClick,
  startIcon,
  isMr = false,
  theme = DEFAULT_COLOR_THEME,
  marginTop,
  disabled = false,
  width,
  marginBot20 = false,
  marginRight5 = false,
  marginRight10 = false,
  endIcon,
  rounded = false,
  fontSize = '',
  size = 'small',
  flexDirection = 'row',
  height,
  bold = false,
  underlined = false,
}) => {
  return (
    <Button
      type={type ? type : 'submit'}
      variant={variant ? variant : 'contained'}
      size={size as any}
      onClick={handleOnClick}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      sx={{
        mr: isMr ? 1 : 0,
        backgroundColor: ['outlined', 'text'].includes(variant)
          ? 'transparent'
          : theme,
        color: ['outlined', 'text'].includes(variant) ? theme : '#FFFFFF',
        border: variant !== 'outlined' ? 'none' : `1px solid ${theme}`,
        boxShadow: '0',
        flexDirection: flexDirection as any,
        '&:hover': {
          backgroundColor: ['outlined', 'text'].includes(variant)
            ? 'transparent'
            : theme,
          color: ['outlined', 'text'].includes(variant) ? theme : '#FFFFFF',
          boxShadow: '0',
        },
        marginTop: marginTop ? marginTop : '',
        width: width !== null ? width : '',
        marginBottom: marginBot20 ? '20px' : '',
        marginRight: marginRight5 ? '5px' : marginRight10 ? '10px' : '',
        borderRadius: rounded ? '49px' : '',
        fontSize: fontSize,
        height: height !== null ? height : '',
        fontWeight: bold ? 'bold' : '',
        textDecoration: underlined ? 'underline' : '',
      }}
      disabled={disabled ? true : false}
    >
      {loading ? <Spinner /> : title}
    </Button>
  );
};

/**
 * Floating Button Component
 * @author Angelo David <angelod@codev.com>
 * @since 1.19.1
 * @reference https://mui.com/material-ui/react-floating-action-button/
 *            https://mui.com/material-ui/api/fab/
 */
interface FloatingButtonProps {
  variant?: 'circular' | 'extended' | string;
  onClick?: () => void;
  theme?: string;
  right?: string;
  bottom?: string;
  hoverTheme?: string;
  fixed?: boolean;
  title: string;
  startIcon?: React.ReactNode | null;
  endIcon?: React.ReactNode | null;
  loading?: boolean;
}

export const FloatingButton: React.FC<FloatingButtonProps> = ({
  variant = 'extended',
  onClick,
  theme = DEFAULT_COLOR_THEME,
  right = '60px',
  bottom = '40px',
  hoverTheme,
  fixed = true,
  title,
  startIcon = null,
  endIcon = null,
  loading = false,
}) => {
  return (
    <Fab
      variant={variant as any}
      onClick={onClick}
      sx={{
        backgroundColor: theme,
        color: 'white',
        right,
        bottom,
        position: fixed ? 'fixed' : 'unset',
        '&:hover': {
          backgroundColor: hoverTheme ? hoverTheme : theme,
        },
      }}
    >
      {startIcon ? startIcon : null}

      {loading ? <Spinner /> : title}

      {endIcon ? endIcon : null}
    </Fab>
  );
};
