import {
  constantStringToHumanized,
  humanizeString,
} from '../../../utils/stringModifier';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import moment from 'moment-timezone';
import { CampaignIssueInfo } from '../../../types';
import { HtmlTooltip } from '../../Tooltip';
import { Fragment } from 'react';
import { toCurrency } from '../../../utils/numberFormatter';
import { FbAdStatus } from '../../../utils/helpers/facebookAdsHelper';
import { GridColDef } from '@mui/x-data-grid-premium';

const TIMEZONE: string = 'US/Eastern';

const isRejected = (status: string) => {
  return [
    FbAdStatus.WITH_ISSUES,
    FbAdStatus.DISAPPROVED,
    FbAdStatus.ADSET_PAUSED,
    FbAdStatus.CAMPAIGN_PAUSED,
    FbAdStatus.PAYMENT_FAILED,
  ].includes(status);
};

const isProcessing = (status: string) => {
  return status === FbAdStatus.PROCESSING_PAYMENT;
};

export const adsTableColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        const { name, onOpenPreview, ad, status, issues } = params.row;

        return status === 'WITH_ISSUES' ? (
          <div
            className={`${styles['name']} ${
              isProcessing(status)
                ? styles['-processing']
                : isRejected(status)
                ? styles['-error']
                : ''
            }`}
            onClick={() => {
              onOpenPreview(ad);
            }}
          >
            <HtmlTooltip
              disableInteractive
              title={
                <Fragment>
                  {issues.map((issue: CampaignIssueInfo, index: number) => {
                    return (
                      <>
                        <Box component="div" sx={{ fontWeight: 'bold' }}>
                          {`Issue #${index + 1}`}
                        </Box>

                        <Box component="div" sx={{ fontWeight: 'bold' }}>
                          Error Summary
                        </Box>

                        <Box component="div" sx={{ marginBottom: '10px' }}>
                          {issue.error_summary}
                        </Box>

                        <Box component="div" sx={{ fontWeight: 'bold' }}>
                          Error Type
                        </Box>

                        <Box component="div" sx={{ marginBottom: '10px' }}>
                          {constantStringToHumanized(issue.error_type)}
                        </Box>

                        <Box component="div" sx={{ fontWeight: 'bold' }}>
                          Error Level
                        </Box>

                        <Box component="div" sx={{ marginBottom: '10px' }}>
                          {humanizeString(issue.level)}
                        </Box>

                        <Box component="div" sx={{ fontWeight: 'bold' }}>
                          Error Message
                        </Box>

                        <Box component="div" sx={{ marginBottom: '10px' }}>
                          {issue.error_message}
                        </Box>

                        <Box component="div" sx={{ marginBottom: '10px' }}>
                          <Divider />
                        </Box>
                      </>
                    );
                  })}
                </Fragment>
              }
            >
              {name}
            </HtmlTooltip>
          </div>
        ) : (
          <Tooltip
            title={<Typography variant="body2">{name}</Typography>}
            disableInteractive
          >
            <div
              className={`${styles['name']} ${
                isProcessing(status)
                  ? styles['-processing']
                  : isRejected(status)
                  ? styles['-error']
                  : ''
              }`}
              onClick={() => {
                onOpenPreview(ad);
              }}
            >
              {name}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'description',
      headerClassName: styles.header,
      headerName: 'Description',
      flex: 1,
      renderCell: (params) => {
        const { description, onOpenPreview, ad } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{description}</Typography>}
            disableInteractive
          >
            <div
              className={styles.name}
              onClick={() => {
                onOpenPreview(ad);
              }}
            >
              {description || ' '}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'duration',
      headerClassName: styles.header,
      headerName: 'Duration',
      width: 200,
      valueGetter: (_, row) => row.startTime,
      renderCell: (params) => {
        const { startTime, endTime, ad, onOpenPreview } = params.row;

        return (
          <div
            onClick={() => {
              onOpenPreview(ad);
            }}
            className={styles.name}
          >
            {`${moment
              .unix(startTime as number)
              .tz(TIMEZONE)
              .format('MM-DD-YYYY')} - ${
              endTime
                ? moment
                    .unix(endTime as number)
                    .tz(TIMEZONE)
                    .format('MM-DD-YYYY')
                : 'On-going'
            }`}
          </div>
        );
      },
    },
    {
      field: 'media',
      headerClassName: styles.header,
      headerName: 'Media',
      width: 100,
      renderCell: (params) => {
        const { onOpenPreview, ad, media } = params.row;

        return (
          <div
            onClick={() => {
              onOpenPreview(ad);
            }}
            className={styles.name}
          >
            {humanizeString(media)}
          </div>
        );
      },
    },
    {
      field: 'adsetId',
      headerClassName: styles.header,
      headerName: 'Adset ID',
      width: 200,
      renderCell: (params) => {
        const { onOpenPreview, ad, adsetId } = params.row;

        return (
          <div
            onClick={() => {
              onOpenPreview(ad);
            }}
            className={styles.name}
          >
            {adsetId}
          </div>
        );
      },
    },
    {
      field: 'budget',
      headerClassName: styles.header,
      headerName: 'Budget',
      width: 100,
      renderCell: (params) => {
        const { onOpenPreview, ad, budget } = params.row;

        return (
          <div
            onClick={() => {
              onOpenPreview(ad);
            }}
            className={styles.name}
          >
            {toCurrency('USD', budget)}
          </div>
        );
      },
    },
    {
      field: 'status',
      headerClassName: styles.header,
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const { status, onOpenPreview, ad, isResumable } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {isResumable
                  ? 'Paused for Budget'
                  : constantStringToHumanized(status)}
              </Typography>
            }
            disableInteractive
          >
            <div
              onClick={() => {
                onOpenPreview(ad);
              }}
              className={`${styles['name']} ${
                isProcessing(status)
                  ? styles['-processing']
                  : isRejected(status)
                  ? styles['-error']
                  : ''
              }`}
            >
              {isResumable
                ? 'Paused for Budget'
                : (constantStringToHumanized(status) as any)}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return columns;
};
