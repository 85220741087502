import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { ChangeEventType, StripeSubAccountForm } from '../../../../types';
import { humanizeString } from '../../../../utils/stringModifier';
import AccountActions from '../AccountActions';

interface StripeBankAccountFormProps {
  formValues: StripeSubAccountForm;
  onChange: (e: ChangeEventType) => void;
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  onClose: (account?: any) => void;
  onChangeBankType: (value: string) => void;
}

const StripeBankAccountForm: React.FC<StripeBankAccountFormProps> = ({
  formValues,
  onChange,
  onSubmit,
  activeStep,
  onBack,
  steps,
  onClose,
  onChangeBankType,
}) => {
  return (
    <Box component="form" onSubmit={onSubmit} autoComplete="off">
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="campaignProviders"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option}>
                  {humanizeString(option)}
                </li>
              );
            }}
            options={['company', 'individual']}
            value={formValues?.external_account?.account_holder_type}
            getOptionLabel={(option) => humanizeString(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                name="account_holder_type"
                label="Account Type"
                InputLabelProps={{ shrink: true }}
              />
            )}
            disableClearable
            onChange={async (e: any, value: any, reason, detail) => {
              onChangeBankType(value);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="accountHolderName"
            name="account_holder_name"
            label="Account Name"
            onChange={onChange}
            value={formValues?.external_account?.account_holder_name}
            InputLabelProps={{ shrink: true }}
            size="small"
            autoFocus
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="accountNumber"
            name="account_number"
            label="Account Number"
            onChange={onChange}
            value={formValues?.external_account?.account_number}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="routingNumber"
            name="routing_number"
            label="Routing Number"
            onChange={onChange}
            value={formValues?.external_account?.routing_number}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            disabled
            variant="standard"
            type="text"
            id="accountType"
            name="currency"
            label="Currency"
            onChange={onChange}
            value={formValues?.external_account?.currency}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      </Grid>

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        onCancel={onClose}
        marginTop="20px"
      />
    </Box>
  );
};

export default StripeBankAccountForm;
