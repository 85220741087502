import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import styles from '../../assets/styles/components/Details/CampaignAnalyticsBreakdown.module.scss';
import {
  fetchGoogleCampaignKeywordMetrics,
  fetchGoogleCampaignLocationMetrics,
  fetchGoogleCampaignSearchTermMetrics,
} from '../../services/report';
import {
  Brand,
  GoogleAdsMetricsData,
  GoogleAdsMetricsFields,
} from '../../types';
import { BreakdownType } from '../../utils/constants/breakdownType';
import { buildGoogleTableRows } from '../../utils/helpers/BrandAnalyticsHelper';
import GoogleCampaignBreakdownTable from '../Tables/ReportList/GoogleCampaignBreakdownTable';
import GoogleCampaignOverview from '../Tables/ReportList/GoogleCampaignOverview';
import { Box, Typography } from '@mui/material';

interface GoogleCampaignMetricsProps {
  data: GoogleAdsMetricsData[];
  brand: Brand;
  fromDate: Moment | null;
  toDate: Moment | null;
  refreshToken: string;
}

const GoogleCampaignMetrics: React.FC<GoogleCampaignMetricsProps> = ({
  brand,
  fromDate,
  toDate,
  data,
  refreshToken,
}) => {
  const [locationMetrics, setLocationMetrics] = useState<
    GoogleAdsMetricsFields[]
  >([]);
  const [keywordMetrics, setKeywordMetrics] = useState<
    GoogleAdsMetricsFields[]
  >([]);
  const [searchTermMetrics, setSearchTermMetrics] = useState<
    GoogleAdsMetricsFields[]
  >([]);

  const [locationMetricsLoading, setLocationMetricsLoading] =
    useState<boolean>(false);
  const [keywordMetricsLoading, setKeywordMetricsLoading] =
    useState<boolean>(false);
  const [searchMetricsLoading, setSearchMetricsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    getLocationMetrics();
    getKeywordMetrics();
    getSearchTermMetrics();
  }, []);

  const getLocationMetrics = async () => {
    try {
      setLocationMetricsLoading(true);
      const data = await fetchGoogleCampaignLocationMetrics(
        brand._id,
        refreshToken,
        fromDate,
        toDate,
      );

      setLocationMetrics(data.data);
      setLocationMetricsLoading(false);
    } catch (error: any) {
      setLocationMetricsLoading(false);
      console.log(error.message);
    }
  };

  const getKeywordMetrics = async () => {
    try {
      setKeywordMetricsLoading(true);
      const data = await fetchGoogleCampaignKeywordMetrics(
        brand._id,
        refreshToken,
        fromDate,
        toDate,
      );

      setKeywordMetrics(data.data);
      setKeywordMetricsLoading(false);
    } catch (error: any) {
      setKeywordMetricsLoading(false);
      console.log(error.message);
    }
  };

  const getSearchTermMetrics = async () => {
    try {
      setSearchMetricsLoading(true);
      const data = await fetchGoogleCampaignSearchTermMetrics(
        brand._id,
        refreshToken,
        fromDate,
        toDate,
      );

      setSearchTermMetrics(data.data);
      setSearchMetricsLoading(false);
    } catch (error: any) {
      setSearchMetricsLoading(false);
      console.log(error.message);
    }
  };

  return (
    <div className={styles.base}>
      <div>
        <Typography
          variant="h6"
          fontWeight="bold"
          textAlign="center"
        >{`${brand?.brand} Google Campaigns`}</Typography>
      </div>

      <div>
        <Typography variant="body2" textAlign="center" mb={2}>
          {`${moment(fromDate).format('LL')} - ${moment(toDate).format('LL')}`}
        </Typography>
      </div>

      <Box component="div" sx={{ width: '100%' }}>
        <GoogleCampaignOverview
          rows={['Campaign Name', ...buildGoogleTableRows()]}
          data={data}
          isCampaign
        />
      </Box>

      <Box component="div" className={styles.title}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} Ad Groups`}
        </Typography>
      </Box>

      <Box component="div" sx={{ width: '100%' }}>
        <GoogleCampaignOverview
          rows={['Ad Group Name', ...buildGoogleTableRows()]}
          data={data}
          isAdGroup
        />
      </Box>

      <Box component="div" className={styles.title}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} Ad Groups Keyword Breakdown`}
        </Typography>
      </Box>

      <Box component="div" sx={{ width: '100%' }}>
        <GoogleCampaignBreakdownTable
          data={keywordMetrics}
          breakdownType={BreakdownType.KEYWORD}
          loading={keywordMetricsLoading}
        />
      </Box>

      <Box component="div" className={styles.title}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} Ad Groups Search Intent Breakdown`}
        </Typography>
      </Box>

      <Box component="div" sx={{ width: '100%' }}>
        <GoogleCampaignBreakdownTable
          data={searchTermMetrics}
          breakdownType={BreakdownType.SEARCH_TERM}
          loading={searchMetricsLoading}
        />
      </Box>

      <Box component="div" className={styles.title}>
        <Typography variant="h6" fontWeight="bold" textAlign="center" my={2}>
          {`${brand?.brand} Campaigns Location Breakdown`}
        </Typography>
      </Box>

      <Box component="div" sx={{ width: '100%' }}>
        <GoogleCampaignBreakdownTable
          data={locationMetrics}
          breakdownType={BreakdownType.LOCATION}
          loading={locationMetricsLoading}
        />
      </Box>
    </div>
  );
};

export default GoogleCampaignMetrics;
