import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Divider,
} from '@mui/material';
import { Agency, AgencyErrors, Brand, ChangeEventType } from '../../types';
import AgencyLogoField from '../AgencyLogoField';
import { PrimaryButton } from '../Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import {
  createAgency,
  editAgency,
  getSingleAgency,
} from '../../services/agency';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createExpressTheme, editExpressTheme } from '../../services/theme';
import {
  EDIT_THEME,
  IS_DIY_ADZ,
  ResizerReturnType,
  resizeFile,
} from '../../utils';
import { ExpressThemeContext } from '../../context';
import { deleteLogo, uploadLogo } from '../../services/upload';
import PageHeader from '../PageHeader';
import { XsOnly } from '../../utils/breakpoints';
import { MuiTelInput } from 'mui-tel-input';
import CircularLoading from '../CircularLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAgencies,
  setAgency,
  setBrands,
  setLocation,
  setLocations,
  toggleAlert,
} from '../../redux/actions';
import ConfirmNavigationModal, { usePrompt } from '../Navigation/RoutePrompt';

const AgencyForm: React.FC = () => {
  const agency = useSelector((state: any) => state?.agency?.agency);
  const agencies: Agency[] =
    useSelector((state: any) => state?.agencies?.agencies) || [];
  const brands: Brand[] =
    useSelector((state: any) => state?.brands?.brands) || [];
  const locations: Brand[] =
    useSelector((state: any) => state?.locations?.locations) || [];
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const [searchParams] = useSearchParams();
  const agencyId: string = searchParams.get('agencyId') || null;
  const { dispatch } = useContext(ExpressThemeContext);
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isEdit = pathNames.includes('edit');
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [formValues, setFormValues] = useState<Agency>({
    agency: '',
    name: '',
    email: '',
    cell: '',
    url: '',
    theme: {
      theme: '',
      properties: {
        bgColor: '#000000',
        txtColor: '#ffffff',
      },
    },
    logo: {
      url: '',
      public_id: '',
    },
    fbLoginEmails: [],
    page: null,
    allowSetupPayment: false,
    diy: IS_DIY_ADZ,
  });
  const [fbLoginEmails, setFbLoginEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<AgencyErrors>({
    agency: '',
    cell: '',
    email: '',
    name: '',
    url: '',
    logo: '',
  });
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openConfirmNavModal, setOpenConfirmNavModal] =
    useState<boolean>(false);
  const [autoUnblockingTx, setAutoUnblockingTx] = useState<any>(null);

  usePrompt(setOpenConfirmNavModal, setAutoUnblockingTx, isDirty);

  useEffect(() => {
    setFbLoginEmails(formValues.fbLoginEmails);
  }, [formValues]);

  useEffect(() => {
    if (isEdit && agencyId) getAgency();
  }, [agencyId, isEdit]);

  const getAgency = async () => {
    setFetchLoading(true);
    try {
      const agency: Agency = await getSingleAgency(agencyId);

      setFormValues({
        ...formValues,
        agency: agency.agency,
        name: agency.name,
        email: agency.email,
        cell: agency.cell,
        url: agency.url,
        logo: agency.logo,
        theme: {
          ...formValues.theme,
          theme: agency.theme.theme,
          properties: { ...agency.theme.properties },
        },
        fbLoginEmails: agency.fbLoginEmails,
        page: agency.page,
        allowSetupPayment: agency.allowSetupPayment || false,
        diy: agency.diy || false,
      });
      setFetchLoading(false);
    } catch (error: any) {
      setFetchLoading(false);
      console.log(error);
    }
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setIsDirty(false);
    try {
      if (isEdit) {
        const { updatedAgency, message } = await editAgency(agencyId, {
          ...formValues,
          diy: IS_DIY_ADZ,
        });

        if (!IS_DIY_ADZ) {
          const { updatedTheme } = await editExpressTheme(
            formValues.url.split('.')[0].toLowerCase(),
            {
              ...formValues.theme.properties,
              logo: formValues.logo,
            },
          );

          if (
            window.location.hostname.split('.')[0] === updatedAgency.theme.theme
          ) {
            dispatch({
              type: EDIT_THEME,
              payload: {
                theme: updatedTheme.theme,
                properties: updatedTheme.properties,
              },
            });
          }
        }

        reduxDispatch(
          toggleAlert({
            toggle: true,
            message,
          }),
        );

        let tempAgencies: Agency[] = [];
        agencies.forEach((item: Agency) => {
          if (item._id === updatedAgency._id) {
            item = updatedAgency;
          }

          tempAgencies = [...tempAgencies, item];
        });

        if (agency) {
          reduxDispatch(setAgency(updatedAgency));
        }

        let tempBrands: Brand[] = [];
        brands.forEach((item: Brand) => {
          if (item?.agency?._id === updatedAgency._id) {
            item.agency.logo = updatedAgency?.logo;
          }

          tempBrands = [...tempBrands, item];
        });

        if (agency) {
          reduxDispatch(setAgency(updatedAgency));
        }

        let tempLocations: Brand[] = [];
        if (locations.length > 0) {
          locations.forEach((item: Brand) => {
            if (item?.agency?._id === updatedAgency._id) {
              item.agency.logo = updatedAgency?.logo;
            }

            tempLocations = [...tempLocations, item];
          });
        }

        if (location) {
          let tempLocation: Brand = { ...location };
          tempLocation.agency.logo = updatedAgency?.logo;

          reduxDispatch(setLocation(tempLocation));
        }

        if (brand?.agency?._id === updatedAgency._id) {
          let tempBrand: Brand = { ...brand };
          tempBrand.agency.logo = updatedAgency?.logo;

          reduxDispatch(setLocation(tempBrand));
        }

        reduxDispatch(setAgencies(tempAgencies));
        reduxDispatch(setBrands(tempBrands));
        reduxDispatch(setLocations(tempLocations));
      } else {
        const { agency, message } = await createAgency({
          ...formValues,
          theme: {
            theme: formValues.url.split('.')[0].toLowerCase(),
            properties: {
              ...formValues.theme.properties,
            },
          },
          diy: IS_DIY_ADZ,
        });

        if (!IS_DIY_ADZ) {
          const res = await createExpressTheme({
            theme: formValues.url.split('.')[0].toLowerCase(),
            properties: {
              ...formValues.theme.properties,
              logo: formValues.logo,
            },
          });

          if (window.location.hostname.split('.')[0] === agency.theme.theme) {
            dispatch({
              type: EDIT_THEME,
              payload: {
                theme: res.theme.theme,
                properties: res.theme.properties,
              },
            });
          }
        }

        reduxDispatch(
          toggleAlert({
            toggle: true,
            message,
          }),
        );

        reduxDispatch(setAgencies([...agencies, agency]));
      }

      navigate(IS_DIY_ADZ ? '/agenciez' : '/agencies');
    } catch (error: any) {
      setIsDirty(true);
      const err = error.response.data;
      setErrors({
        ...errors,
        agency: err.agency,
        cell: err.cell,
        email: err.email,
        name: err.name,
        url: err.url,
        logo: err.logo,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    const file = e.target.files[0];
    try {
      setUploadLoading(true);
      const image: ResizerReturnType = await resizeFile(file);
      const res = await uploadLogo(image);
      if (res) {
        setFormValues({ ...formValues, logo: res.logo });
        setUploadLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: res.message,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setUploadLoading(false);
    }
  };

  const handleRemoveLogo = async (public_id: string) => {
    setIsDirty(true);
    try {
      setUploadLoading(true);
      const res = await deleteLogo(public_id);
      if (res) {
        setFormValues({ ...formValues, logo: { url: '', public_id: '' } });
        setUploadLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: res.message,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
      setUploadLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setIsDirty(true);
    if (e.target.name === 'bgColor') {
      setFormValues({
        ...formValues,
        theme: {
          ...formValues.theme,
          properties: {
            ...formValues.theme.properties,
            bgColor: e.target.value,
          },
        },
      });
    } else if (e.target.name === 'txtColor') {
      setFormValues({
        ...formValues,
        theme: {
          ...formValues.theme,
          properties: {
            ...formValues.theme.properties,
            txtColor: e.target.value,
          },
        },
      });
    } else if (e.target.name === 'agency') {
      setFormValues({
        ...formValues,
        agency: e.target.value,
        theme: { ...formValues.theme, theme: e.target.value.toLowerCase() },
      });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  const handleOnChangeNumber = (newValue: string) => {
    setIsDirty(true);
    setFormValues({ ...formValues, cell: newValue.split(' ').join('') });
  };

  const handleAddFbEmails = async (value: string[]) => {
    setIsDirty(true);
    let newFormValue: Agency = { ...formValues };

    newFormValue = { ...newFormValue, fbLoginEmails: value };

    await setFormValues(newFormValue);
  };

  const handleConfirmNavigation = () => {
    autoUnblockingTx.retry();
    setOpenConfirmNavModal(false);
  };

  const handleCancelNavigation = () => {
    setOpenConfirmNavModal(false);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%' }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <CircularLoading loading={fetchLoading} />

      <PageHeader title={isEdit ? 'Edit Agency' : 'Add New Agency'} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Details
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="agency"
            name="agency"
            label="Agency Name"
            onChange={handleOnChange}
            value={formValues.agency}
            InputLabelProps={{ shrink: true }}
            size="small"
            error={errors?.agency ? true : false}
            helperText={errors?.agency ? errors.agency : ''}
            autoFocus
          />
        </Grid>

        {!IS_DIY_ADZ ? (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="name"
              name="name"
              label="Agency Holder Name"
              onChange={handleOnChange}
              value={formValues.name}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.name ? true : false}
              helperText={errors?.name ? errors.name : ''}
            />
          </Grid>
        ) : null}

        {!IS_DIY_ADZ ? (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="email"
              id="email"
              name="email"
              label="Email"
              onChange={handleOnChange}
              value={formValues.email}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.email ? true : false}
              helperText={errors?.email ? errors.email : ''}
            />
          </Grid>
        ) : null}

        {!IS_DIY_ADZ ? (
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              fullWidth
              required
              id="cell"
              name="cell"
              label="Phone Number"
              variant="standard"
              onChange={(newValue: string) => handleOnChangeNumber(newValue)}
              value={formValues?.cell}
              InputLabelProps={{ shrink: true }}
              size="small"
              onlyCountries={['US']}
              defaultCountry="US"
              error={errors?.cell ? true : false}
              helperText={errors?.cell ? errors.cell : ''}
            />
          </Grid>
        ) : null}

        {!IS_DIY_ADZ ? (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="url"
              name="url"
              label="URL"
              onChange={handleOnChange}
              value={formValues.url}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.url ? true : false}
              helperText={errors?.url ? errors.url : ''}
            />
          </Grid>
        ) : null}

        {!IS_DIY_ADZ ? (
          <Grid item xs={12}>
            <Autocomplete
              id="fbLoginEmails"
              multiple
              disableClearable
              freeSolo
              options={[]}
              value={fbLoginEmails}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Facebook Login Emails"
                  placeholder="Enter Facebook Login Emails"
                  name="fbLoginEmails"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={(e: any, value: any[]) => {
                handleAddFbEmails(value);
              }}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid container spacing={2} my={1}>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Logo
          </Typography>
        </Grid>

        <AgencyLogoField
          logo={formValues.logo}
          handleUploadImage={handleUploadImage}
          handleRemoveLogo={handleRemoveLogo}
          errors={errors}
          uploadLoading={uploadLoading}
        />
      </Grid>

      <div className={`${styles.action} ${styles['-unsticky']}`}>
        <PrimaryButton
          title={isEdit ? 'Save' : 'Add'}
          loading={loading}
          size="large"
          rounded
        />

        <PrimaryButton
          title="Cancel"
          type="button"
          handleOnClick={() => navigate(IS_DIY_ADZ ? '/agenciez' : '/agencies')}
          theme="red"
          variant="text"
          size="large"
        />
      </div>

      <ConfirmNavigationModal
        open={openConfirmNavModal}
        onClose={handleCancelNavigation}
        onConfirm={handleConfirmNavigation}
      />
    </Box>
  );
};

export default AgencyForm;
