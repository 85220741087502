import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from 'react-icons/fa';
import { humanizeString } from '../../../utils/stringModifier';

export const renderCCIcon = (cardBrand: string, size?: number) => {
  if (cardBrand === 'amex') {
    return <FaCcAmex size={size} />;
  }
  if (cardBrand === 'diners') {
    return <FaCcDinersClub size={size} />;
  }
  if (cardBrand === 'discover') {
    return <FaCcDiscover size={size} />;
  }
  if (cardBrand === 'jcb') {
    return <FaCcJcb size={size} />;
  }
  if (cardBrand === 'mastercard') {
    return <FaCcMastercard size={size} />;
  }
  if (cardBrand === 'visa') {
    return <FaCcVisa size={size} />;
  }

  return <FaCreditCard size={size} />;
};

export const buildCardBrand = (card: any) => {
  if (card?.brand === 'amex') {
    return 'AmEx';
  }
  if (card?.brand === 'diners') {
    return 'Diners Club International';
  }
  if (card?.brand === 'jcb') {
    return 'JCB';
  }
  if (card?.brand === 'mastercard') {
    return 'MasterCard';
  }
  if (card?.brand === 'unionpay') {
    return 'UnionPay';
  }
  if (card?.brand === 'eftpos_au') {
    return 'EFTPOS';
  }

  return humanizeString(card?.brand);
};

export const subscriptionCalcelationReason = [
  {
    value: 'too_expensive',
    description: `It's too expensive`,
  },
  {
    value: 'missing_features',
    description: 'Some features are missing',
  },
  {
    value: 'switched_service',
    description: `I'm switching to a different service`,
  },
  {
    value: 'unused',
    description: `I don't use the service enough`,
  },
  {
    value: 'customer_service',
    description: 'Customer service was less than expected',
  },
  {
    value: 'too_complex',
    description: 'Ease of use was less than expected',
  },
  {
    value: 'low_quality',
    description: 'Quality was less than expected',
  },
  { value: 'other', description: 'Other reason' },
];
