import axios from 'axios';
import {
  StripeCreateSetupIntentForm,
  StripePaymentIntentForm,
} from '../../types';
import { apiBaseUrl } from '../api';

export const createStripePaymentIntent = async (
  params: StripePaymentIntentForm,
  role: string,
  brandId: string,
  userId: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/request?userId=${userId}&brandId=${brandId}&role=${role}`,
    params,
  );

  return data?.data;
};

export const createStripeSetupIntent = async (
  params: StripeCreateSetupIntentForm,
  brandId: string,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/intent/set-up?brandId=${brandId}`,
    params,
  );

  return data?.data;
};
