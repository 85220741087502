import { Box } from '@mui/material';
import React from 'react';
import { Fee } from '../../../../types';
import AccountActions from '../AccountActions';
import SubscriptionFees from '../SubscriptionFees';

interface StripeSubscriptionFeesProps {
  brandFees: Fee[];
  onNext: () => void;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  feeTotal: number;
  onClose: () => void;
}

const StripeSubscriptionFees: React.FC<StripeSubscriptionFeesProps> = ({
  brandFees,
  onNext,
  onBack,
  activeStep,
  steps,
  feeTotal,
  onClose,
}) => {
  return (
    <Box component="form" onSubmit={onNext} autoComplete="off">
      <SubscriptionFees brandFees={brandFees} total={feeTotal} subscription />

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        marginTop="20px"
        onCancel={onClose}
      />
    </Box>
  );
};

export default StripeSubscriptionFees;
