import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Brand,
  ChangeEventType,
  Salesperson,
  SalespersonErrors,
} from '../../types';
import {
  createSalesperson,
  editSalesperson,
  getSingleSalesperson,
} from '../../services/salesperson';
import {
  BRAND_MANAGER,
  IS_DIY_ADZ,
  MARKETING_MANAGER,
  SALESPERSON,
} from '../../utils';
import { MuiTelInput } from 'mui-tel-input';
import { PrimaryButton } from '../Buttons';
import { toggleAlert } from '../../redux/actions';
import Spinner from '../Spinner';
import { UserCapability } from '../../context/authContext';

interface BrandUserFormProps {
  isLocation?: boolean;
  salespersons?: Salesperson[];
  setSalespersons?: Dispatch<SetStateAction<Salesperson[]>>;
  managers?: Salesperson[];
  setManagers?: Dispatch<SetStateAction<Salesperson[]>>;
  brandFormValues?: Brand;
  setBrandFormValues?: Dispatch<SetStateAction<Brand>>;
  role: string;
  onCloseSalespersonForm: () => void;
  onCloseManagerForm: () => void;
  marketingManagers?: Salesperson[];
  setMarketingManagers?: Dispatch<SetStateAction<Salesperson[]>>;
  onCloseMultipleForm?: (index: number) => void;
  multiple?: boolean;
  index?: number;
  edit?: boolean;
  salespersonId?: string;
  capabilities?: UserCapability;
  isMarketingManager?: boolean;
  isAdmin?: boolean;
}

const BrandUserForm: React.FC<BrandUserFormProps> = ({
  salespersons,
  setSalespersons,
  managers,
  setManagers,
  isLocation = false,
  brandFormValues,
  setBrandFormValues,
  role,
  onCloseSalespersonForm,
  onCloseManagerForm,
  marketingManagers,
  setMarketingManagers,
  onCloseMultipleForm,
  multiple = false,
  index,
  edit = false,
  salespersonId,
  capabilities,
  isMarketingManager = false,
  isAdmin = false,
}) => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const [formValues, setFormValues] = useState<Salesperson>({
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    cell: '',
    broker: false,
    investment: 10,
    role,
    active: true,
    brand: isLocation ? brand?._id : null,
    diy: IS_DIY_ADZ,
    capabilities: IS_DIY_ADZ
      ? {
          approveTemplate: false,
          createTemplate: false,
          createAds: false,
          viewReports: true,
          addLocations: false,
          addUser: false,
        }
      : null,
  });
  const [errors, setErrors] = useState<SalespersonErrors>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  useEffect(() => {
    if (edit && salespersonId) {
      getSalesperson();
    }
  }, [edit, salespersonId]);

  const getSalesperson = async () => {
    setFetchLoading(true);
    try {
      const response = await getSingleSalesperson(salespersonId);

      setFormValues({
        ...response,
        capabilities: response?.capabilities
          ? {
              ...response?.capabilities,
            }
          : {
              approveTemplate: false,
              createTemplate: false,
              createAds: false,
              viewReports: true,
              addLocations: false,
              addUser: false,
            },
      });
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnChangeNumber = (newValue: string) => {
    setFormValues({ ...formValues, cell: newValue.split(' ').join('') });
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      let salesperson: any;
      let message: any;

      if (edit) {
        const response = await editSalesperson(formValues, salespersonId);

        salesperson = response.updatedSalesperson;
        message = response.message;
      } else {
        const response = await createSalesperson({
          ...formValues,
        });

        salesperson = response.salesperson;
        message = response.message;
      }

      const params: Salesperson = {
        _id: salesperson._id,
        name: salesperson.name,
        salespersonId: salesperson.salespersonId,
        user: salesperson.user,
        email: salesperson.email,
        role: salesperson.role,
        capabilities: salesperson.capabilities,
      };

      if (salesperson.role === SALESPERSON) {
        let salespersonsData: any[] = [];
        if (edit) {
          salespersons.forEach((data: any) => {
            if (data._id === params._id) {
              data = params;
            }

            salespersonsData = [...salespersonsData, data];
          });
        } else {
          salespersonsData = [...salespersons, params];
        }

        setSalespersons(salespersonsData);

        if (multiple) {
          if (edit) {
            let multipleUsers: any[] = [];

            brandFormValues?.users?.forEach((user: any) => {
              if (user._id === params._id) {
                user = params;
              }

              multipleUsers = [...multipleUsers, user];
            });

            setBrandFormValues({
              ...brandFormValues,
              users: multipleUsers,
            });
          } else {
            setBrandFormValues({
              ...brandFormValues,
              users: [...(brandFormValues?.users || []), params],
            });
          }

          onCloseMultipleForm(index);
        } else {
          setBrandFormValues({ ...brandFormValues, salesperson: params });

          onCloseSalespersonForm();
        }
      } else if (salesperson.role === BRAND_MANAGER) {
        setBrandFormValues({ ...brandFormValues, manager: params });
        setManagers([...managers, params]);
        onCloseManagerForm();
      } else if (salesperson.role === MARKETING_MANAGER) {
        let marketingManagerData: any[] = [];
        if (edit) {
          marketingManagers.forEach((data: any) => {
            if (data._id === params._id) {
              data = params;
            }

            marketingManagerData = [...marketingManagerData, data];
          });
        } else {
          marketingManagerData = [...marketingManagers, params];
        }

        setMarketingManagers(marketingManagerData);

        if (multiple) {
          if (edit) {
            let multipleUsers: any[] = [];

            brandFormValues?.users?.forEach((user: any) => {
              if (user._id === params._id) {
                user = params;
              }

              multipleUsers = [...multipleUsers, user];
            });

            setBrandFormValues({
              ...brandFormValues,
              users: multipleUsers,
            });
          } else {
            setBrandFormValues({
              ...brandFormValues,
              users: [...(brandFormValues?.users || []), params],
            });
          }

          onCloseMultipleForm(index);
        } else {
          setBrandFormValues({ ...brandFormValues, marketingManager: params });

          onCloseSalespersonForm();
        }

        onCloseMultipleForm(index);
      }

      dispatch(
        toggleAlert({
          toggle: true,
          message,
        }),
      );
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (error.response) {
        const err = error.response.data;
        setErrors({
          ...errors,
          firstName: err.firstname,
          lastName: err.lastname,
          email: err.email,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">{`Create ${
          IS_DIY_ADZ
            ? 'User'
            : role === SALESPERSON
            ? `${IS_DIY_ADZ ? 'User' : 'Salesperson'}`
            : role === MARKETING_MANAGER
            ? 'Marketing Manager'
            : 'Manager'
        }:`}</Typography>
      </Grid>

      {fetchLoading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              fullWidth
              required
              variant="standard"
              type="text"
              id="firstname"
              name="firstname"
              label="First Name"
              onChange={handleOnChange}
              value={formValues.firstname}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.firstName ? true : false}
              helperText={errors?.firstName ? errors.firstName : ''}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="lastname"
              name="lastname"
              label="Last Name"
              onChange={handleOnChange}
              value={formValues.lastname}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.lastName ? true : false}
              helperText={errors?.lastName ? errors.lastName : ''}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="email"
              id="email"
              name="email"
              label="Email"
              onChange={handleOnChange}
              value={formValues.email}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={errors?.email ? true : false}
              helperText={errors?.email ? errors.email : ''}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MuiTelInput
              fullWidth
              id="cell"
              name="cell"
              label="Phone Number"
              variant="standard"
              onChange={(newValue: string) => handleOnChangeNumber(newValue)}
              value={formValues?.cell}
              InputLabelProps={{ shrink: true }}
              size="small"
              onlyCountries={['US']}
              defaultCountry="US"
            />
          </Grid>

          {IS_DIY_ADZ ? (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">User Roles</Typography>
              </Grid>

              <Grid item xs={12}>
                {(brandFormValues?.__type === 'brand' &&
                  capabilities?.viewReports) ||
                isAdmin ||
                brandFormValues?.__type === 'location' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.viewReports}
                      />
                    }
                    name="viewReports"
                    label="View reports"
                    value={formValues?.capabilities?.viewReports}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {(brandFormValues?.__type === 'brand' &&
                  capabilities?.createTemplate) ||
                isAdmin ||
                brandFormValues?.__type === 'location' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={
                          formValues?.capabilities?.createTemplate
                        }
                      />
                    }
                    name="createTemplate"
                    label="Create template"
                    value={formValues?.capabilities?.createTemplate}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {(brandFormValues?.__type === 'brand' &&
                  capabilities?.approveTemplate) ||
                isAdmin ||
                brandFormValues?.__type === 'location' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={
                          formValues?.capabilities?.approveTemplate
                        }
                      />
                    }
                    name="approveTemplate"
                    label="Approve template"
                    value={formValues?.capabilities?.approveTemplate}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {(brandFormValues?.__type === 'brand' &&
                  capabilities?.addLocations) ||
                isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.addLocations}
                      />
                    }
                    name="addLocations"
                    label="Add locations"
                    value={formValues?.capabilities?.addLocations}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {(brandFormValues?.__type === 'brand' &&
                  capabilities?.createAds) ||
                isAdmin ||
                brandFormValues?.__type === 'location' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.createAds}
                      />
                    }
                    name="createAds"
                    label="Create ads"
                    value={formValues?.capabilities?.createAds}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}

                {(brandFormValues?.__type === 'brand' &&
                  capabilities?.addUser) ||
                isAdmin ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        defaultChecked={formValues?.capabilities?.addUser}
                      />
                    }
                    name="addUser"
                    label="Create user"
                    value={formValues?.capabilities?.addUser}
                    onChange={(e: any) => {
                      setFormValues({
                        ...formValues,
                        capabilities: {
                          ...formValues?.capabilities,
                          [e.target.name]: e.target.checked,
                        },
                      });
                    }}
                  />
                ) : null}
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <PrimaryButton
              title={edit ? 'Save Changes' : 'Add'}
              loading={loading}
              marginRight5
              handleOnClick={() => handleOnSubmit()}
              type="button"
            />

            <PrimaryButton
              title="Cancel"
              type="button"
              handleOnClick={() =>
                multiple
                  ? onCloseMultipleForm(index)
                  : role === SALESPERSON
                  ? onCloseSalespersonForm()
                  : role === MARKETING_MANAGER
                  ? onCloseMultipleForm(index)
                  : onCloseManagerForm()
              }
              theme="red"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BrandUserForm;
