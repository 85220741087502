import { Brand } from '../../types';

export const buildBrandAddress = (brand: Brand) => {
  let address: string = '';

  if (brand?.address) {
    const { line1, city, state, zipcode, country } = brand?.address;

    address = `${line1 ? `${line1}` : ''}${city ? `, ${city}` : ''}${
      state ? `, ${state}` : ''
    }${zipcode ? `, ${zipcode}` : ''}${country ? `, ${country}` : ''}`;
  }

  return address;
};

export const AnalyticTools = {
  SCORECARDZ: 'scorecardz',
  TRENDZ: 'trendz',
};

export const analyticTools = [AnalyticTools.SCORECARDZ, AnalyticTools.TRENDZ];

export const BrandIndustry = {
  RESTAURANT: 'Restaurant',
  FITNESS: 'Fitness',
  HOME_SERVICES: 'Home Services',
  RETAIL: 'Retail',
  BUSINESS_SERVICES: 'Business Services',
  EDUCATION: 'Education',
  OTHER: 'Other',
};

export const brandIndustries = [
  BrandIndustry.RESTAURANT,
  BrandIndustry.FITNESS,
  BrandIndustry.HOME_SERVICES,
  BrandIndustry.RETAIL,
  BrandIndustry.BUSINESS_SERVICES,
  BrandIndustry.EDUCATION,
  BrandIndustry.OTHER,
];

export const BusinessType = {
  FRANDEV: 'FranDev',
  B2C: 'B2C',
  B2B: 'B2B',
};

export const businessTypes = [
  BusinessType.FRANDEV,
  BusinessType.B2C,
  BusinessType.B2B,
];
