import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context';
import { Navigate, useLocation } from 'react-router';
import { isExpired } from '../../utils/tokenValidator';
import { AUTH_TOKEN } from '../../utils';

const Home: React.FC = () => {
  const { state } = useContext(AuthContext);
  const isAuthenticated = state.isAuthenticated;
  const location = useLocation();
  const token = localStorage.getItem(AUTH_TOKEN);
  const redirectUrl = state.authUser?.redirectUrl;
  const [hasSession, setHasSession] = useState<boolean>(false);

  useEffect(() => {
    if (!isExpired(token)) setHasSession(true);

    if (isExpired(token)) setHasSession(false);
  }, [token]);

  if (isAuthenticated && hasSession) {
    return <Navigate to={redirectUrl} state={{ from: location }} replace />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default Home;
