import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  Brand,
  ChangeEventType,
  FBLeadGenData,
  LeadFormData,
  LeadNotification,
} from '../../types';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Grid,
  Tab,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  archiveLeadForm,
  getBrandLeadForms,
  getDefaultLeadNotification,
  syncLeadForms,
} from '../../services/lead';
import LeadFormListTable from '../../components/Tables/LeadList/LeadFormListTable';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import { DrawerMenu } from '../../components/Menu';
import LeadFormPreview from '../../components/Forms/Assetz/LeadFormPreview';
import { HtmlTooltip } from '../../components/Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PopupModal from '../../components/Modal';
import FacebookLeadFormListTable from '../../components/Tables/LeadList/FacebookLeadFormListTable';
import ModalHeader from '../../components/Modal/ModalHeader';
import { toggleAlert } from '../../redux/actions';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { DiySettings } from '../../types/IDiy';
import {
  createOrUpdateDiySettings,
  getSingleDiySetting,
} from '../../services/diy';
import { useForm } from 'react-hook-form';
import { validateEmailList } from '../../utils/validation';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

const TABS: string[] = ['Lead Forms'];

const Assetz: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'onSubmit' });

  const [tabValue, setTabValue] = useState<string>('1');
  const [leadForms, setLeadForms] = useState<LeadFormData[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [selectedLeadForm, setSelectedLeadForm] = useState<LeadFormData>(null);
  const [openFormPreview, setOpenFormPreview] = useState<boolean>(false);
  const [openSyncFormModal, setOpenSyncFormModal] = useState<boolean>(false);
  const [selectedForms, setSelectedForms] = useState<FBLeadGenData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [defaultNotification, setDefaultNotification] =
    useState<LeadNotification>(null);

  useEffect(() => {
    if (location || brand) {
      getLeadForms();
      getDiySettings();
      getDefaultNotification();
    }
  }, [brand, location]);

  const getLeadForms = async () => {
    try {
      setFetchLoading(true);

      const response = await getBrandLeadForms((location || brand)?._id);

      setLeadForms(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getDefaultNotification = async () => {
    try {
      setFetchLoading(true);

      const response = await getDefaultLeadNotification(
        (location || brand)?._id,
      );

      setDefaultNotification(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getDiySettings = async () => {
    try {
      const response = await getSingleDiySetting((location || brand)?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleOnUpdateDiySettings = async () => {
    setLoading(true);
    try {
      const response = await createOrUpdateDiySettings(
        (location || brand)?._id,
        diySettings,
      );

      setDiySettings(response.data);

      setValue(
        'defaultEmailRecipients',
        response.data.defaultEmailRecipients?.join(','),
      );
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Default email recipients updated successfully',
          type: 'error',
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTabValue = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleOpenFormPreview = (leadForm: LeadFormData) => {
    setOpenFormPreview(true);
    setSelectedLeadForm(leadForm);
  };

  const handleCloseFormPreview = () => {
    setOpenFormPreview(false);
    setSelectedLeadForm(null);
  };

  const handleOpenSyncForm = () => {
    setOpenSyncFormModal((prev) => !prev);
  };

  const handleSyncForms = async () => {
    setLoading(true);
    try {
      const response = await syncLeadForms(
        selectedForms,
        (location || brand)?._id,
      );

      setLeadForms([...leadForms, ...response.data]);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `You successfully sync ${response.count} lead forms`,
        }),
      );

      handleOpenSyncForm();
      navigate(`/assetz/form/set-up/${response.data[0]?._id}`);
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(
        toggleAlert({
          toggle: true,
          message: errorMsg,
          type: 'error',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnArchive = async () => {
    try {
      setArchiveLoading(true);

      const response = await archiveLeadForm(selectedLeadForm?._id);

      let temp: LeadFormData[] = [];
      leadForms.forEach((form: LeadFormData) => {
        if (form._id !== response.data._id) {
          temp = [...temp, form];
        }
      });

      setLeadForms(temp);

      handleCloseFormPreview();
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'You successfully archive the form',
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);

      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setArchiveLoading(false);
    }
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'Lead Forms') {
      return (
        <Grid container spacing={2}>
          {(location || brand)?.__type === 'brand' ? (
            <Grid
              item
              xs={12}
              sx={{ justifyContent: 'flex-end', display: 'flex' }}
            >
              <div>
                {!(location || brand)?.allowLocations ? (
                  <PrimaryButton
                    title="Sync Form"
                    type="button"
                    handleOnClick={handleOpenSyncForm}
                    marginRight5
                    endIcon={
                      <HtmlTooltip
                        disableInteractive
                        title={
                          <Fragment>
                            <Box
                              component="div"
                              sx={{
                                fontWeight: 'bold',
                              }}
                            >
                              Sync Form
                            </Box>

                            <div>
                              Integrate your Lead Generation form created
                              through Facebook Ads Manager.
                            </div>
                          </Fragment>
                        }
                      >
                        <InfoTwoToneIcon sx={{ fontSize: '18px' }} />
                      </HtmlTooltip>
                    }
                  />
                ) : null}

                <PrimaryButton
                  title="Create Form"
                  type="button"
                  handleOnClick={() => navigate('/assetz/form/create')}
                />
              </div>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <LeadFormListTable
              leadForms={leadForms}
              loading={fetchLoading}
              onOpenPreview={handleOpenFormPreview}
              isMobile={xsOnly}
              defaultNotification={defaultNotification}
            />
          </Grid>
        </Grid>
      );
    }

    /**
     * Temporary Remove until all assets were transfer
     */
    if (tab === 'Email') {
      return (
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            sx={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <TextField
              {...register('defaultEmailRecipients', {
                required: true,
                validate: validateEmailList,
              })}
              fullWidth
              variant="standard"
              type="text"
              name="defaultEmailRecipients"
              label="Default Email Recipients"
              onChange={(e: ChangeEventType) => {
                clearErrors('defaultEmailRecipients');
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value.trim().split(','),
                });
              }}
              value={diySettings?.defaultEmailRecipients?.join(',')}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline
              helperText={
                errors?.defaultEmailRecipients?.message ||
                (errors?.defaultEmailRecipients &&
                  'Please enter at least 1 email recipient') ||
                'Enter list of email addresses separated by comma'
              }
              error={!!errors.defaultEmailRecipients}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Assetz" />
        </div>

        <div>
          <TabContext value={tabValue}>
            <TabList
              onChange={handleChangeTabValue}
              variant="scrollable"
              allowScrollButtonsMobile
              scrollButtons
              textColor="secondary"
              TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
              TabScrollButtonProps={{
                style: { backgroundColor: DEFAULT_COLOR_THEME, color: 'white' },
              }}
              sx={{
                backgroundColor: DEFAULT_COLOR_THEME,
                '.MuiTab-root': {
                  top: 5,
                  color: 'white',
                },
                '.Mui-selected': {
                  color: `${DEFAULT_COLOR_THEME} !important`,
                  backgroundColor: 'white',
                  top: 5,
                  borderRadius: 2,
                },
              }}
            >
              {TABS?.map((tab: string, index: number) => (
                <Tab
                  label={tab}
                  value={`${index + 1}`}
                  key={`assetz-tab-header-${index + 1}`}
                />
              ))}
            </TabList>

            {TABS?.map((tab: string, index: number) => {
              return (
                <TabPanel
                  value={`${index + 1}`}
                  sx={{
                    backgroundColor: '#d9d9d933',
                    minHeight: 'calc(100vh - 310px)',
                  }}
                  key={`assetz-content-${index + 1}`}
                >
                  {renderTabContent(tab)}

                  {tab === 'Email' ? (
                    <Box
                      sx={{
                        position: 'sticky',
                        bottom: 0,
                        right: 0,
                        left: 0,
                        paddingBottom: '20px',
                      }}
                    >
                      <Box
                        sx={{
                          justifyContent: 'flex-end',
                          display: 'flex',
                        }}
                      >
                        <PrimaryButton
                          title="Save"
                          loading={loading}
                          size="large"
                          rounded
                          type="button"
                          handleOnClick={
                            tab === 'Email'
                              ? handleSubmit(handleOnUpdateDiySettings)
                              : null
                          }
                        />
                      </Box>
                    </Box>
                  ) : null}
                </TabPanel>
              );
            })}
          </TabContext>
        </div>
      </div>

      <DrawerMenu
        anchor="right"
        open={openFormPreview}
        onClose={handleCloseFormPreview}
      >
        <LeadFormPreview
          leadForm={selectedLeadForm}
          onClose={handleCloseFormPreview}
          onArchive={handleOnArchive}
          loading={archiveLoading}
        />
      </DrawerMenu>

      <PopupModal open={openSyncFormModal} handleClose={handleOpenSyncForm}>
        <Grid container spacing={2} sx={{ width: xsOnly ? '100%' : '600px' }}>
          <Grid item xs={12}>
            <ModalHeader title="Sync Lead Forms" />
          </Grid>

          <Grid item xs={12}>
            <FacebookLeadFormListTable
              brand={location || brand}
              setSelectedForms={setSelectedForms}
              forms={leadForms}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              position: 'sticky',
              bottom: 0,
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <PrimaryButton
              title="Sync Forms"
              type="button"
              handleOnClick={handleSyncForms}
              loading={loading}
            />
          </Grid>
        </Grid>
      </PopupModal>
    </div>
  );
};

export default Assetz;
