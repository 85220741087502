import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MoreIcon from '@mui/icons-material/MoreVert';
import { AuthContext, ExpressThemeContext } from '../../context';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ADMIN,
  AGENCY,
  AGENCY_SESSION,
  BRAND_FILTER_SESSION,
  BRAND_MANAGER,
  BRAND_SESSION,
  BUDGET_PROVIDER_SESSION,
  CAMPAIGN_PROVIDER_SESSION,
  IS_DIY_ADZ,
  LOCATION_SESSION,
  LOGGED_IN_USER,
  MARKETING_MANAGER,
  SALESPERSON,
  SUPER_ADMIN,
} from '../../utils';
import {
  Autocomplete,
  Avatar,
  Button,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import styles from '../../assets/styles/components/Navbar/Navbar.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import { Agency, Brand, ChangeEventType } from '../../types';
import { humanizeString } from '../../utils/stringModifier';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
} from '../../redux/actions';
import { resetSession } from '../../utils/tokenValidator';
import AddIcon from '@mui/icons-material/Add';
import {
  getBrands,
  getBrandsAssociatedLocations,
  getBrandsAssociatedWithAgency,
  getBrandsAssociatedWithManager,
  getBrandsAssociatedWithMarketingManager,
  getBrandsAssociatedWithSalesperson,
} from '../../services/brand';
import { getAgencies } from '../../services/agency';
import { fetchBrandCampaigns } from '../../services/ads';
import { arrayUniqueFilter } from '../../utils/arrayFormatter';
import { adsProvider } from '../../utils/constants/facebookAds';
import { MdAndDown } from '../../utils/breakpoints';
import TuneIcon from '@mui/icons-material/Tune';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';

interface TopNavBarProps {
  drawerWidth: number;
  onOpenDrawer: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onOpenMobileOptions: () => void;
}

const TopNavBar: React.FC<TopNavBarProps> = ({
  drawerWidth,
  onOpenDrawer,
  setLoading,
  onOpenMobileOptions,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const pathLocation = useLocation();
  const pathNames = pathLocation.pathname.split('/');
  const isBudget = pathNames.includes('budgets');
  const isBudgetz = pathNames.includes('budgetz');
  const isCampaign = pathNames.includes('campaigns');
  const { state, dispatch } = useContext(AuthContext);
  const {
    state: { expressTheme },
  } = useContext(ExpressThemeContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAgency = state.role === AGENCY;
  const isSalesperson = state.role === SALESPERSON;
  const isBrandManager = state.role === BRAND_MANAGER;
  const isMarketingManager = state.role === MARKETING_MANAGER;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const fullName = state.authUser?.name;
  const capabilities = state.capabilities;
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [selectedLocation, setSelectedLocation] = useState<Brand>(null);
  const [selectedAgency, setSelectedAgency] = useState<Agency>(null);
  const [campaignProviders, setCampaignProviders] = useState<string[]>([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const budgetProvider =
    useSelector((state: any) => state?.budgetReportProvider?.provider) ||
    'facebook';
  const brands: Brand[] = useSelector((state: any) => state.brands?.brands);
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const locations: Brand[] = useSelector(
    (state: any) => state.locations?.locations,
  );
  const location: Brand = useSelector((state: any) => state.location?.location);
  const campaignProvider: string = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const agencies: Agency[] =
    useSelector((state: any) => state.agencies?.agencies) || [];
  const agency: Agency = useSelector((state: any) => state.agency?.agency);
  const brandFilter = useSelector(
    (state: any) => state?.brandFilter?.filter?.filter,
  );
  const budgetProviderSession = localStorage.getItem(BUDGET_PROVIDER_SESSION);
  const sessionFilter = localStorage.getItem(BRAND_FILTER_SESSION);

  useEffect(() => {
    if (budgetProviderSession) {
      reduxDispatch(setBudgetReportProvider(budgetProviderSession));
    } else {
      reduxDispatch(setBudgetReportProvider('facebook'));
    }
  }, [budgetProviderSession]);

  useEffect(() => {
    if (isSuperAdmin || isAdmin) {
      handleSelectFilter(sessionFilter ? sessionFilter : 'active');
      getAllAgencies(sessionFilter ? sessionFilter : 'active');
    }
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    if (agency) {
      setSelectedAgency(agency);
    }
  }, [agency, brandFilter]);

  useEffect(() => {
    if (!(isSuperAdmin || isAdmin)) {
      getAllBrands();
    }
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    if (brand) {
      setSelectedBrand(brand);
      getCampaigns(brand);
      if (brand?.allowLocations) {
        getAllLocations(brand);
      }
    }
  }, [brand, isCampaign]);

  useEffect(() => {
    if (location) {
      setSelectedLocation(location);
      getCampaigns(location);
    } else {
      reduxDispatch(setLocation(null));
      setSelectedLocation(null);
    }
  }, [location]);

  const getAllBrands = async (agency?: Agency, brandFilter?: string) => {
    setLoading(true);
    try {
      let data: Brand[] = [];
      if (IS_DIY_ADZ && !(isSuperAdmin || isAdmin)) {
        const response = await getBrandsAssociatedWithSalesperson(
          state.roleBasedId,
          1,
          1000,
          brandFilter ? brandFilter : 'active',
          undefined,
          undefined,
          undefined,
          IS_DIY_ADZ,
        );

        let data = response.data?.filter(
          (item: Brand) => item?.__type === 'brand',
        );
        const hasAccessBrands = data.length > 0;

        if (!hasAccessBrands) {
          data = response.data?.filter(
            (item: Brand) => item?.__type === 'location',
          );
        }

        reduxDispatch(setBrands(data));

        const sessionBrand = localStorage.getItem(BRAND_SESSION);

        if (sessionBrand) {
          const brand = data.find((item: Brand) => {
            return item?._id === sessionBrand;
          });

          reduxDispatch(setBrand(brand));
        } else {
          reduxDispatch(setBrand(data[0]));
          localStorage.setItem(BRAND_SESSION, data[0]?._id);
        }
      } else {
        if (isAgency) {
          const response = await getBrandsAssociatedWithAgency(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else if (isBrandManager) {
          const response = await getBrandsAssociatedWithManager(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            isFranchisee,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else if (isSalesperson) {
          const response = await getBrandsAssociatedWithSalesperson(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            isFranchisee,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else if (isMarketingManager) {
          const response = await getBrandsAssociatedWithMarketingManager(
            state.roleBasedId,
            1,
            1000,
            brandFilter ? brandFilter : 'active',
            isFranchisee,
            undefined,
            undefined,
            IS_DIY_ADZ,
          );

          data = [...response.data];
        } else {
          if (agency) {
            const response = await getBrandsAssociatedWithAgency(
              agency?._id,
              1,
              1000,
              brandFilter ? brandFilter : 'active',
              undefined,
              undefined,
              IS_DIY_ADZ,
            );

            data = [...response.data];
          } else {
            const response = await getBrands(
              1,
              1000,
              brandFilter ? brandFilter : 'active',
              undefined,
              undefined,
              IS_DIY_ADZ,
            );

            data = [...response.data];
          }
        }

        reduxDispatch(setBrands(data));
        const sessionBrand = localStorage.getItem(BRAND_SESSION);

        if (sessionBrand) {
          const brand = data.find((brand: Brand) => {
            return brand?._id === sessionBrand;
          });

          reduxDispatch(setBrand(brand));
        } else {
          reduxDispatch(setBrand(data[0]));
          localStorage.setItem(BRAND_SESSION, data[0]?._id);
        }
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllAgencies = async (brandFilter: string) => {
    try {
      setLoading(true);
      const response = await getAgencies(
        1,
        1000,
        undefined,
        undefined,
        undefined,
        IS_DIY_ADZ,
      );
      reduxDispatch(setAgencies(response.data));

      const agencySession = localStorage.getItem(AGENCY_SESSION);

      if (agencySession) {
        const agencyData = response.data.find((agency: Agency) => {
          return agency._id === agencySession;
        });

        reduxDispatch(setAgency(agencyData));
        getAllBrands(agencyData, brandFilter);
      } else {
        reduxDispatch(setAgency(null));
        getAllBrands(null, brandFilter);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCampaigns = async (brand: Brand) => {
    setLoading(true);
    try {
      const campaigns = await fetchBrandCampaigns(brand?._id);

      const providers = campaigns?.data.map(
        (campaign: any) => campaign.provider,
      );

      const filteredProviders = arrayUniqueFilter(providers);
      const providerSession = localStorage.getItem(CAMPAIGN_PROVIDER_SESSION);

      if (isCampaign) {
        if (providerSession) {
          reduxDispatch(setCampaignProvider(providerSession));
        } else {
          reduxDispatch(setCampaignProvider(adsProvider.FACEBOOK));
          localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, adsProvider.FACEBOOK);
        }
      } else {
        setCampaignProviders(filteredProviders);

        if (filteredProviders.length > 1) {
          if (providerSession) {
            reduxDispatch(setCampaignProvider(providerSession));
          } else {
            reduxDispatch(setCampaignProvider(adsProvider.FACEBOOK));
            localStorage.setItem(
              CAMPAIGN_PROVIDER_SESSION,
              adsProvider.FACEBOOK,
            );
          }
        } else if (filteredProviders.length > 0) {
          reduxDispatch(setCampaignProvider(filteredProviders[0]));
          localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, filteredProviders[0]);
        } else {
          localStorage.removeItem(CAMPAIGN_PROVIDER_SESSION);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllLocations = async (brand?: Brand, brandFilter?: string) => {
    setLoading(true);
    try {
      const response = await getBrandsAssociatedLocations(
        brand?._id,
        1,
        1000,
        brandFilter ? brandFilter : 'active',
      );

      reduxDispatch(setLocations(response.data));
      const sessionLocation = localStorage.getItem(LOCATION_SESSION);

      if (sessionLocation) {
        const locationData = response.data.find((location: Brand) => {
          return location._id === sessionLocation;
        });

        reduxDispatch(setLocation(locationData));
      } else {
        reduxDispatch(setLocation(null));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnSelectProvider = (value: string) => {
    reduxDispatch(setCampaignProvider(value));
    localStorage.setItem(CAMPAIGN_PROVIDER_SESSION, value);
  };

  const handleOnSelectBudgetProvider = (value: string) => {
    reduxDispatch(setBudgetReportProvider(value));
    localStorage.setItem(BUDGET_PROVIDER_SESSION, value);
  };

  const handleOnSelectBrandFilter = (value: string) => {
    reduxDispatch(setBrandFilter({ filter: value }));
    localStorage.setItem(BRAND_FILTER_SESSION, value);
    localStorage.removeItem(BRAND_SESSION);
    getAllBrands(selectedAgency, value);
  };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const resetReduxSession = () => {
    dispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
      },
    });
    reduxDispatch(setBrands([]));
    reduxDispatch(setBrand(null));
    reduxDispatch(setAgencies([]));
    reduxDispatch(setAgency(null));
    reduxDispatch(setBrandFilter(null));
    reduxDispatch(setCampaignProvider(null));
    reduxDispatch(setBudgetReportProvider(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setLocation(null));
  };

  const handleSelectBrand = (brand: Brand) => {
    reduxDispatch(setLocation(null));
    reduxDispatch(setLocations([]));
    reduxDispatch(setBrand(brand));
    localStorage.setItem(BRAND_SESSION, brand?._id);
  };

  const handleSelectAgency = (agency: Agency, reason: string) => {
    if (reason === 'clear') {
      setSelectedAgency(null);
      reduxDispatch(setAgency(null));
      reduxDispatch(setLocations([]));
      reduxDispatch(setLocation(null));
      localStorage.removeItem(AGENCY_SESSION);
      localStorage.removeItem(BRAND_SESSION);
      localStorage.removeItem(LOCATION_SESSION);
      getAllBrands(null, brandFilter);
    } else {
      setSelectedAgency(agency);
      reduxDispatch(setAgency(agency));
      reduxDispatch(setLocations([]));
      reduxDispatch(setLocation(null));
      localStorage.setItem(AGENCY_SESSION, agency?._id);
      localStorage.removeItem(BRAND_SESSION);
      localStorage.removeItem(LOCATION_SESSION);
      getAllBrands(agency, brandFilter);
    }
  };

  const handleSelectLocation = (location: Brand, reason: string) => {
    if (reason === 'clear') {
      setSelectedLocation(null);
      reduxDispatch(setLocation(null));
      localStorage.removeItem(LOCATION_SESSION);
    } else {
      setSelectedLocation(location);
      reduxDispatch(setLocation(location));
      localStorage.setItem(LOCATION_SESSION, location?._id);
    }
  };

  const handleSelectFilter = (filter: string) => {
    reduxDispatch(setBrandFilter({ filter }));
  };

  const logout = () => {
    resetSession();
    resetReduxSession();
    navigate('/login');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAdmin || isSuperAdmin || isAgency ? (
        <MenuItem
          onClick={() => {
            navigate(IS_DIY_ADZ ? '/settingz' : '/settings');
            handleMobileMenuClose();
          }}
        >
          <IconButton
            size="large"
            aria-label="settings user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <RoomPreferencesOutlinedIcon />
          </IconButton>
          <p>{IS_DIY_ADZ ? 'Settingz' : 'Settings'}</p>
        </MenuItem>
      ) : null}

      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigate('/profile');
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>

        <p>Profile</p>
      </MenuItem>

      <MenuItem onClick={logout}>
        <IconButton
          size="large"
          aria-label="logout"
          aria-controls="logout"
          aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>

        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate('/profile');
          handleMobileMenuClose();
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>

        <p>Profile</p>
      </MenuItem>

      <MenuItem onClick={logout}>
        <IconButton
          size="large"
          aria-label="logout"
          aria-controls="logout"
          aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>

        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          boxShadow: 'none',
          backgroundColor: '#f5f5f5',
          color: 'red',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
        className={styles.navbar}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onOpenDrawer}
            sx={{
              mr: 2,
              display: { md: 'none' },
              color: expressTheme.properties.bgColor,
            }}
          >
            <MenuIcon />
          </IconButton>

          {MdAndDown() ? (
            <Typography
              variant="body1"
              color="#484848"
              fontWeight="bold"
              sx={{ marginRight: '5px' }}
            >
              {location ? location?.brand : brand?.brand}
            </Typography>
          ) : (
            <>
              {isAdmin || isSuperAdmin || (brands?.length > 1 && !isBudget) ? (
                <Autocomplete
                  id="brands"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        {option.brand}
                      </li>
                    );
                  }}
                  options={brands?.sort(
                    (a, b) => -b.brand?.localeCompare(a.brand),
                  )}
                  value={selectedBrand}
                  getOptionLabel={(option) => option?.brand}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Brands"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '50px',
                          backgroundColor: 'white',
                          legend: {
                            marginLeft: '10px',
                          },
                        },
                        '& .MuiAutocomplete-inputRoot': {
                          paddingLeft: '20px !important',
                          borderRadius: '50px',
                        },
                        '& .MuiInputLabel-outlined': {
                          backgroundColor: 'none',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: 0,
                          marginLeft: '10px',
                        },
                        input: {
                          '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: 'black',
                            fontWeight: 'bold',
                          },
                        },
                      }}
                    />
                  )}
                  disableClearable
                  sx={{
                    sm: { width: '100%' },
                    width: '300px',
                    marginRight: '5px',
                  }}
                  onChange={async (e: any, value: any, reason, detail) => {
                    handleSelectBrand(value);
                    navigate(IS_DIY_ADZ ? '/reportz' : '/reports');
                  }}
                  PaperComponent={({ children }) => {
                    return (
                      <Paper>
                        {children}
                        {!(isSuperAdmin || isAdmin) ? null : (
                          <Box component="div" sx={{ textAlign: 'center' }}>
                            <Button
                              type="button"
                              startIcon={
                                <AddIcon sx={{ fontWeight: 'bold' }} />
                              }
                              onMouseDown={() =>
                                navigate(
                                  `/${IS_DIY_ADZ ? 'brandz' : 'brands'}/create`,
                                )
                              }
                              variant="text"
                              sx={{
                                color: DEFAULT_COLOR_THEME,
                                fontWeight: 'bold',
                              }}
                            >
                              Add New Brand
                            </Button>
                          </Box>
                        )}
                      </Paper>
                    );
                  }}
                />
              ) : brands?.length === 1 && !isBudget ? (
                <Typography
                  variant="h6"
                  color="#484848"
                  fontWeight="bold"
                  sx={{ marginRight: '5px' }}
                >
                  {brands[0].brand}
                </Typography>
              ) : null}

              {brand?.allowLocations && !isBudget ? (
                <Autocomplete
                  id="locations"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option?._id}>
                        {option.brand}
                      </li>
                    );
                  }}
                  options={locations?.sort(
                    (a, b) => -b.brand?.localeCompare(a.brand),
                  )}
                  value={selectedLocation}
                  getOptionLabel={(option) => option?.brand}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Location"
                      label={humanizeString(
                        `${brand?.locationLabel || 'location'}s`,
                      )}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '50px',
                          backgroundColor: 'white',
                          legend: {
                            marginLeft: '10px',
                          },
                        },
                        '& .MuiAutocomplete-inputRoot': {
                          paddingLeft: '20px !important',
                          borderRadius: '50px',
                        },
                        '& .MuiInputLabel-outlined': {
                          backgroundColor: 'none',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: 0,
                          marginLeft: '10px',
                        },
                        input: {
                          '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: 'black',
                            fontWeight: 'bold',
                          },
                        },
                      }}
                    />
                  )}
                  sx={{
                    sm: { width: '100%' },
                    width: '300px',
                    marginRight: '5px',
                  }}
                  onChange={async (e: any, value: any, reason, detail) => {
                    handleSelectLocation(value, reason);
                    navigate(IS_DIY_ADZ ? '/reportz' : '/reports');
                  }}
                  PaperComponent={({ children }) => {
                    return (
                      <Paper>
                        {children}

                        {isSuperAdmin ||
                        isAdmin ||
                        capabilities?.addLocations ? (
                          <Box component="div" sx={{ textAlign: 'center' }}>
                            <Button
                              type="button"
                              startIcon={
                                <AddIcon sx={{ fontWeight: 'bold' }} />
                              }
                              onMouseDown={() =>
                                navigate(
                                  `/${
                                    IS_DIY_ADZ ? 'locationz' : 'locations'
                                  }/create`,
                                )
                              }
                              variant="text"
                              sx={{
                                color: DEFAULT_COLOR_THEME,
                                fontWeight: 'bold',
                              }}
                            >
                              {`Add New ${humanizeString(
                                brand?.locationLabel || 'location',
                              )}`}
                            </Button>
                          </Box>
                        ) : null}
                      </Paper>
                    );
                  }}
                />
              ) : locations?.length === 1 && !isBudget ? (
                <Typography
                  variant="h6"
                  color="#484848"
                  fontWeight="bold"
                  sx={{ marginRight: '5px' }}
                >
                  {locations[0].brand}
                </Typography>
              ) : null}

              {(campaignProviders.length > 1 && campaignProvider) ||
              isCampaign ||
              (isBudget && (isSuperAdmin || isAdmin || isAgency)) ? (
                <TextField
                  id="campaignProviders"
                  select
                  sx={{
                    sm: { width: '100%' },
                    width: '150px',
                    marginRight: '5px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      backgroundColor: 'white',
                      legend: {
                        marginLeft: '10px',
                      },
                    },
                    '& .MuiSelect-select': {
                      marginLeft: '10px',
                    },
                    '& .MuiInputLabel-outlined': {
                      backgroundColor: 'none',
                    },
                    '& .MuiInputLabel-shrink': {
                      paddingRight: 0,
                      marginLeft: '10px',
                    },
                    input: {
                      '&::placeholder': {
                        textOverflow: 'ellipsis !important',
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    },
                  }}
                  label="Provider"
                  value={isBudget ? budgetProvider : campaignProvider}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  onChange={(e: ChangeEventType) => {
                    isBudget
                      ? handleOnSelectBudgetProvider(e.target.value)
                      : handleOnSelectProvider(e.target.value);
                  }}
                >
                  {['facebook', 'google'].map((option: string) => (
                    <MenuItem key={option} value={option}>
                      {humanizeString(option)}
                    </MenuItem>
                  ))}
                </TextField>
              ) : null}

              {(isAdmin || isSuperAdmin) && agencies?.length > 0 ? (
                <>
                  <Autocomplete
                    id="agencies"
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.agency}
                        </li>
                      );
                    }}
                    options={agencies?.sort(
                      (a, b) => -b.agency?.localeCompare(a.agency),
                    )}
                    value={selectedAgency}
                    getOptionLabel={(option) => option?.agency}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="All Agencies"
                        label="Agencies"
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '50px',
                            backgroundColor: 'white',
                            legend: {
                              marginLeft: '10px',
                            },
                          },
                          '& .MuiAutocomplete-inputRoot': {
                            paddingLeft: '20px !important',
                            borderRadius: '50px',
                          },
                          '& .MuiInputLabel-outlined': {
                            backgroundColor: 'none',
                          },
                          '& .MuiInputLabel-shrink': {
                            paddingRight: 0,
                            marginLeft: '10px',
                          },
                          input: {
                            '&::placeholder': {
                              textOverflow: 'ellipsis !important',
                              color: 'black',
                              fontWeight: 'bold',
                            },
                          },
                        }}
                      />
                    )}
                    sx={{
                      sm: { width: '100%' },
                      width: '300px',
                      marginRight: '5px',
                    }}
                    onChange={async (e: any, value: any, reason, detail) => {
                      handleSelectAgency(value, reason);

                      if (!isBudgetz) {
                        navigate(IS_DIY_ADZ ? '/reportz' : '/reports');
                      }
                    }}
                  />

                  {brandFilter && !isBudget ? (
                    <TextField
                      id="filters"
                      select
                      sx={{
                        sm: { width: '100%' },
                        width: '150px',
                        marginRight: '5px',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '50px',
                          backgroundColor: 'white',
                          legend: {
                            marginLeft: '10px',
                          },
                        },
                        '& .MuiInputLabel-outlined': {
                          backgroundColor: 'none',
                        },
                        '& .MuiInputLabel-shrink': {
                          paddingRight: 0,
                          marginLeft: '10px',
                        },
                        '& .MuiSelect-select': {
                          marginLeft: '10px',
                        },
                        input: {
                          '&::placeholder': {
                            textOverflow: 'ellipsis !important',
                            color: 'black',
                            fontWeight: 'bold',
                          },
                        },
                      }}
                      label="Brand Filter"
                      value={brandFilter}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      onChange={(e: ChangeEventType) => {
                        handleOnSelectBrandFilter(e.target.value);
                      }}
                    >
                      {['active', 'inactive'].map((option: string) => (
                        <MenuItem key={option} value={option}>
                          {humanizeString(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}
                </>
              ) : null}
            </>
          )}

          <Box sx={{ flexGrow: 1 }} />

          {xsOnly ? (
            <Box>
              <IconButton
                size="large"
                edge="end"
                onClick={onOpenMobileOptions}
                sx={{ color: expressTheme.properties.bgColor }}
              >
                <TuneIcon />
              </IconButton>
            </Box>
          ) : null}

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              sx={{ color: expressTheme.properties.bgColor }}
            >
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{
                  bgcolor: expressTheme.properties.bgColor,
                  width: 30,
                  height: 30,
                }}
                src={`${state.authUser?.imageUrl}`}
              >
                {state.authUser?.imageUrl === null ? <AccountCircle /> : null}
              </Avatar>

              <Typography
                variant="body2"
                color="#484848"
                fontWeight="bold"
                ml={1}
              >
                {fullName}
              </Typography>
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{
                color: expressTheme.properties.bgColor,
                paddingRight: '0px',
              }}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}

      {renderMenu}
    </Box>
  );
};

export default TopNavBar;
