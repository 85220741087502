import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import { PrimaryButton } from '../Buttons';
import FacebookIcon from '@mui/icons-material/Facebook';
import PageListModal from '../Modal/PageListModal';
import FormHeader from './FormHeader';
import { XsOnly } from '../../utils/breakpoints';

interface ConnectFacebookPageFormProps {
  page: any;
  objectId?: string;
  model: string;
  handleConnect: (page: any, objectId: string, model: string) => void;
  handleDisconnect: (page: any, objectId: string, model: string) => void;
  loading: boolean;
  open: boolean;
  handleOpenPageList: () => void;
  handleClosePageList: () => void;
  postCreate?: boolean;
  settings?: boolean;
  accessToken?: string;
}

const ConnectFacebookPageForm: React.FC<ConnectFacebookPageFormProps> = ({
  page,
  objectId,
  model,
  handleConnect,
  handleDisconnect,
  loading,
  open,
  handleOpenPageList,
  handleClosePageList,
  postCreate = false,
  settings = false,
  accessToken,
}) => {
  return (
    <div className={styles.page}>
      {settings ? (
        <FormHeader title="Connect Facebook Page" />
      ) : (
        <Typography
          component="p"
          sx={{ my: 1, fontSize: '16px', color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {postCreate
            ? 'Connect your Facebook page now?'
            : 'Connected Facebook Page'}
        </Typography>
      )}

      {page?.fbPageId ? (
        <Grid container spacing={2}>
          <Grid item xs={2} sm={2}>
            <div>
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  marginRight: '5px',
                }}
                src={page?.imageUrl}
                alt={page?.name}
              />
            </div>
          </Grid>

          <Grid item xs={10} sm={4}>
            <div className={styles.details}>
              <div>
                <p className={styles.id}>{page?.fbPageId}</p>
              </div>

              <div>
                <p>{page?.name}</p>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <PrimaryButton
              type="button"
              title="Disconnect"
              theme="#df4759"
              handleOnClick={() => {
                handleDisconnect(page, objectId, model);
              }}
              width={'45%'}
              loading={loading}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <PrimaryButton
              type="button"
              title="Connect Page"
              handleOnClick={handleOpenPageList}
              startIcon={<FacebookIcon />}
              width={`${XsOnly() ? '100%' : '40%'}`}
              loading={loading}
            />
          </Grid>
        </Grid>
      )}

      <PageListModal
        open={open}
        handleClose={handleClosePageList}
        loading={loading}
        handleConnectPage={handleConnect}
        model={model}
        objectId={objectId}
        defaultAccessToken={accessToken}
      />
    </div>
  );
};

export default ConnectFacebookPageForm;
