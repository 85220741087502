import { Box } from '@mui/material';
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import styles from '../../../../assets/styles/components/Forms/Form.module.scss';
import StripePaymentSteps from './StripePaymentSteps';
import {
  Brand,
  ChangeEventType,
  Fee,
  StripeCreateInvoiceItemForm,
  StripeCreateSetupIntentForm,
  StripeCustomerForm,
  StripeSubscriptionForm,
  StripeSubscriptionItemForm,
} from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrUpdateStripeCustomer,
  fetchSingleStripeCustomer,
} from '../../../../services/stripe/customer';
import StripePaymentTermsModal from '../../../Modal/StripePaymentTermsModal';
import { clientBaseUrl } from '../../../../services/api';
import moment, { Moment } from 'moment';
import { FeeType } from '../../../../utils/helpers/stripeHelper';
import {
  createStripeInvoiceItem,
  createStripeSubscription,
  retriveStripeInvoiceItems,
} from '../../../../services/stripe/subscription';
import { createStripeSetupIntent } from '../../../../services/stripe/paymentIntent';
import { useNavigate } from 'react-router-dom';
import CircularLoading from '../../../CircularLoading';
import { setBrand, setLocation, toggleAlert } from '../../../../redux/actions';
import { AuthContext } from '../../../../context';
import { BRAND_MANAGER, IS_DIY_ADZ, SALESPERSON } from '../../../../utils';
import { createOrUpdateFee } from '../../../../services/fee';

interface SubscriptionFormProps {
  brandFees: Fee[];
  locationFees: Fee[];
  feeTotal: number;
  setBrandFees: Dispatch<SetStateAction<Fee[]>>;
  setLocationFees: Dispatch<SetStateAction<Fee[]>>;
  setBrandFeesForm: Dispatch<SetStateAction<Fee[]>>;
  onClose: () => void;
}

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  brandFees,
  locationFees,
  feeTotal,
  setBrandFees,
  setLocationFees,
  setBrandFeesForm,
  onClose,
}) => {
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const { state } = useContext(AuthContext);
  const isSalesperson = state.role === SALESPERSON;
  const isBrandManager = state.role === BRAND_MANAGER;
  const locationBrand = state.authUser?.brand;
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const dispatch = useDispatch();
  const [customerForm, setCustomerForm] = useState<StripeCustomerForm>({
    address: {
      city: '',
      country: 'US',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
    email: '',
    name: '',
    phone: '',
    metadata: {
      user_id: location ? location?._id : brand?._id,
      role: 'brand',
    },
  });
  const [stripeClientToken, setStripeClientToken] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>('payment');
  const [subscriptionStartDate, setSubscriptionStartDate] =
    useState<Moment | null>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
  const [brandToSubscribe, setBrandToSubscribe] = useState<Brand>(null);

  useEffect(() => {
    if (location) {
      setBrandToSubscribe(location);
    } else {
      setBrandToSubscribe(brand);
    }
  }, [brand, location]);

  useEffect(() => {
    getSingleStripeCustomer();
  }, [brandToSubscribe]);

  const getSingleStripeCustomer = async () => {
    try {
      const { data } = await fetchSingleStripeCustomer(
        brandToSubscribe?._id,
        'brand',
      );

      setCustomerForm({
        address: data?.address,
        email: data?.email,
        name: data?.name,
        phone: data?.phone,
        metadata:
          Object.keys(data?.metadata).length === 0
            ? { user_id: brandToSubscribe?._id, role: 'brand' }
            : data?.metadata,
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const redirectUrl = () => {
    const path = window.location.pathname;

    if (transactionType === 'setup') {
      return `/${
        IS_DIY_ADZ ? 'billingz' : 'billings'
      }/payment-method/added?path=${path}&transaction=${transactionType}`;
    }

    return `/${
      IS_DIY_ADZ ? 'billingz' : 'billings'
    }/payment-success?path=${path}&transaction=${transactionType}`;
  };

  const handleSuccessPayment = () => {
    setLoading(false);
    onClose();
    navigate(redirectUrl());
  };

  const handleSubmitPayment = async (stripe: any, elements: any) => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    if (transactionType === 'payment') {
      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: clientBaseUrl,
        },
        redirect: 'if_required',
      });

      if (response.error) {
        if (
          response.error.type === 'card_error' ||
          response.error.type === 'validation_error'
        ) {
          setLoading(false);
          dispatch(
            toggleAlert({
              toggle: true,
              message: response.error.message,
              type: 'error',
            }),
          );
        } else {
          setLoading(false);
          dispatch(
            toggleAlert({
              toggle: true,
              message: 'An unexpected error occurred.',
              type: 'error',
            }),
          );
        }
      } else {
        handleSuccessPayment();
      }
    } else if (transactionType === 'setup') {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: clientBaseUrl,
        },
        redirect: 'if_required',
      });

      console.log(error);

      if (error) {
        setLoading(false);

        return dispatch(
          toggleAlert({
            toggle: true,
            message: error.message,
            type: 'error',
          }),
        );
      } else {
        handleSuccessPayment();
      }
    }
  };

  const handleUpdateAdBudget = async (
    e: FormEvent,
    isCreateSubscription: boolean,
  ) => {
    e.preventDefault();

    try {
      const budget = brandFees.find((fee: Fee) => {
        return fee.type === FeeType.AdBudget;
      });

      if (
        (budget.amount === 0 && feeTotal === 0 && isFranchisee) ||
        (!isFranchisee && (isSalesperson || isBrandManager) && feeTotal === 0)
      ) {
        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Please provide your desired Ad Budget.',
            type: 'error',
          }),
        );

        return;
      }

      setLoading(true);
      let tempForm: Fee[] = [];
      brandFees.forEach((fee: Fee) => {
        if (fee.type === FeeType.AdBudget) {
          fee = { ...fee, enabled: true };
        }

        tempForm = [...tempForm, fee];
      });
      const response = await createOrUpdateFee(tempForm);

      setBrandFees(response.data);
      setBrandFeesForm(response.data);
      if (isCreateSubscription)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Ad Budget Update Successfully.',
        }),
      );
    } catch (error: any) {
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleCreateOrUpdateCustomer = async () => {
    try {
      const response = await createOrUpdateStripeCustomer(
        customerForm,
        brandToSubscribe?._id,
        'brand',
      );

      return response;
    } catch (error: any) {
      console.log(error.message);
      const err = error.response.data;
      const buildName = `${err.message ? `${err.message}.` : ''} ${
        err.name ? `${err.name}.` : ''
      } ${err.email ? `${err.email}.` : ''} ${
        err.phone ? `${err.phone}.` : ''
      } ${err['address.city'] ? `${err['address.city']}.` : ''} ${
        err['address.state'] ? `${err['address.state']}.` : ''
      } ${err['address.postal_code'] ? `${err['address.postal_code']}.` : ''} ${
        err['address.country'] ? `${err['address.country']}.` : ''
      }`;

      dispatch(
        toggleAlert({
          toggle: true,
          message: buildName,
          type: 'error',
        }),
      );
    }
  };

  const handleCreateInvoiceItem = async (customerId: string) => {
    try {
      const fee = brandFees.find((fee: Fee) => {
        return fee?.oneTime;
      });

      const payload: StripeCreateInvoiceItemForm = {
        customer: customerId,
        currency: 'USD',
        description: `${fee?.name} Fee`,
        metadata: {
          user_id: brandToSubscribe?._id,
          role: 'brand',
        },
        unit_amount_decimal: fee?.amount * 100,
      };
      const response = await createStripeInvoiceItem(payload);

      return response;
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  };

  const handleCreateStripeSubscription = async (customerId: string) => {
    try {
      /**
       * Setting Up Billing Cycle Anchor
       * @reference https://stackoverflow.com/a/50289149
       */
      let startOfBillingCycle = moment()
        .utc()
        .add(1, 'month')
        .startOf('month')
        .unix();

      let payload: StripeSubscriptionForm = {
        customer: customerId,
        items: buildItems(brandFees),
        currency: 'USD',
        metadata: {
          user_id: brandToSubscribe?._id,
          role: 'brand',
        },
        payment_behavior: 'default_incomplete',
        expand: ['latest_invoice.payment_intent'],
        payment_settings: {
          save_default_payment_method: 'on_subscription',
          payment_method_types: ['card'],
        },
        billing_cycle_anchor: startOfBillingCycle,
      };

      let startDate: Moment | null;
      let startDateUnix: number;

      if (subscriptionStartDate) {
        startDate = subscriptionStartDate.utc().startOf('day');
        startDateUnix = startDate.unix();
      } else {
        startDate = moment().utc().add(1, 'day').startOf('day');
        startDateUnix = startDate.unix();
      }

      if (startDateUnix > startOfBillingCycle) {
        startOfBillingCycle = startDate.add(1, 'month').startOf('month').unix();
      }

      payload = {
        ...payload,
        trial_end: startDateUnix,
        billing_cycle_anchor: startOfBillingCycle,
      };

      const response = await createStripeSubscription(payload);

      return response;
    } catch (error: any) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.response.data.message,
          type: 'error',
        }),
      );
    }
  };

  const handleCreateSubscription = async (e: FormEvent) => {
    e.preventDefault();

    handleCloseTermsModal();

    setLoading(true);
    try {
      const customer = await handleCreateOrUpdateCustomer();

      const invoiceItems = await retriveStripeInvoiceItems(
        brandToSubscribe._id,
        'pending',
      );

      const existingSetupFee = invoiceItems.data.map((item: any) => {
        return item.description === 'Setup Fee';
      });

      if (customer?.data) {
        let temp: Brand = {
          ...brandToSubscribe,
          stripe: {
            ...brandToSubscribe.stripe,
            customerId: customer.data.id,
          },
        };
        if (location) {
          dispatch(setLocation(temp));
        } else {
          dispatch(setBrand(temp));
        }

        if (existingSetupFee.length === 0) {
          await handleCreateInvoiceItem(customer.data.id);
        }

        const fee = brandFees.find((fee: Fee) => {
          return fee?.type === FeeType.Setup;
        });

        if (fee?.amount === 0) {
          setTransactionType('setup');

          const params: StripeCreateSetupIntentForm = {
            customer: customer.data.id,
            payment_method_types: ['card'],
            metadata: {
              user_id: brandToSubscribe?._id,
              role: 'brand',
              subscription_start_date: subscriptionStartDate,
              action: 'subscription',
            },
          };

          const response = await createStripeSetupIntent(
            params,
            brandToSubscribe?._id,
          );

          if (response?.data) {
            setStripeClientToken(response.data.client_secret);

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            dispatch(
              toggleAlert({
                toggle: true,
                message: 'Failed to create subscription.',
                type: 'error',
              }),
            );
          }
        } else {
          setTransactionType('payment');

          const subscription = await handleCreateStripeSubscription(
            customer.data.id,
          );

          if (subscription?.data) {
            setStripeClientToken(
              subscription.data.latest_invoice.payment_intent?.client_secret,
            );

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            dispatch(
              toggleAlert({
                toggle: true,
                message: 'Failed to create subscription.',
                type: 'error',
              }),
            );
          }
        }
      } else {
        dispatch(
          toggleAlert({
            toggle: true,
            message: 'Failed to create subscription.',
            type: 'error',
          }),
        );
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleOnChangeCustomerForm = (e: ChangeEventType) => {
    if (isAddress(e.target.name)) {
      setCustomerForm({
        ...customerForm,
        address: {
          ...customerForm.address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setCustomerForm({
        ...customerForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOnChangeFees = (
    e: ChangeEventType,
    index: number,
    entity: string,
  ) => {
    const IS_BRAND = entity === 'brand';
    const IS_LOCATION = entity === 'location';

    const temp = [...(IS_BRAND ? brandFees : locationFees)];
    temp[index].amount = parseFloat(e.target.value);

    if (IS_BRAND) {
      setBrandFees(temp);
    } else if (IS_LOCATION) {
      setLocationFees(temp);
    }
  };

  const handlePhoneInput = (newValue: string) => {
    setCustomerForm({ ...customerForm, phone: newValue });
  };

  const isAddress = (field: string) => {
    return [
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'state',
    ].includes(field);
  };

  const buildItems = (fees: Fee[]) => {
    let items: StripeSubscriptionItemForm[] = [];

    fees.forEach((fee: Fee) => {
      let params: any = { price: fee?.stripePriceId };

      if (
        fee?.amount === 0 ||
        fee?.oneTime ||
        !fee.enabled ||
        fee.type === FeeType.AdBudget
      )
        return;

      items = [...items, params];
    });

    return items;
  };

  const handleOpenTermsModal = (e: FormEvent) => {
    e.preventDefault();

    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  return (
    <Box className={styles.form}>
      <CircularLoading loading={loading} />

      <StripePaymentSteps
        formValues={customerForm}
        onChange={handleOnChangeCustomerForm}
        clientSecret={stripeClientToken}
        onPay={handleSubmitPayment}
        loading={loading}
        subscriptionStartDate={subscriptionStartDate}
        setSubscriptionStartDate={setSubscriptionStartDate}
        brandFees={brandFees}
        handleOnChangeFees={handleOnChangeFees}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        onUpdateBudget={handleUpdateAdBudget}
        onChangeNumber={handlePhoneInput}
        onOpenTerms={handleOpenTermsModal}
        transactionType={transactionType}
        feeTotal={feeTotal}
        onClose={onClose}
      />

      <StripePaymentTermsModal
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        onAgree={handleCreateSubscription}
      />
    </Box>
  );
};

export default SubscriptionForm;
