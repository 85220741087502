import { Agency } from '../../types';
import { SET_AGENCIES, SET_AGENCY } from '../ActionNames';

export const setAgencies = (value: Agency[]) => {
  return {
    type: SET_AGENCIES,
    payload: value,
  };
};

export const setAgency = (value: Agency) => {
  return {
    type: SET_AGENCY,
    payload: value,
  };
};
