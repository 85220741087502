export const stripeBusinessType = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
  NON_PROFIT: 'non_profit',
  GOVERNMENT_ENTITY: 'government_entity',
};

export const stripeAccountType = {
  CUSTOM: 'custom',
  EXPRESS: 'express',
  STANDARD: 'standard',
};

export const stripeBusinessTypeSelection = [
  stripeBusinessType.INDIVIDUAL,
  stripeBusinessType.COMPANY,
];
