import axios from "axios";
import { MinMaxMinute } from "../types";
import { apiBaseUrl } from "./api";

export const addMinMaxMinutes = async (obj: MinMaxMinute) => {
  const data = await axios.post(`${apiBaseUrl}/min-max-minutes`, obj);

  return data?.data;
};

export const updateMinMaxMinutes = async (id: string, obj: MinMaxMinute) => {
  const data = await axios.put(`${apiBaseUrl}/min-max-minutes/${id}`, obj);

  return data?.data;
};

export const getMinMaxMinutes = async () => {
  const data = await axios.get(`${apiBaseUrl}/min-max-minutes`);
  return data?.data;
};
