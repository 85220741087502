import { Box } from '@mui/system';
import React, { Dispatch, SetStateAction } from 'react';
import { Brand } from '../../../types';
import {
  columns,
  adminColumns,
  mobileColumns,
  brandManagerColumns,
} from './BrandListTableColumns';
import { XsOnly } from '../../../utils/breakpoints';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';

interface BrandListTableProps {
  brands: Brand[];
  handleOnClickDelete: (id: string) => void;
  loading: boolean;
  isAdmin: boolean;
  isAgency: boolean;
  isSalesperson: boolean;
  rowData: number;
  isBrandManager: boolean;
  onSort: (sortedField: string, sortType: string) => void;
  agencyId: string;
  agencyName: string;
  paginationModel: any;
  setPaginationModel: Dispatch<SetStateAction<any>>;
}
const BrandListTable: React.FC<BrandListTableProps> = ({
  brands,
  handleOnClickDelete,
  loading,
  isAdmin,
  isAgency,
  isSalesperson,
  rowData,
  isBrandManager,
  onSort,
  agencyId,
  agencyName,
  paginationModel,
  setPaginationModel,
}) => {
  const renderColumns = () => {
    if (isAdmin || isAgency) {
      if (XsOnly()) {
        return mobileColumns;
      } else {
        return adminColumns;
      }
    } else if (isBrandManager) {
      return brandManagerColumns;
    } else {
      return columns;
    }
  };

  const rows = brands.slice().map((brand: Brand, index: number) => {
    return {
      brand: brand.brand,
      agency: brand.agency,
      investmentRequirement: brand.investmentRequirement,
      allLeads: brand.allLeads,
      zipcodereq: brand.zipcodereq,
      financialreq: brand.financialreq,
      redirect: brand.redirect,
      redirectlink: brand.redirectlink,
      redirecttext: brand.redirecttext,
      industryRequirement: brand.industryRequirement,
      industryQuestion: brand.industryQuestion,
      schedulecall: brand.schedulecall,
      calendly: brand.calendly,
      salesperson: brand.salesperson,
      manager: brand.manager,
      salespersonTitle: brand.salespersonTitle,
      _id: brand._id,
      id: brand.brandId,
      phoneNumReq: brand.phoneNumReq,
      budgetOffset: brand.budgetOffset || 100,
      clientTetherConfig: brand.clientTetherConfig,
      isAdmin,
      isSalesperson,
      isBrandManager,
      isAgency,
      cannedResponses: brand.cannedResponses,
      active: brand.active,
      paused: brand.paused,
      lastPausedAt: brand.lastPausedAt,
      page: {
        _id: brand.page?._id,
        name: brand.page?.name,
        accessToken: brand.page?.accessToken,
        fbPageId: brand.page?.fbPageId,
        imageUrl: brand.page?.imageUrl,
      },
      facebookBusinessIds: brand.facebookBusinessIds,
      googleCustomerIds: brand.googleCustomerIds,
      allowLocations: brand.allowLocations,
      franchisor: brand.franchisor,
      allowSetupDepositAccount: brand.allowSetupDepositAccount,
      allowSetupPayment: brand.allowSetupPayment,
      allowOptions: brand.allowOptions,
      __type: brand.__type,
      stripe: brand.stripe,
      handleOnClickDelete: handleOnClickDelete,
      agencyId: agencyId,
      agencyName: agencyName,
    };
  });

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={rows}
        columns={renderColumns()}
        hideFooterSelectedRowCount
        rowCount={rowData}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          loadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        onSortModelChange={(e: any) => {
          onSort(e[0]?.field || 'brand', e[0]?.sort || 'asc');
        }}
        sortingMode="server"
      />
    </Box>
  );
};

export default BrandListTable;
