import { Drawer } from '@mui/material';
import React, { ReactNode } from 'react';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  anchor: string;
  children?: ReactNode;
}

export const DrawerMenu: React.FC<DrawerProps> = ({
  anchor,
  open,
  onClose,
  children,
}) => {
  return (
    <Drawer
      anchor={anchor as any}
      open={open}
      onClose={onClose}
      sx={{ height: '100%' }}
    >
      {children}
    </Drawer>
  );
};
