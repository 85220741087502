export const FeeType = {
  AdBudget: 'ad-budget',
  Management: 'management',
  Setup: 'setup',
  Subscription: 'subscription',
};

export const FEE_TYPES: string[] = [
  FeeType.AdBudget,
  FeeType.Setup,
  FeeType.Management,
  FeeType.Subscription,
];

export const SubscriptionStatus = {
  Incomplete: 'incomplete',
  InCompleteExpired: 'incomplete_expired',
  Trialing: 'trialing',
  Active: 'active',
  PastDue: 'past_due',
  Canceled: 'canceled',
  Upaid: 'unpaid',
};
