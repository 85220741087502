import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment, { Moment } from 'moment';
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ChangeEventType, Fee } from '../../../../types';
import { FeeType } from '../../../../utils/helpers/stripeHelper';
import AccountActions from '../AccountActions';

interface StripeSubscriptionSetupFormProps {
  subscriptionStartDate: Moment | null;
  setSubscriptionStartDate: Dispatch<SetStateAction<Moment | null>>;
  brandFees: Fee[];
  handleOnChangeFees: (
    e: ChangeEventType,
    index: number,
    entity: string,
  ) => void;
  entity: string;
  onNext: () => void;
  activeStep: number;
  onBack: () => void;
  steps: string[];
  onUpdateBudget: (e: FormEvent, isCreate: boolean) => void;
  allowBudget: boolean;
  onClose: () => void;
}

const StripeSubscriptionSetupForm: React.FC<
  StripeSubscriptionSetupFormProps
> = ({
  subscriptionStartDate,
  setSubscriptionStartDate,
  brandFees,
  handleOnChangeFees,
  entity,
  onNext,
  onBack,
  activeStep,
  steps,
  onUpdateBudget,
  allowBudget,
  onClose,
}) => {
  const [budgetIndex, setBudgetIndex] = useState<number>(null);

  useEffect(() => {
    if (brandFees.length > 0) {
      const index = brandFees.findIndex((fee: Fee) => {
        return fee.type === FeeType.AdBudget;
      });

      setBudgetIndex(index);
    }
  }, [brandFees]);

  return (
    <Box
      component="form"
      onSubmit={(e: any) => {
        brandFees[budgetIndex]?.enabled ? onUpdateBudget(e, true) : onNext();
      }}
      autoComplete="off"
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {brandFees[budgetIndex]?.enabled ? (
            <TextField
              required
              variant="standard"
              type="number"
              id="adBudget"
              name="adBudget"
              label="Ads Budget"
              onChange={(e: any) => {
                handleOnChangeFees(e, budgetIndex, entity);
              }}
              value={brandFees[budgetIndex]?.amount}
              InputLabelProps={{ shrink: true }}
              size="small"
              autoFocus
              sx={{
                marginRight: '10px',
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
            />
          ) : null}

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Schedule Your Subscription"
              inputFormat="MM/DD/YYYY"
              value={subscriptionStartDate || moment().add(1, 'day')}
              minDate={moment().add(1, 'day')}
              onChange={(e: any) => setSubscriptionStartDate(e)}
              renderInput={(params) => {
                const value = moment(params.inputProps.value).format('LL');
                params.inputProps.value = value;

                return (
                  <TextField {...params} size="small" variant="standard" />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></Grid>
      </Grid>

      <AccountActions
        activeStep={activeStep}
        onBack={onBack}
        steps={steps}
        marginTop="20px"
        onCancel={onClose}
      />
    </Box>
  );
};

export default StripeSubscriptionSetupForm;
