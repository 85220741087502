export const SET_END_RANGE: string = 'SET_END_RANGE';
export const SET_START_RANGE: string = 'SET_START_RANGE';
export const SET_BRANDS: string = 'SET_BRANDS';
export const SET_BRAND: string = 'SET_BRAND';
export const SET_CAMPAIGN_PROVIDER: string = 'SET_CAMPAIGN_PROVIDER';
export const TOGGLE_ALERT: string = 'TOGGLE_ALERT';
export const SET_AGENCIES: string = 'SET_AGENCIES';
export const SET_AGENCY: string = 'SET_AGENCY';
export const SET_BRAND_FILTER: string = 'SET_BRAND_FILTER';
export const SET_BUDGET_REPORT_PROVIDER: string = 'SET_BUDGET_REPORT_PROVIDER';
export const SET_LOCATIONS: string = 'SET_LOCATIONS';
export const SET_LOCATION: string = 'SET_LOCATION';
