import {
  ADS_MANAGEMENT_SCOPES,
  PAGE_MANAGEMENT_SCOPES,
  PROFILE_SCOPES,
  BUSINESS_MANAGEMENT_SCOPES,
  USER_MANAGEMENT_SCOPES,
} from '../../utils';

declare let FB: any;

/**
 * Facebook Login Provider
 * @description Call Facebook login API with indicated desired permissions
 * @author Angelo David <angelod@codev.com>
 * @reference https://developers.facebook.com/docs/facebook-login/web
 * @returns accessToken
 */
export const signIn = () => {
  return new Promise((resolve) => {
    FB.login(
      (response: any) => {
        resolve(response);
      },
      {
        scope: `
          ${PROFILE_SCOPES},${USER_MANAGEMENT_SCOPES},
          ${PAGE_MANAGEMENT_SCOPES},${ADS_MANAGEMENT_SCOPES},
          ${BUSINESS_MANAGEMENT_SCOPES}
        `,
      },
    );
  });
};
