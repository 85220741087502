import { Chip, Grid, IconButton, Stack, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { AgencyErrors, CannedResponse, ProfileFormValues } from '../../types';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';
import { PrimaryButton } from '../Buttons';
import { CANNED_RESPONSE_FIELDS } from '../../utils';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import FormHeader from './FormHeader';
import { XsOnly } from '../../utils/breakpoints';

interface CannedResponsesFormProps {
  formValues: ProfileFormValues;
  handleAddResponses: () => void;
  handleDeleteReponses: (index: number) => void;
  handleOnSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  handleEditResponses: (index: number, value: string) => void;
  errors: AgencyErrors;
  handleSetCustomFields: (selectedForm: number, customFields: string) => void;
  loading: boolean;
}

const CannedResponsesForm: React.FC<CannedResponsesFormProps> = ({
  formValues,
  handleAddResponses,
  handleDeleteReponses,
  handleOnSubmit,
  handleEditResponses,
  handleSetCustomFields,
  errors,
  loading,
}) => {
  const [selectedForm, setSelectedForm] = useState<number>(null);

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%', py: 2 }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <FormHeader title="Canned Responses" my={2} />

      <Grid container spacing={2} sx={{ margin: '0 20px 20px 0' }}>
        <Stack direction="row" spacing={1}>
          {CANNED_RESPONSE_FIELDS.map((fields: any) => {
            return (
              <Chip
                key={fields}
                label={`${fields}`}
                onClick={() => {
                  handleSetCustomFields(selectedForm, fields);
                }}
              />
            );
          })}
        </Stack>
      </Grid>

      <Grid container spacing={2}>
        {formValues.cannedResponses.map(
          (response: CannedResponse, index: number) => {
            return response.isDeleted ? null : (
              <Grid item xs={12} key={index}>
                <TextField
                  fullWidth
                  required
                  multiline
                  variant="standard"
                  size="small"
                  type="text"
                  id={`cannedResponse-${index}`}
                  name={`cannedResponse-${index}`}
                  label={`Response #${index + 1}`}
                  onChange={(e: any) => {
                    handleEditResponses(index, e.target.value);
                  }}
                  onClick={() => {
                    setSelectedForm(index);
                  }}
                  value={response?.message}
                  InputLabelProps={{ shrink: true }}
                />

                <IconButton
                  id={`delete-${index}`}
                  size="small"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="chat-settings"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => {
                    handleDeleteReponses(index);
                  }}
                >
                  <DeleteIcon
                    sx={{
                      color: red[500],
                      fontSize: '30px',
                    }}
                  />
                </IconButton>
              </Grid>
            );
          },
        )}

        <Grid item xs={12}>
          <PrimaryButton
            title="Add Response"
            type="button"
            handleOnClick={handleAddResponses}
          />{' '}
          <PrimaryButton title="Save" loading={loading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CannedResponsesForm;
