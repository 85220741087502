import React, { FormEvent, useState } from 'react';
import AuthForm from './AuthForm';
import { ChangeEventType } from '../../types';
import { chnagePassword } from '../../services/user';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

const ChangePassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const dispatch = useDispatch();

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await chnagePassword({
        newPassword,
        oldPassword,
        confirmPassword,
      });
      if (data) {
        setLoading(false);
        dispatch(toggleAlert({ toggle: true, message: data.message }));
      }
    } catch (error: any) {
      const err = error.response.data;
      setErrors({
        ...errors,
        oldPassword: err?.oldPassword,
        newPassword: err?.newPassword,
        confirmPassword: err?.confirmPassword,
      });
      dispatch(
        toggleAlert({ toggle: true, message: err?.message, type: 'error' }),
      );
      setLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    if (e.target.name === 'oldPassword') {
      setOldPassword(e.target.value);
    } else if (e.target.name === 'newPassword') {
      setNewPassword(e.target.value);
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword(e.target.value);
    }
  };

  return (
    <AuthForm
      handleOnSubmit={handleOnSubmit}
      handleOnChange={handleOnChange}
      title="Change Password"
      isLogin={false}
      isChangePassword={true}
      oldPassword={oldPassword}
      errors={errors}
      loading={loading}
      role={null}
    />
  );
};

export default ChangePassword;
