import { Divider, Grid, Link, Paper, TextField } from '@mui/material';
import moment from 'moment';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { ChangeEventType, ChatUser } from '../../../types';
import { humanizeString } from '../../../utils/stringModifier';
import { fbPageId } from '../../../services/api';
import _ from 'lodash';
import { Box } from '@mui/system';
import {
  releaseThreadControl,
  sendMessage,
  takeThreadControl,
} from '../../../services/chats';
import { getMinMaxMinutes } from '../../../services/min-max-minutes';
import ScrollButton from '../../../pages/ChatDialog/ScrollButton';
import CircularLoading from '../../CircularLoading';
import CannedResponsesModal from '../../Modal/CannedResponsesModal';
import { Attachment, FileInput, FileInputError } from '../../../types';
import styles from '../../../assets/styles/components/Forms/DesktopChatDialog.module.scss';
import DesktopChatDialogUserDetails from './DesktopChatDialogUserDetails';
import DesktopChatDialogSendMessageForm from './DesktopChatDialogSendMessageForm';
import DesktopChatBox from './DesktopChatBox';
import DesktopChatList from './DesktopChatList';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';

interface DesktopChatDialogProps {
  chats: ChatUser[];
  onSelectedChat: (chat: ChatUser) => void;
  chat: ChatUser;
  loading: boolean;
  handleSrollPagination: (e: any) => void;
  handleOnSearch: (e: ChangeEventType) => void;
  isAgency: boolean;
  isBrandManager: boolean;
}

const DesktopChatDialog: React.FC<DesktopChatDialogProps> = ({
  chats,
  onSelectedChat,
  chat,
  loading,
  handleSrollPagination,
  handleOnSearch,
  isAgency,
  isBrandManager,
}) => {
  const dispatch = useDispatch();
  const [chatMessages, setChatMessages] = useState(null);
  const [messagesToShow, setMessagesToShow] = useState(null);
  const [sendMsgLoading, setSendMsgLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false);
  const [threadControlTaken, setThreadControlTaken] = useState<boolean>(false);
  const [showEmojis, setShowEmojis] = useState<boolean>(false);
  const [openCannedResponsesModal, setOpenCannedResponsesModal] =
    useState<boolean>(false);
  const [fileError, setFileError] = useState<FileInputError>(null);
  const [file, setFile] = useState<Attachment>(null);
  const [fileDetails, setFileDetails] = useState<FileInput>(null);
  const [selectedChat, setSelectedChat] = useState<string>('');
  const [previousSelectedChat, setPreviousSelectedChat] = useState<string>('');
  const [called, setCalled] = useState(false);

  const scrollRef: any = useRef();
  const mountedRef: any = useRef(true);
  const boxRef: any = useRef();

  useEffect(() => {
    if (chat) {
      const msgObj = chat?.messages.find((msg: any) => {
        return (
          msg?.brandId === chat?.brand?.brandId.toString() &&
          msg?.user === chat?.chatuserId
        );
      });
      setChatMessages(msgObj);
      if (chatMessages) {
        const temp = _.groupBy(chatMessages.messages, (m) =>
          moment(m.timestamp).format('LL'),
        );
        setMessagesToShow(temp);
      }
    } else {
      setChatMessages(null);
      setMessagesToShow([]);
    }
  }, [chat, chatMessages]);

  const formattedDate = (lastInteraction: Date) => {
    return moment.utc(lastInteraction).local().format('YYYY-MM-DDTHH:mm:ss');
  };

  useEffect(() => {
    const threadControl = localStorage.getItem('threadControl');
    if (threadControl) {
      setThreadControlTaken(true);
    }
  }, []);

  useEffect(() => {
    getMinMax();
    return () => {
      mountedRef.current = false; // clean up function
    };
  }, []);

  const getMinMax = async () => {
    const { min, max, message } = await getMinMaxMinutes();
    setMin(min);
    setMax(max);
    setErrorMessage(message);
  };

  /**
   * Moment fromNow Method Threshold
   * @description Create # of days threshold and abbreviation
   * @author Angelo David <angelod@codev.com>
   * @since 1.5.0
   * @references https://github.com/moment/moment/issues/2781#issuecomment-160739129
   */
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24);
  moment.updateLocale('en', {
    relativeTime: {
      s: '%ds',
      ss: '%ds',
      m: '%dmin',
      mm: '%dmins',
      h: '%dhr',
      hh: '%dhrs',
      d: '%dd',
      dd: '%dd',
      M: '%dmon',
      MM: '%dmos',
    },
  });

  const handleSendMessage = async (e: FormEvent) => {
    const psId = chat.chatuserId;
    e.preventDefault();

    setSendMsgLoading(true);

    if (sendMsgLoading || fileError?.error || (message === '' && !file)) {
      setSendMsgLoading(false);
      return;
    }

    try {
      if (chat) {
        const psId = chat.chatuserId;
        await takeThreadControl(psId, chat?.page?._id);
        setThreadControlTaken(true);
      }

      const obj = {
        text: message,
        chatId: chat._id,
        brandId: chat.brand.brandId,
        pageId: chat?.page?._id,
        attachment: file,
      };

      const nowTime = Date.now();
      const fromTime = chatMessages?.userLastInteraction;
      const lastInteraction = Math.floor((nowTime - fromTime) / 60000);

      if (lastInteraction < min || lastInteraction > max) {
        setSendMsgLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: errorMessage,
            type: 'error',
          }),
        );
        return true;
      }

      const res = await sendMessage(psId, obj);

      if (res) {
        const { chat, attachment } = res;
        if (chat) {
          const tempObj = {
            sender: { id: fbPageId },
            recipient: { id: chat?.recipient_id },
            message: { text: message, mid: chat?.message_id },
            timestamp: new Date(),
          };
          setChatMessages({
            ...chatMessages,
            messages: [...chatMessages.messages, tempObj],
          });

          setMessage('');
        }

        if (attachment) {
          setFile(null);
          setFileDetails(null);
          setFileError(null);
        }

        setShowScrollBtn(true);
      }

      await releaseThreadControl(psId, chat?.page?._id);
    } catch (error: any) {
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err.message,
          type: 'error',
        }),
      );
      setSendMsgLoading(false);
    } finally {
      setSendMsgLoading(false);
    }
  };

  const handleOnChange = (e: ChangeEventType) => {
    setMessage(e.target.value);
  };

  const handleAddEmoji = (event: any, obj: any) => {
    setMessage(message + obj.emoji);
    setShowEmojis(false);
  };

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const scrollData = scrollHeight - scrollTop - clientHeight;

    if (Math.abs(scrollData) < 2) {
      setShowScrollBtn(false);
    } else {
      setShowScrollBtn(true);
    }
  };

  const handleScrollDown = () => {
    boxRef.current.scrollTo({
      top: scrollRef.current?.offsetTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setCalled(false);
    if (
      messagesToShow !== null &&
      !called &&
      previousSelectedChat !== selectedChat
    ) {
      handleScrollDown();

      const { scrollTop, scrollHeight, clientHeight } = boxRef.current;
      const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 2;
      if (bottom) {
        setCalled(true);
        setPreviousSelectedChat(selectedChat);
      }
    }
  }, [messagesToShow]);

  const setResponseMessage = (message: string) => {
    setMessage(message);
    handleCannedResponsesModal(false);
  };

  const handleCannedResponsesModal = (value: boolean) => {
    setOpenCannedResponsesModal(value);
  };

  const handleFileUpload = (e: any) => {
    setFileDetails(null);
    setFileError(null);
    setFile(null);
    const file = e.target.files[0];
    if (file.size / (1024 * 1024) > 25) {
      setFileError({
        error: 'Oops! The size limit for files is 25 MB.',
      });
    } else {
      setFileDetails(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFile({ file: reader.result, type: file.type, name: file.name });
      };
    }
  };

  const handleRemoveAttachment = () => {
    setFile(null);
    setFileDetails(null);
    setFileError(null);
  };

  const messageContent = (isOwnMessage: boolean, message: any) => {
    if (message.text) {
      return (
        <div
          className={`${styles['content']} ${
            isOwnMessage ? styles['-own-message'] : ''
          }`}
        >
          {message.text}
        </div>
      );
    } else if (message.attachments) {
      if (message.attachments[0].type === 'image') {
        const url = message.attachments[0].payload.url;
        const sticker = message.attachments[0].payload.sticker_id;

        return (
          <div
            className={`${styles['attachment']} ${styles['-image']} ${
              isOwnMessage ? styles['-own-message'] : ''
            }`}
          >
            <img
              width={`${sticker ? '10%' : '100%'}`}
              height="100%"
              src={url}
              alt=""
            />
          </div>
        );
      } else if (
        ['file', 'video', 'audio'].includes(message.attachments[0].type)
      ) {
        const type = message.attachments[0].type;
        return (
          <div
            className={`${styles['attachment']} ${styles['-file']} ${
              isOwnMessage ? styles['-own-message'] : ''
            }`}
          >
            <Link
              href={`${message.attachments[0].payload.url}`}
              target="_blank"
              className={`${styles['text']} ${
                isOwnMessage ? styles['-own-message'] : ''
              }`}
            >
              {`${humanizeString(type)} Attachement`}
            </Link>
          </div>
        );
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSendMessage}
      className={styles.dialog}
    >
      <Grid
        container
        component={Paper}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: 'calc(100vh - 235px)',
          maxHeight: 'calc(100vh - 235px)',
        }}
      >
        <CircularLoading loading={loading} />

        <Grid item xs={3} sx={{ borderRight: '1px solid #e0e0e0' }}>
          <Grid item xs={12} sx={{ padding: '10px' }}>
            <TextField
              id="outlined-basic-email"
              label="Search"
              variant="outlined"
              fullWidth
              onChange={handleOnSearch}
              size="small"
            />

            <Divider />

            {/** CHAT LIST SECTION */}
            <DesktopChatList
              onScroll={handleSrollPagination}
              chat={chat}
              chats={chats}
              onSelectChat={onSelectedChat}
              setSelectedChat={setSelectedChat}
              dateFormatter={formattedDate}
            />
          </Grid>
        </Grid>

        {/** CHAT DIALOG SECTION */}
        <Grid item xs={6}>
          <DesktopChatBox
            boxRef={boxRef}
            onScroll={handleScroll}
            isAgency={isAgency}
            isBrandManager={isBrandManager}
            messages={messagesToShow}
            chat={chat}
            scrollRef={scrollRef}
            messageContent={messageContent}
          />

          <Box
            sx={{
              position: 'absolute',
              bottom: '220px',
              left: '57%',
              zIndex: 100,
            }}
          >
            {showScrollBtn && (
              <ScrollButton handleScrollDown={handleScrollDown} />
            )}
          </Box>

          {/** CHAT SEND MESSAGE FORM SECTION */}
          {!isAgency && !isBrandManager ? (
            <DesktopChatDialogSendMessageForm
              chat={chat}
              onOpenCannedResponses={handleCannedResponsesModal}
              onSendMessage={handleSendMessage}
              onChange={handleOnChange}
              message={message}
              onFileUpload={handleFileUpload}
              showEmojis={showEmojis}
              setShowEmojis={setShowEmojis}
              onAddEmoji={handleAddEmoji}
              loading={sendMsgLoading}
              onSubmit={handleSendMessage}
              fileError={fileError}
              fileDetails={fileDetails}
              onRemoveAttachment={handleRemoveAttachment}
            />
          ) : null}
        </Grid>

        {/** CHAT USER DETAILS SECTION */}
        <DesktopChatDialogUserDetails chat={chat} />
      </Grid>

      <CannedResponsesModal
        open={openCannedResponsesModal}
        handleCannedResponsesModal={handleCannedResponsesModal}
        chat={chat}
        setMessage={setResponseMessage}
        message={message}
      />
    </Box>
  );
};

export default DesktopChatDialog;
