import React, { FormEvent, useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Home.module.scss';
import { AuthContext } from '../../context';
import {
  Agency,
  ChangeEventType,
  MinMaxMinute,
  PageFormFields,
} from '../../types';
import { ADMIN, AGENCY, SUPER_ADMIN } from '../../utils';
import { adsProvider } from '../../utils/constants/facebookAds';
import { createFbLoginEmails, fetchAdminData } from '../../services/admin';
import { connectFbPage, disconnectPage } from '../../services/page';
import CircularLoading from '../../components/CircularLoading';
import { Box } from '@mui/material';
import MinMaxMinutesForm from '../../components/Forms/MinMaxMinutesForm';
import ProviderEmailForm from '../../components/Forms/ProviderEmailForm';
import { XsOnly } from '../../utils/breakpoints';
import ConnectFacebookPageForm from '../../components/Forms/ConnectFacebookPageForm';
import { getSingleAgency } from '../../services/agency';
import {
  getMinMaxMinutes,
  updateMinMaxMinutes,
} from '../../services/min-max-minutes';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';

const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [agency, setAgency] = useState<Agency>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const isAgency = state.role === AGENCY;
  const roleBasedId = state.roleBasedId;
  const [fbLoginEmails, setFbLoginEmails] = useState<string[]>([]);
  const [googleLoginEmails, setGoogleLoginEmails] = useState<string[]>([]);
  const [values, setValues] = useState<MinMaxMinute>({
    min: 0,
    max: 0,
    message: '',
    _id: '',
  });
  const [openPageList, setOpenPageList] = useState<boolean>(false);
  const [connectLoading, setConnectLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      const { min, max, message, _id } = await getMinMaxMinutes();
      setValues({ ...values, min, max, message, _id });

      if (isAgency) {
        getAgency();
      }

      if (isSuperAdmin) getFbLoginEmails();

      setFetchLoading(false);
    })();
  }, []);

  const getAgency = async () => {
    try {
      const data = await getSingleAgency(roleBasedId);

      setAgency(data);
      setFbLoginEmails(data?.fbLoginEmails || []);
      setGoogleLoginEmails(data?.googleLoginEmails || []);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getFbLoginEmails = async () => {
    setLoading(true);

    try {
      const response = await fetchAdminData();

      setFbLoginEmails(response.data?.fbLoginEmails || []);
      setGoogleLoginEmails(response.data?.googleLoginEmails || []);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const submitTimeThreshold = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { message, updatedMinMaxMinute } = await updateMinMaxMinutes(
        values._id,
        {
          min: values.min,
          max: values.max,
          message: values.message,
        },
      );
      const { min, max } = updatedMinMaxMinute;
      setValues({ ...values, min, max });
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message,
        }),
      );
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const onChangeTimeThreshold = (e: ChangeEventType) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmitEmails = async (provider: string) => {
    setLoading(true);
    try {
      let query: any = {};

      if (provider === adsProvider.FACEBOOK) {
        query = { ...query, emails: fbLoginEmails, provider };
      } else if (provider === adsProvider.GOOGLE) {
        query = { ...query, emails: googleLoginEmails, provider };
      }

      if (isAgency) {
        query = { ...query, agencyId: agency._id };
      }

      const response = await createFbLoginEmails(query);

      setFbLoginEmails(response.data.fbLoginEmails);
      setGoogleLoginEmails(response.data.googleLoginEmails);
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: response.message,
        }),
      );
    } catch (error: any) {
      setLoading(false);
      dispatch(
        toggleAlert({
          toggle: true,
          message: error.message,
          type: 'error',
        }),
      );
    }
  };

  const handleOpenPageListModal = () => {
    setOpenPageList(true);
  };

  const handleClosePageListModal = () => {
    setOpenPageList(false);
  };

  const handleConnectPage = async (
    page: any,
    objectId: string,
    model: string,
  ) => {
    setConnectLoading(true);

    try {
      const formObj: PageFormFields = {
        accessToken: page?.accessToken,
        name: page?.name,
        fbPageId: page?.fbPageId,
        imageUrl: page?.imageUrl,
        objectId,
        model,
      };
      const data = await connectFbPage(formObj);
      if (data) {
        const response = {
          _id: data.page?._id,
          accessToken: data.page?.accessToken,
          name: data.page?.name,
          fbPageId: data.page?.fbPageId,
          imageUrl: data.page?.imageUrl,
        };

        let temp: Agency = agency;
        temp.page = response;

        setAgency(temp);
        handleClosePageListModal();
        setConnectLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      setConnectLoading(false);
      const err = error.response.data;
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
    }
  };

  const handleDisconnectPage = async (
    page: any,
    objectId: string,
    model: string,
  ) => {
    setConnectLoading(true);

    try {
      const formObj: PageFormFields = {
        accessToken: page?.accessToken,
        name: page?.name,
        fbPageId: page?.fbPageId,
        imageUrl: page?.imageUrl,
        objectId,
        model,
      };

      const data = await disconnectPage(formObj);
      if (data) {
        let temp: Agency = agency;
        temp.page = null;

        setAgency(temp);
        setConnectLoading(false);
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
      }
    } catch (error: any) {
      console.log(error.message);
      setConnectLoading(false);
    }
  };

  return (
    <div className={styles.home}>
      <div className={styles.base}>
        <CircularLoading loading={fetchLoading} />

        {isSuperAdmin || isAdmin ? (
          <Box>
            <MinMaxMinutesForm
              handleOnSubmit={submitTimeThreshold}
              handleOnChange={onChangeTimeThreshold}
              values={values}
              loading={loading}
            />
          </Box>
        ) : null}

        {isSuperAdmin || isAdmin || isAgency ? (
          <ProviderEmailForm
            emails={fbLoginEmails}
            setLoginEmails={setFbLoginEmails}
            loading={loading}
            handleSubmit={handleSubmitEmails}
            provider={adsProvider.FACEBOOK}
          />
        ) : null}

        {isSuperAdmin || isAdmin || isAgency ? (
          <ProviderEmailForm
            emails={googleLoginEmails}
            setLoginEmails={setGoogleLoginEmails}
            loading={loading}
            handleSubmit={handleSubmitEmails}
            provider={adsProvider.GOOGLE}
          />
        ) : null}

        {isAgency ? (
          <Box
            sx={{
              width: XsOnly() ? '100%' : '660px',
              flexDirection: 'column',
              marginTop: '20px',
            }}
          >
            <ConnectFacebookPageForm
              page={agency?.page}
              objectId={roleBasedId}
              model="Agency"
              handleConnect={handleConnectPage}
              handleDisconnect={handleDisconnectPage}
              loading={connectLoading}
              open={openPageList}
              handleClosePageList={handleClosePageListModal}
              handleOpenPageList={handleOpenPageListModal}
              settings
            />
          </Box>
        ) : null}
      </div>
    </div>
  );
};

export default Settings;
