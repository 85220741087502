import axios from 'axios';
import { apiBaseUrl } from '../api';

export const getStripeTestClock = async (brandId: string) => {
  const data = await axios.get(
    `${apiBaseUrl}/payment/stripe/test-clock?brandId=${brandId}`,
  );

  return data?.data;
};

export const advanceStripeTestClock = async (
  brandId: string,
  advanceTime: number,
) => {
  const data = await axios.post(
    `${apiBaseUrl}/payment/stripe/test-clock/advance`,
    { brandId, advanceTime },
  );

  return data?.data;
};
