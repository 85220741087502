import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { buildRowData } from '../../../utils/helpers/BrandAnalyticsHelper';
import { toCurrency } from '../../../utils/numberFormatter';
import { IconButton, TableBody, Tooltip, Typography } from '@mui/material';
import { DEFAULT_COLOR_THEME, StyledPaper } from '../../../utils/Styling';
import { FbAdStatus } from '../../../utils/helpers/facebookAdsHelper';
import { constantStringToHumanized } from '../../../utils/stringModifier';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: DEFAULT_COLOR_THEME,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface CampaignOverviewProps {
  rows: any[];
  campaigns?: any[];
  isSummary?: boolean;
  lead?: number;
  ads?: any[];
  adsets?: any[];
  isCampaign?: boolean;
  isAds?: boolean;
  isAdset?: boolean;
  leadAds?: any;
  messengerAds?: any;
  trafficAds?: any;
  onOpenPreview?: (adId: string) => void;
  salesAds?: any;
  showQL?: boolean;
}

const CampaignOverview: React.FC<CampaignOverviewProps> = ({
  rows,
  lead = 0,
  campaigns = [],
  ads = [],
  isSummary = false,
  isCampaign = false,
  isAds = false,
  leadAds,
  messengerAds,
  trafficAds,
  isAdset = false,
  adsets = [],
  onOpenPreview,
  salesAds,
  showQL = false,
}) => {
  const rowData = () => {
    return buildRowData(
      isCampaign,
      lead,
      isAds,
      isSummary,
      isAdset,
      campaigns,
      ads,
      adsets,
      leadAds,
      messengerAds,
      trafficAds,
      salesAds,
    );
  };

  const setTextTheme = (status: string) => {
    if (status === FbAdStatus.ACTIVE) {
      return 'unset';
    }

    if (status === FbAdStatus.IN_PROCESS) {
      return 'blue';
    }

    return 'red';
  };

  const sortedRows = (rows: any[]) => {
    const customOrder = ['ACTIVE', 'IN_PROCESS'];

    rows.sort((a: any, b: any) => {
      const indexA = customOrder.indexOf(a.status);
      const indexB = customOrder.indexOf(b.status);

      if (indexA !== -1 || indexB !== -1) {
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      }

      return a.status.localeCompare(b.status);
    });

    return rows;
  };

  return (
    <div>
      <Paper sx={StyledPaper()}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {rows.map((row: any, index: number) => (
                  <StyledTableCell key={index} align="center">
                    {row}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rowData().length > 0 ? (
                sortedRows(rowData()).map((row: any, index: any) => (
                  <StyledTableRow key={index}>
                    {!isSummary ? (
                      <StyledTableCell
                        align="center"
                        sx={{
                          color: setTextTheme(row.status),
                          fontWeight: !(row.status === FbAdStatus.ACTIVE)
                            ? 'bold'
                            : 'unset',
                        }}
                      >
                        {row.status !== FbAdStatus.ACTIVE ? (
                          <Tooltip
                            title={
                              <Typography variant="body2">
                                {constantStringToHumanized(row.status)}
                              </Typography>
                            }
                            disableInteractive
                          >
                            {row.name}
                          </Tooltip>
                        ) : (
                          row.name
                        )}
                      </StyledTableCell>
                    ) : null}

                    <StyledTableCell align="center">
                      {row.clicks}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {toCurrency('USD', row.cpc)}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.impressions}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {toCurrency('USD', row.cpm)}
                    </StyledTableCell>

                    <StyledTableCell align="center">{row.lead}</StyledTableCell>

                    <StyledTableCell align="center">
                      {`$${row.cpl}`}
                    </StyledTableCell>

                    {showQL ? (
                      <>
                        <StyledTableCell align="center">
                          {row.ql}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {`$${row.cpql}`}
                        </StyledTableCell>
                      </>
                    ) : null}

                    <StyledTableCell align="center">
                      {toCurrency('USD', row.spend)}
                    </StyledTableCell>

                    {isAds ? (
                      <StyledTableCell align="center">
                        <IconButton
                          onClick={() => {
                            onOpenPreview(row.id);
                          }}
                          sx={{ fontSize: '13px', color: '#1976d2' }}
                        >
                          Preview Ad
                        </IconButton>
                      </StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CampaignOverview;
