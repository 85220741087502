import React, { useContext, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Campaigns.module.scss';
import PageHeader from '../../components/PageHeader';
import {
  AdsAccountFormFields,
  Brand,
  CampaignFormFields,
  FbAdsAccountData,
  FbCampaign,
} from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAdAccount,
  createCampaign,
  deleteCampaign,
  fetchAdsAccountByBrand,
  fetchBrandCampaigns,
  fetchFbAdsAccount,
  fetchFbCampaigns,
  removeAdAccount,
  syncConnectedCampaigns,
  syncFbBusinessAccount,
} from '../../services/ads';
import { AuthContext } from '../../context';
import { setBrand, toggleAlert } from '../../redux/actions';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { adsProvider } from '../../utils/constants/facebookAds';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AdAccountForm from '../../components/Forms/Campaigns/AdAccountForm';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import {
  fetchGoogleAdsAccount,
  fetchGoogleCampaigns,
  syncCustomerIds,
} from '../../services/googleAds';
import { arrayUniqueFilter } from '../../utils/arrayFormatter';
import CampaignForm from '../../components/Forms/Campaigns/CampaignForm';
import CircularLoading from '../../components/CircularLoading';
import { NoData } from '../NoContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrimaryButton } from '../../components/Buttons';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { IS_DIY_ADZ } from '../../utils';

const Campaigns: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider: string =
    useSelector((state: any) => state?.campaignProvider?.campaignProvider) ||
    'facebook';
  const { state } = useContext(AuthContext);
  const refreshToken = state.authUser?.refreshToken;
  const tabs = ['Connect Ad Accounts', 'Setup Campaigns'];
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;
  const isGoogle = campaignProvider === adsProvider.GOOGLE;

  const [campaigns, setCampaigns] = useState<CampaignFormFields[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('1');
  const [fbAdsAccounts, setFbAdsAccounts] = useState<AdsAccountFormFields[]>(
    [],
  );
  const [adAccounts, setAdAccounts] = useState<AdsAccountFormFields[]>([]);
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>(null);
  const [connectedAccountIds, setConnectedAccountIds] = useState<string[]>([]);
  const [googleAdAccounts, setGoogleAdAccounts] = useState<
    AdsAccountFormFields[]
  >([]);
  const [providerCampaigns, setProviderCampaigns] = useState<
    CampaignFormFields[]
  >([]);
  const [expanded, setExpanded] = useState<string>('panel1');

  useEffect(() => {
    handleBuildProviderTokens();
  }, [refreshToken]);

  useEffect(() => {
    if (location || brand) {
      getCampaigns();

      if (isFacebook) {
        getAdsAccounts();
        syncBrandCampaigns();
        getFbCampaigns();
        getFbAdsAccounts();
      } else if (isGoogle && defaultRefreshToken) {
        getAdsAccounts();
        getGoogleCampaigns();
        getGoogleAdAccounts();
      }
    }
  }, [location, brand, isFacebook, isGoogle, defaultRefreshToken]);

  const syncBrandCampaigns = async () => {
    try {
      const obj = {
        brandId: (location || brand)?._id,
        campaigns,
      };

      syncConnectedCampaigns(obj);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getAdsAccounts = async () => {
    try {
      setLoading(true);

      const response = await fetchAdsAccountByBrand(
        (location || brand)?._id,
        campaignProvider,
      );

      let temp: AdsAccountFormFields[] = [];
      if (isFacebook) {
        temp = await response?.data.filter(
          (data: any) => data.provider === adsProvider.FACEBOOK,
        );
      } else if (isGoogle) {
        temp = await response?.data.filter(
          (data: any) => data.provider === adsProvider.GOOGLE,
        );
      }

      setAdAccounts(temp);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const campaigns = await fetchBrandCampaigns(
        (location || brand)?._id,
        campaignProvider,
      );

      setCampaigns(campaigns.data);

      const accountIds = campaigns.data.map((obj: any) => obj.accountId);

      const filteredIds = arrayUniqueFilter(accountIds);

      setConnectedAccountIds(filteredIds);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getFbCampaigns = async () => {
    try {
      setLoading(true);

      const campaigns = await fetchFbCampaigns((location || brand)?._id);

      let temp: any = [];
      campaigns.data.forEach(async (campaign: FbCampaign) => {
        const formattedObj: CampaignFormFields = {
          id: campaign.id,
          name: campaign.name,
          objective: campaign.objective,
          status: campaign.effective_status,
          accountId: campaign.account_id,
          provider: adsProvider.FACEBOOK,
          special_ad_categories: campaign?.special_ad_categories || [],
        };
        const campaignValue = { ...campaign, ...formattedObj };
        temp = [...temp, campaignValue];
      });

      setProviderCampaigns(temp);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getFbAdsAccounts = async () => {
    try {
      setLoading(true);

      const response = await fetchFbAdsAccount(
        (location || brand)?.facebookBusinessIds || [],
        (location || brand)?._id,
      );

      let newFormValues: any[] = [];

      response.data.forEach((adsAccount: FbAdsAccountData) => {
        const formattedObj: AdsAccountFormFields = {
          accountId: adsAccount.account_id,
          name: adsAccount.name,
          currency: adsAccount.currency,
          provider: adsProvider.FACEBOOK,
        };

        newFormValues = [...newFormValues, formattedObj];
      });

      setFbAdsAccounts(newFormValues);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getGoogleCampaigns = async () => {
    try {
      setLoading(true);
      const campaigns = await fetchGoogleCampaigns(
        (location || brand)?._id,
        defaultRefreshToken,
      );

      setProviderCampaigns(campaigns?.data || []);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getGoogleAdAccounts = async () => {
    try {
      setLoading(true);

      const response = await fetchGoogleAdsAccount(
        (location || brand)?._id,
        defaultRefreshToken,
      );

      setGoogleAdAccounts(response?.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAdsAccount = async (adAccount: AdsAccountFormFields) => {
    try {
      setLoading(true);

      let temp: AdsAccountFormFields = {
        ...adAccount,
        brand: (location || brand)?._id,
        manual: true,
        diy: IS_DIY_ADZ,
      };

      const response = await createAdAccount(temp);

      let tempAccounts: AdsAccountFormFields[] = [];
      if (adAccounts.length === 0) {
        tempAccounts = [response.data];
      } else {
        adAccounts.forEach((account: AdsAccountFormFields) => {
          if (account.accountId !== response.data.accountId) {
            tempAccounts = [...(adAccounts || []), response.data];
          } else {
            if (account.accountId === response.data.accountId) {
              account = response.data;
            }

            tempAccounts = [...tempAccounts, account];
          }
        });
      }

      setAdAccounts(tempAccounts);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} ad account was added successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAdsAccount = async (adAccount: AdsAccountFormFields) => {
    try {
      setLoading(true);

      const response = await removeAdAccount(
        adAccount.accountId,
        (location || brand)?._id,
        campaignProvider,
      );

      const tempAccounts = adAccounts.filter(
        (account: AdsAccountFormFields) =>
          account?.accountId !== response.data.accountId,
      );

      setAdAccounts(tempAccounts);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} ad account was removed`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleSyncBusinessIds = async (value: any[], brand: Brand) => {
    try {
      setLoading(true);

      const data = await syncFbBusinessAccount(value, brand._id);

      let temp: Brand = { ...brand };

      temp = {
        ...temp,
        facebookBusinessIds: data?.data.facebookBusinessIds,
      };

      dispatch(setBrand(temp));

      getFbAdsAccounts();
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBuildProviderTokens = () => {
    buildRefreshToken(refreshToken, setDefaultRefreshToken);
  };

  const handleSyncCustomerIds = async (value: string[], brand: Brand) => {
    try {
      setLoading(true);
      const data = await syncCustomerIds(brand._id, value);

      let temp: Brand = { ...brand };

      temp = {
        ...temp,
        googleCustomerIds: data?.data.googleCustomerIds,
      };

      dispatch(setBrand(temp));

      getGoogleAdAccounts();
      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleOnSelectCampaign = async (campaign: CampaignFormFields) => {
    try {
      setLoading(true);
      let temp: CampaignFormFields = {
        ...campaign,
        brand: (location || brand)?._id,
        diy: IS_DIY_ADZ,
        provider: campaignProvider,
      };

      const response = await createCampaign(temp);

      let tempCampaigns: CampaignFormFields[] = [];
      if (campaigns.length === 0) {
        tempCampaigns = [response.data];
      } else {
        campaigns.forEach((campaign: CampaignFormFields) => {
          if (campaign.id !== response.data.id) {
            tempCampaigns = [...campaigns, response.data];
          } else {
            if (campaign.id === response.data.id) {
              campaign = response.data;
            }

            tempCampaigns = [...tempCampaigns, campaign];
          }
        });
      }

      setCampaigns(tempCampaigns);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} campaign was added successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleOnRemoveCampaign = async (campaign: CampaignFormFields) => {
    try {
      setLoading(true);
      const response = await deleteCampaign(
        campaign.id,
        (location || brand)?._id,
        campaignProvider,
      );

      const tempCampaigns = campaigns.filter(
        (campaign: CampaignFormFields) => campaign?.id !== response.data.id,
      );

      setCampaigns(tempCampaigns);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} campaign was removed`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  const handleOnExpand = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const renderContent = (tab: string) => {
    if (tab === 'Connect Ad Accounts') {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AdAccountForm
              fbAdsAccounts={fbAdsAccounts}
              adAccounts={adAccounts}
              onSelectAdsAccount={handleSelectAdsAccount}
              brand={location || brand}
              connectedAccountsIds={connectedAccountIds}
              handleSync={handleSyncBusinessIds}
              handleSyncGoogleCustomerIds={handleSyncCustomerIds}
              googleAdAccounts={googleAdAccounts}
              isFacebook={isFacebook}
              isGoogle={isGoogle}
              onRemoveAdAccount={handleRemoveAdsAccount}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {adAccounts.filter(
            (obj: AdsAccountFormFields) => !(obj.deleted === true),
          ).length > 0 ? (
            <CampaignForm
              providerCampaigns={providerCampaigns}
              brandCampaigns={campaigns}
              onSelectCampaign={handleOnSelectCampaign}
              provider={campaignProvider}
              onRemoveCampaign={handleOnRemoveCampaign}
            />
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.campaigns}>
      <div className={styles.base}>
        <CircularLoading loading={loading} />

        <div className={styles.header}>
          <PageHeader title={IS_DIY_ADZ ? 'Campaignz' : 'Campaigns'} />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              <PrimaryButton
                type="button"
                title="Ads Manager"
                marginRight5
                startIcon={<SettingsIcon />}
                handleOnClick={() => navigate('/ads-manager')}
              />
            </div>
          </div>
        </div>

        <div>
          {!isMdAndDown ? (
            <TabContext value={tabValue}>
              <TabList
                onChange={handleChangeTabValue}
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons
                textColor="secondary"
                TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
                TabScrollButtonProps={{
                  style: {
                    backgroundColor: DEFAULT_COLOR_THEME,
                    color: 'white',
                  },
                }}
                sx={{
                  backgroundColor: DEFAULT_COLOR_THEME,
                  '.MuiTab-root': {
                    top: 5,
                    color: 'white',
                  },
                  '.Mui-selected': {
                    color: `${DEFAULT_COLOR_THEME} !important`,
                    backgroundColor: 'white',
                    top: 5,
                    borderRadius: 2,
                  },
                }}
              >
                {tabs.map((tab: string, index: number) => (
                  <Tab label={tab} value={`${index + 1}`} key={`tab-${tab}`} />
                ))}
              </TabList>

              {tabs.map((tab: string, index: number) => (
                <TabPanel
                  key={`tab-panel-${tab}`}
                  value={`${index + 1}`}
                  sx={{
                    backgroundColor: '#d9d9d933',
                    minHeight: 'calc(100vh - 310px)',
                  }}
                >
                  {renderContent(tab)}
                </TabPanel>
              ))}
            </TabContext>
          ) : (
            <Box component="div" sx={{ marginTop: '10px' }}>
              {tabs.map((tab: string, index: number) => {
                return (
                  <Accordion
                    key={`accordion-${tab}`}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleOnExpand(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls={`panel${index + 1}a-content`}
                      id={`panel${index + 1}a-header`}
                      sx={{ backgroundColor: DEFAULT_COLOR_THEME }}
                    >
                      <Typography sx={{ color: 'white' }}>{tab}</Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ backgroundColor: '#d9d9d933' }}>
                      {renderContent(tab)}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
