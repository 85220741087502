import { Agency } from '../../types';
import { SET_AGENCIES, SET_AGENCY } from '../ActionNames';

const agencies = (state: Agency[] = [], action: any) => {
  switch (action.type) {
    case SET_AGENCIES:
      return { ...state, agencies: action.payload };
    default:
      return state;
  }
};

const agency = (state: Agency = null, action: any) => {
  switch (action.type) {
    case SET_AGENCY:
      return { ...state, agency: action.payload };
    default:
      return state;
  }
};

export { agencies, agency };
