import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Brand,
  BrandErrors,
  ChangeEventType,
  IFacebookUser,
  Page,
  Salesperson,
} from '../../../types';
import DiyGeolocationForm from './DiyGeolocationForm';
import {
  fetchBusinessFbPages,
  fetchFbPageIgAccounts,
} from '../../../services/ads';
import DiyAdsAccountForm from './DiyAdsAccountForm';
import ConnectFacebookAccountForm from '../ConnectFacebookAccountForm';
import { getUserByRoleId } from '../../../services/user';
import { DiySettings, DiySettingsError } from '../../../types/IDiy';
import { getSingleDiySetting } from '../../../services/diy';
import { IS_DIY_ADZ } from '../../../utils';
import { humanizeString } from '../../../utils/stringModifier';
import {
  brandIndustries,
  businessTypes,
} from '../../../utils/helpers/BrandHelpers';
import { deepOrange } from '@mui/material/colors';

interface DiySettingsFormProps {
  formValues: Brand;
  setFormValues: Dispatch<SetStateAction<Brand>>;
  accessToken: string;
  isFranchisee?: boolean;
  isAdmin?: boolean;
  isMarketingManager?: boolean;
  brand?: Brand | null;
  setLoading: Dispatch<SetStateAction<boolean>>;
  role: string;
  userId: string;
  diySettings: DiySettings;
  setDiySettings: Dispatch<SetStateAction<DiySettings>>;
  loading: boolean;
  isEdit: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  errors: BrandErrors;
  diySettingsErrors: DiySettingsError;
}

const DiySettingsForm: React.FC<DiySettingsFormProps> = ({
  formValues,
  setFormValues,
  accessToken,
  isFranchisee = false,
  isAdmin = false,
  isMarketingManager = false,
  brand = null,
  setLoading,
  role,
  userId,
  diySettings,
  setDiySettings,
  loading,
  isEdit,
  setIsDirty,
  errors,
  diySettingsErrors,
}) => {
  const [facebookPages, setFacebookPages] = useState<Page[]>([]);
  const [igAccounts, setIgAccounts] = useState<Page[]>([]);
  const [facebookAccount, setFacebookAccount] = useState<IFacebookUser>(null);
  const [brandDiySettings, setBrandDiySettings] = useState<DiySettings>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [igAccountLoading, setIgAccountLoading] = useState<boolean>(false);
  const [businessId, setBusinessId] = useState<string>('');

  useEffect(() => {
    if (isFranchisee && brand) {
      getBrandDiySettings(brand);
    }
  }, [brand, isFranchisee]);

  useEffect(() => {
    if (diySettings?.defaultUser) {
      getUser();
    }
  }, [diySettings?.defaultUser]);

  useEffect(() => {
    if (
      (isFranchisee
        ? diySettings?.allowSetupOwnAccount
          ? formValues
          : brand
        : formValues
      )?.facebookBusinessIds?.length > 0 &&
      accessToken
    ) {
      getFacebookPages();
    }
  }, [
    (isFranchisee
      ? diySettings?.allowSetupOwnAccount
        ? formValues
        : brand
      : formValues
    )?.facebookBusinessIds,
    accessToken,
    facebookAccount,
  ]);

  /*
  useEffect(() => {
    if (
      (isFranchisee
        ? diySettings?.allowSetupOwnAccount
          ? formValues
          : brand
        : formValues
      )?.facebookBusinessIds?.length > 0 &&
      accessToken
    ) {
      getFacebookPages();
    } else {
      setFacebookPages([]);
      setDiySettings({
        ...diySettings,
        facebookPage: null,
        instagramAccount: null,
      });
    }
  }, [
    (isFranchisee
      ? diySettings?.allowSetupOwnAccount
        ? formValues
        : brand
      : formValues
    )?.facebookBusinessIds,
    accessToken,
  ]);
  */

  useEffect(() => {
    if (diySettings?.facebookPage) {
      getIgAccount();
    } else {
      setIgAccounts([]);
      setDiySettings({
        ...diySettings,
        instagramAccount: null,
      });
    }
  }, [diySettings?.facebookPage]);

  const getFacebookPages = async () => {
    setPageLoading(true);
    try {
      const response = await fetchBusinessFbPages(
        (isFranchisee
          ? diySettings?.allowSetupOwnAccount
            ? formValues
            : brand
          : formValues
        )?.facebookBusinessIds || [],
        facebookAccount?.accessToken
          ? facebookAccount?.accessToken
          : accessToken,
      );

      setFacebookPages(response?.data || []);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setPageLoading(false);
    }
  };

  const getIgAccount = async () => {
    setIgAccountLoading(true);
    try {
      const response = await fetchFbPageIgAccounts(
        diySettings?.facebookPage?.fbPageId,
        diySettings?.facebookPage?.accessToken,
      );

      setIgAccounts(response?.data || []);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setIgAccountLoading(false);
    }
  };

  const getUser = async () => {
    try {
      const response = await getUserByRoleId(
        diySettings?.defaultUser?._id,
        'facebook',
      );

      setFacebookAccount(response?.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getBrandDiySettings = async (brand: Brand) => {
    try {
      const response = await getSingleDiySetting(brand?._id);

      const data: DiySettings = response?.data;

      setBrandDiySettings(data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleAllowOwnAdAccount = (e: ChangeEventType) => {
    setIsDirty(true);
    let temp: DiySettings = {
      ...diySettings,
      [e.target.name]: e.target.value === 'true',
    };

    if (e.target.value === 'true') {
      temp = {
        ...temp,
        fbAdAccount: null,
        facebookPage: null,
        instagramAccount: null,
        defaultUser: null,
      };

      setFormValues({ ...formValues, facebookBusinessIds: [] });
      setBusinessId(null);
      setFacebookAccount(null);
    } else if (
      e.target.value === 'false' &&
      formValues?.__type === 'location'
    ) {
      temp = {
        ...temp,
        fbAdAccount: brandDiySettings?.fbAdAccount,
        facebookPage: brandDiySettings?.facebookPage,
        instagramAccount: brandDiySettings?.instagramAccount,
        defaultUser: null,
      };
    }

    setDiySettings(temp);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">DIY Settings</Typography>
      </Grid>

      {isAdmin || (isMarketingManager && formValues?.__type === 'location') ? (
        <>
          <Grid item xs={12}>
            <FormControl>
              <Typography variant="body2">
                {`Will the ${
                  formValues?.__type === 'location'
                    ? `${brand?.locationLabel}`
                    : formValues?.__type
                } use their own ad account and Facebook page?`}
              </Typography>

              <RadioGroup
                row
                aria-labelledby="allowSetupOwnAccount"
                name="allowSetupOwnAccount"
                value={`${diySettings?.allowSetupOwnAccount || false}`}
                onChange={handleAllowOwnAdAccount}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="Yes"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>

              {!diySettings?.allowSetupOwnAccount &&
              formValues?.allowSetupPayment ? (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  User’s credit card will be charged for media spend
                  immediately.
                </Typography>
              ) : null}
            </FormControl>
          </Grid>

          {formValues?.users?.length > 0 &&
          diySettings?.allowSetupOwnAccount ? (
            <>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option._id}>
                            {`${option.salespersonId}. ${option.name}`}
                          </li>
                        );
                      }}
                      options={formValues?.users?.sort(
                        (a, b) => -b.name?.localeCompare(a.name),
                      )}
                      value={
                        formValues?.users?.find(
                          (user: Salesperson) =>
                            user._id === diySettings?.defaultUser?._id,
                        ) || null
                      }
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Default User"
                          placeholder="Select default user"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          size="small"
                          error={diySettingsErrors?.defaultUser ? true : false}
                          helperText={
                            diySettingsErrors?.defaultUser
                              ? diySettingsErrors?.defaultUser
                              : `Please select the user who has full access to 
                                 both your Ad Account and Facebook page. The
                                 selected user needs to connect their Facebook
                                 account to the platform.`
                          }
                        />
                      )}
                      disableClearable
                      onChange={async (e: any, value: any) => {
                        setIsDirty(true);
                        setDiySettings({
                          ...diySettings,
                          defaultUser: {
                            ...value,
                          },
                        });
                      }}
                      getOptionDisabled={(option) => {
                        return option._id === diySettings?.defaultUser?._id;
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </>
      ) : null}

      {isAdmin ||
      (!isAdmin && diySettings?.allowSetupOwnAccount && facebookAccount) ||
      (!isAdmin && !diySettings?.allowSetupOwnAccount) ? (
        <>
          {diySettings?.allowSetupOwnAccount && facebookAccount ? (
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                }}
                src={`${facebookAccount?.imageUrl}`}
                alt={`${facebookAccount?.firstName}`}
              />

              <Box sx={{ flexDirection: 'column' }}>
                <Typography
                  component="div"
                  variant="body2"
                  color="#484848"
                  fontWeight="bold"
                  ml={1}
                >
                  {`${facebookAccount?.firstName} ${facebookAccount?.lastName}`}
                </Typography>

                <Typography
                  component="div"
                  variant="caption"
                  color="#484848"
                  ml={1}
                >
                  {`${facebookAccount?.facebookUid}`}
                </Typography>
              </Box>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <DiyAdsAccountForm
              brand={brand}
              isEdit={isEdit}
              formValues={formValues}
              setFormValues={setFormValues}
              setLoading={setLoading}
              disabled={
                (isAdmin && diySettings?.allowSetupOwnAccount) ||
                (!isAdmin && !diySettings?.allowSetupOwnAccount) ||
                (!isAdmin &&
                  diySettings?.allowSetupOwnAccount &&
                  diySettings?.defaultUser?._id !== userId)
              }
              facebookAccount={facebookAccount}
              diySettings={diySettings}
              setDiySettings={setDiySettings}
              userId={userId}
              loading={loading}
              setIsDirty={setIsDirty}
              businessId={businessId}
              setBusinessId={setBusinessId}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {formValues?.facebookBusinessIds?.length > 0 &&
              facebookPages?.length > 0 &&
              !pageLoading ? (
                <Grid item xs={12} sm={6} flexDirection="column">
                  <Autocomplete
                    id="fbPage"
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Avatar
                            src={option.imageUrl}
                            sx={{ marginRight: '10px' }}
                          />

                          <Box component="div" sx={{ flexDirection: 'column' }}>
                            <Typography variant="body1">
                              {option.name}
                            </Typography>

                            <Typography variant="caption">
                              {option.fbPageId}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }}
                    options={facebookPages?.sort(
                      (a, b) => -b.name?.localeCompare(a.name),
                    )}
                    loading={pageLoading}
                    defaultValue={facebookPages?.find(
                      (page: Page) =>
                        page.fbPageId === diySettings?.facebookPage?.fbPageId,
                    )}
                    value={
                      facebookPages?.find(
                        (page: Page) =>
                          page.fbPageId === diySettings?.facebookPage?.fbPageId,
                      ) || null
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Facebook Page"
                        fullWidth
                        size="small"
                        placeholder="Select a Facebook page"
                        required
                        InputLabelProps={{ shrink: true }}
                        //error={errors?.agency ? true : false}
                        //helperText={errors?.agency ? errors?.agency : ''}
                      />
                    )}
                    onChange={async (e: any, value: any, reason: string) => {
                      setIsDirty(true);
                      if (reason !== 'clear') {
                        setDiySettings({
                          ...diySettings,
                          facebookPage: value,
                          instagramAccount: null,
                        });
                      } else {
                        setDiySettings({
                          ...diySettings,
                          facebookPage: null,
                          instagramAccount: null,
                        });
                      }
                    }}
                    disabled={
                      (isAdmin && diySettings?.allowSetupOwnAccount) ||
                      (!isAdmin && !diySettings?.allowSetupOwnAccount)
                    }
                  />

                  {diySettings?.facebookPage ? (
                    <Grid item xs={12} lg={6} mt={1}>
                      <Card>
                        <CardHeader
                          avatar={
                            <Avatar
                              src={
                                facebookPages?.find(
                                  (page: Page) =>
                                    page.fbPageId ===
                                    diySettings?.facebookPage?.fbPageId,
                                )?.imageUrl
                              }
                              alt={diySettings?.facebookPage?.name}
                              sx={{ bgcolor: deepOrange[500] }}
                            />
                          }
                          title={diySettings?.facebookPage?.name}
                          subheader={diySettings?.facebookPage?.fbPageId}
                        />
                      </Card>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}

              {formValues?.facebookBusinessIds?.length > 0 &&
              facebookPages?.length === 0 &&
              !pageLoading ? (
                <Grid item xs={12} sm={6} flexDirection="column">
                  <Autocomplete
                    id="fbPage"
                    options={[]}
                    loading={pageLoading}
                    value={null}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Facebook Page"
                        fullWidth
                        size="small"
                        placeholder="Select a Facebook page"
                        required
                        InputLabelProps={{ shrink: true }}
                        error={true}
                        helperText={`No Facebook page to show. Make sure that you Facebook page is associated with your Business Manager account.`}
                      />
                    )}
                    onChange={null}
                    disabled
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {diySettings?.facebookPage &&
              igAccounts.length > 0 &&
              !igAccountLoading ? (
                <Grid item xs={12} sm={6} flexDirection="column">
                  <Autocomplete
                    id="igAccount"
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Avatar
                            src={option.imageUrl}
                            sx={{ marginRight: '10px' }}
                          />

                          <Box component="div" sx={{ flexDirection: 'column' }}>
                            <Typography variant="body1">
                              {option.instagramUsername}
                            </Typography>

                            <Typography variant="caption">
                              {option.instagramId}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }}
                    options={igAccounts?.sort(
                      (a, b) =>
                        -b.instagramUsername?.localeCompare(
                          a.instagramUsername,
                        ),
                    )}
                    defaultValue={igAccounts?.find(
                      (account: Page) =>
                        account.instagramId ===
                        diySettings?.instagramAccount?.instagramId,
                    )}
                    value={igAccounts?.find(
                      (account: Page) =>
                        account.instagramId ===
                        diySettings?.instagramAccount?.instagramId,
                    )}
                    getOptionLabel={(option) => option.instagramUsername}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Instagram Account"
                        fullWidth
                        size="small"
                        placeholder="Select a Instagram Account"
                        InputLabelProps={{ shrink: true }}
                        //error={errors?.agency ? true : false}
                        //helperText={errors?.agency ? errors?.agency : ''}
                      />
                    )}
                    onChange={async (e: any, value: any, reason: string) => {
                      setIsDirty(true);
                      let selectedAccount: any = null;
                      if (reason !== 'clear') {
                        selectedAccount = value;
                      }

                      setDiySettings({
                        ...diySettings,
                        instagramAccount: selectedAccount,
                      });
                    }}
                    disabled={
                      (isAdmin && diySettings?.allowSetupOwnAccount) ||
                      (!isAdmin && !diySettings?.allowSetupOwnAccount)
                    }
                  />

                  {diySettings?.instagramAccount ? (
                    <Grid item xs={12} lg={6} mt={1}>
                      <Card>
                        <CardHeader
                          avatar={
                            <Avatar
                              src={diySettings?.instagramAccount?.imageUrl}
                            />
                          }
                          title={
                            diySettings?.instagramAccount?.instagramUsername
                          }
                          subheader={diySettings?.instagramAccount?.instagramId}
                        />
                      </Card>
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <Grid xs={12} sm={6} mt={1} />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      {diySettings?.defaultUser?._id === userId ? (
        <ConnectFacebookAccountForm
          isBrand
          userId={userId}
          role={role}
          setUser={setFacebookAccount}
          diySettings={diySettings}
          setDiySettings={setDiySettings}
        />
      ) : null}

      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="standard"
              type="text"
              id="websiteUrl"
              name="defaultUrl"
              label="Default Website URL"
              onChange={(e: ChangeEventType) => {
                setIsDirty(true);
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value,
                });
              }}
              value={diySettings?.defaultUrl}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={diySettingsErrors?.defaultUrl ? true : false}
              helperText={
                diySettingsErrors?.defaultUrl
                  ? diySettingsErrors?.defaultUrl
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              //required
              variant="standard"
              type="text"
              id="defaultEmploymentUrl"
              name="defaultEmploymentUrl"
              label="Default Employment URL"
              onChange={(e: ChangeEventType) => {
                setIsDirty(true);
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value,
                });
              }}
              value={diySettings?.defaultEmploymentUrl}
              InputLabelProps={{ shrink: true }}
              size="small"
              error={diySettingsErrors?.defaultEmploymentUrl ? true : false}
              helperText={
                diySettingsErrors?.defaultEmploymentUrl
                  ? diySettingsErrors?.defaultEmploymentUrl
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              id="defaultCalendarUrl"
              name="defaultCalendarUrl"
              label="Default Calendar URL"
              onChange={(e: ChangeEventType) => {
                setIsDirty(true);
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value,
                });
              }}
              value={diySettings?.defaultCalendarUrl}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          {formValues?.__type === 'brand' ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="text"
                id="defaultPrivacyPolicyUrl"
                name="defaultPrivacyPolicyUrl"
                label="Default Privacy Policy URL"
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setDiySettings({
                    ...diySettings,
                    [e.target.name]: e.target.value,
                  });
                }}
                value={diySettings?.defaultPrivacyPolicyUrl}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="defaultEmailRecipients"
              label="Default Email Recipients"
              onChange={(e: ChangeEventType) => {
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value.trim().split(','),
                });
              }}
              value={diySettings?.defaultEmailRecipients?.join(',')}
              InputLabelProps={{ shrink: true }}
              size="small"
              multiline
              helperText="Enter list of email addresses separated by comma"
            />
          </Grid>
        </Grid>
      </Grid>

      {(formValues?.__type === 'brand' && !formValues?.allowLocations) ||
      (formValues?.__type === 'location' && !isFranchisee) ||
      isAdmin ? (
        <Grid item xs={12}>
          <FormControl>
            <Typography variant="body2">
              {`Allow the ${
                formValues?.__type === 'location'
                  ? `${brand?.locationLabel}`
                  : formValues?.__type
              } to target all geolocation?`}
            </Typography>

            <RadioGroup
              row
              aria-labelledby="allowAllGeolocation"
              name="allowAllGeolocation"
              value={`${diySettings?.allowAllGeolocation || false}`}
              onChange={(e: ChangeEventType) => {
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value === 'true',
                });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
              />

              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      ) : null}

      {isAdmin && !isFranchisee ? (
        <>
          {formValues?.__type === 'brand' && !loading ? (
            <>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="locationLabel"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option}>
                            {humanizeString(`${option}s`)}
                          </li>
                        );
                      }}
                      options={['location', 'consultant']}
                      value={formValues?.locationLabel}
                      getOptionLabel={(option) => humanizeString(`${option}s`)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Location Label"
                          fullWidth
                          size="small"
                          placeholder="Select the location label"
                          required
                          InputLabelProps={{ shrink: true }}
                          error={errors?.locationLabel ? true : false}
                          helperText={
                            errors?.locationLabel ? errors?.locationLabel : ''
                          }
                        />
                      )}
                      disableClearable
                      onChange={async (e: any, value: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          locationLabel: value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="businessType"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        );
                      }}
                      options={businessTypes}
                      value={formValues?.businessType}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Business Type"
                          fullWidth
                          size="small"
                          placeholder="Select a business type"
                          required
                          InputLabelProps={{ shrink: true }}
                          error={errors?.businessType ? true : false}
                          helperText={
                            errors?.businessType ? errors?.businessType : ''
                          }
                        />
                      )}
                      disableClearable
                      onChange={async (e: any, value: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          businessType: value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="industry"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option}>
                            {option}
                          </li>
                        );
                      }}
                      options={brandIndustries}
                      value={formValues?.industry}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Industry"
                          fullWidth
                          size="small"
                          placeholder="Select an industry"
                          required
                          InputLabelProps={{ shrink: true }}
                          error={errors?.industry ? true : false}
                          helperText={errors?.industry ? errors?.industry : ''}
                        />
                      )}
                      disableClearable
                      onChange={async (e: any, value: any) => {
                        setIsDirty(true);
                        setFormValues({
                          ...formValues,
                          industry: value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <FormControl>
              <Typography variant="body2">
                Does the franchise want to generate leads from the ad campaign?
              </Typography>

              <RadioGroup
                row
                aria-labelledby="leadCampaign"
                name="allowLeadAds"
                value={`${diySettings?.allowLeadAds || false}`}
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setDiySettings({
                    ...diySettings,
                    [e.target.name]: e.target.value === 'true',
                  });
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="Yes"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <Typography variant="body2">
                Does the franchise want to utilize our Messenger chatz app to
                for employment ads?
              </Typography>

              <RadioGroup
                row
                aria-labelledby="messengerCampaign"
                name="allowMessengerAds"
                value={`${diySettings?.allowMessengerAds || false}`}
                onChange={(e: ChangeEventType) => {
                  setIsDirty(true);
                  setDiySettings({
                    ...diySettings,
                    [e.target.name]: e.target.value === 'true',
                  });
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="Yes"
                />

                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </>
      ) : null}

      {isAdmin && !isFranchisee ? (
        <Grid item xs={12}>
          <FormControl>
            <Typography variant="body2">
              Does the franchise have an App they want to promote and get more
              downloads for?
            </Typography>

            <RadioGroup
              row
              aria-labelledby="messengerCampaign"
              name="allowAppAds"
              value={`${diySettings?.allowAppAds || false}`}
              onChange={(e: ChangeEventType) => {
                setIsDirty(true);
                setDiySettings({
                  ...diySettings,
                  [e.target.name]: e.target.value === 'true',
                });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
              />

              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      ) : null}

      {diySettings?.allowAppAds ? (
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            type="text"
            id="appDeepLink"
            name="appDeepLink"
            label="App Deep Link"
            onChange={(e: ChangeEventType) => {
              setIsDirty(true);
              setDiySettings({
                ...diySettings,
                [e.target.name]: e.target.value,
              });
            }}
            value={diySettings?.appDeepLink}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="standard"
              type="text"
              id="phone"
              name="phoneNumber"
              label="Phone Number"
              onChange={(e: ChangeEventType) => {
                setIsDirty(true);
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
              }}
              value={formValues?.phoneNumber}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>

      {isAdmin || isMarketingManager || (IS_DIY_ADZ && !isFranchisee) ? (
        <Grid item xs={12}>
          <DiyGeolocationForm
            formValues={formValues}
            setFormValues={setFormValues}
            isAdmin={isAdmin || isMarketingManager}
            diySettings={diySettings}
            setDiySettings={setDiySettings}
            loading={loading}
            setIsDirty={setIsDirty}
            errors={errors}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DiySettingsForm;
