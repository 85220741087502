import React from 'react';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { invoicesColumns } from './BillingInvoiceListTableColumns';
import { StripeInvoiceBreakdown } from '../../../types';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';

interface BillingInvoiceListTableProps {
  invoices: any[];
  loading: boolean;
  openBreakdown: (breakdown: StripeInvoiceBreakdown) => void;
}

const BillingInvoiceListTable: React.FC<BillingInvoiceListTableProps> = ({
  invoices = [],
  loading,
  openBreakdown,
}) => {
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const rows = invoices.map((invoice, index) => {
    const { city, country, line1, line2, postal_code, state } =
      invoice?.customer_address || {};
    const invoiceBreakdown: StripeInvoiceBreakdown = {
      invoiceNumber: invoice?.number,
      issuedDate: moment.unix(invoice?.created).utc().format('MMM DD, YYYY'),
      dueDate: invoice.due_date
        ? moment.unix(invoice?.due_date).utc().format('MMM DD, YYYY')
        : moment.unix(invoice?.created).utc().format('MMM DD, YYYY'),
      accountName: invoice?.account_name,
      customerName: invoice?.customer_name,
      customerEmail: invoice?.customer_email,
      customerPhone: invoice?.customer_phone,
      customerAddress: invoice?.customer_address,
      items: invoice?.lines?.data,
      subTotal: invoice?.subtotal > 0 ? invoice?.subtotal / 100 : 0,
      subTotalTax:
        invoice?.subtotal_excluding_tax > 0
          ? invoice?.subtotal_excluding_tax / 100
          : 0,
      tax: invoice?.tax > 0 ? invoice.tax / 100 : 0,
      total: invoice?.total > 0 ? invoice?.total / 100 : 0,
      amountDue: invoice?.amount_due > 0 ? invoice?.amount_due / 100 : 0,
      amountPaid: invoice?.amount_paid > 0 ? invoice?.amount_paid / 100 : 0,
      amountRemaining:
        invoice?.amount_remaining > 0 ? invoice?.amount_remaining / 100 : 0,
      currency: invoice?.currency,
      paymentMethod: invoice?.payment_intent?.payment_method,
    };

    return {
      id: invoice?.id || `${index}`,
      invoiceNumber: invoice?.number,
      createdAt: invoice?.created,
      formattedDate: moment.unix(invoice?.created).utc().format('LL'),
      amount: invoice?.total > 0 ? invoice?.total / 100 : 0,
      status: invoice?.id ? invoice.status : 'Upcoming',
      items: invoice?.lines?.data,
      invoiceFile: invoice?.invoice_pdf,
      invoicePortal: invoice?.hosted_invoice_url,
      periodStart: moment.unix(invoice?.period_start).utc().format('LL'),
      periodEnd: moment.unix(invoice?.period_end).utc().format('LL'),
      customerName: invoice?.customer_name,
      customerEmail: invoice?.customer_email,
      customerPhone: invoice?.customer_phone,
      customerAddress: `${line1} ${line2}, ${city}, ${state}, ${postal_code} ${country}`,
      paymentMethod: invoice?.payment_intent?.payment_method,
      openBreakdown,
      breakdown: invoiceBreakdown,
      paymentIntent: invoice?.payment_intent,
    };
  }, []);

  return (
    <Box>
      <DataGridPremium
        className={styles.table}
        density="compact"
        rows={loading ? [] : rows}
        columns={invoicesColumns(isMdAndDown)}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
      />
    </Box>
  );
};

export default BillingInvoiceListTable;
