import { SET_END_RANGE, SET_START_RANGE } from "../ActionNames";

export const setStartRange = (value: any) => {
  return {
    type: SET_START_RANGE,
    payload: value,
  };
};

export const setEndRange = (value: any) => {
  return {
    type: SET_END_RANGE,
    payload: value,
  };
};
