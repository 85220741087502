import { Box } from '@mui/system';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { BudgetReport } from '../../../types';
import {
  budgetReportColumns,
  budgetReportGroupingColumn,
} from './BudgetReportTableColumn';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridGroupNode,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { CustomLoadingOverlay } from '../../Loading';
import { arrayUniqueFilter } from '../../../utils/arrayFormatter';

interface BudgetReportProps {
  budgetReports: BudgetReport[];
  loading: boolean;
  selectedRows: BudgetReport[];
  setSelectedRows: Dispatch<SetStateAction<BudgetReport[]>>;
}

const BudgetReportTable: React.FC<BudgetReportProps> = ({
  budgetReports,
  loading,
  selectedRows,
  setSelectedRows,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) =>
    row.group;

  const cellBgFormatter = (params: any) => {
    const { value } = params;
    const { status } = params.row;

    if (params.field === 'percentage') {
      if (value === 0) return '';

      if (value < 80) return 'primary';

      if (value >= 80 && value < 95) return 'yellow';

      if (value >= 95 && value < 101) return 'green';

      if (value >= 101) return 'red';
    }

    if (
      ['campaign', 'adset'].includes(params.row.level) &&
      status &&
      params.field === '__tree_data_group__'
    ) {
      if (status === 'IN_PROCESS') return 'primary';

      if (status !== 'ACTIVE') return 'red';
    }

    return '';
  };

  const isSelectable = (row: any) => {
    return (
      row.level === 'adset' &&
      row.status === 'ACTIVE' &&
      row.daily > 0 &&
      isBudgetSyncable(row.budgetStatus, row)
    );
  };

  const isBudgetSyncable = (budgetStatus: string, row: any) => {
    if (budgetStatus === 'REACHING_BUDGET') {
      return row.daily !== row.currentDaily;
    }

    if (budgetStatus === 'EXCEED_BUDGET') {
      return row.daily < row.currentDaily;
    }

    return row.daily > row.currentDaily;
  };

  return (
    <Box
      sx={{
        '& .green': {
          backgroundColor: 'green',
          fontWeight: 'bold',
        },
        '& .yellow': {
          backgroundColor: 'yellow',
          fontWeight: 'bold',
        },
        '& .red': {
          backgroundColor: 'red',
          fontWeight: 'bold',
        },
        '& .primary': {
          backgroundColor: '#1976d2',
          fontWeight: 'bold',
        },
      }}
    >
      <DataGridPremium
        treeData
        groupingColDef={budgetReportGroupingColumn(isMdAndDown)}
        getTreeDataPath={getTreeDataPath}
        rows={budgetReports}
        columns={budgetReportColumns(isMdAndDown)}
        autoHeight
        density="compact"
        hideFooterSelectedRowCount
        loading={loading}
        className={styles.table}
        getCellClassName={(params: any) => {
          return cellBgFormatter(params);
        }}
        checkboxSelection
        isRowSelectable={(params: GridRowParams) => isSelectable(params.row)}
        onRowClick={(params, event: any) => {
          if (event.target?.className?.animVal) {
            let groups: string[] = [...expandedKeys];

            const existingKey = groups.find(
              (key: any) =>
                key === params.row.group[params.row.group.length - 1],
            );

            if (existingKey) {
              groups = groups.filter((key: string) => key !== existingKey);
            } else {
              budgetReports.forEach((report: BudgetReport) => {
                if (report.group.includes(params.row.group[0])) {
                  groups = [...groups, ...report.group];
                }
              });
            }

            setExpandedKeys(arrayUniqueFilter(groups));
          }
        }}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(params: GridRowSelectionModel) => {
          let data: BudgetReport[] = [];
          budgetReports.forEach((report: BudgetReport) => {
            if (params.includes(report.id)) {
              data = [...data, report];
            }
          });

          setSelectedRows(data);
        }}
        rowSelectionModel={selectedRows.map((row: BudgetReport) => row.id)}
        isGroupExpandedByDefault={(row: GridGroupNode) =>
          expandedKeys.includes(row.groupingKey as string)
        }
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        slotProps={{
          baseIconButton: {
            onClick: () => {
              return false;
            },
          },
        }}
        onColumnHeaderClick={async (params, event: any) => {
          if (params.field === '__check__') {
            let expandedKeys: string[] = [];
            let data: BudgetReport[] = [];

            if (event.target.checked) {
              budgetReports.forEach((report: BudgetReport) => {
                if (report.level === 'adset') {
                  expandedKeys = [...expandedKeys, ...report.group];
                }
              });

              budgetReports.forEach((report: BudgetReport) => {
                if (isSelectable(report)) {
                  data = [...data, report];
                }
              });
            }

            await setExpandedKeys(expandedKeys);
            setSelectedRows(data);
          }
        }}
      />
    </Box>
  );
};

export default BudgetReportTable;
