import axios from "axios";
import { apiBaseUrl } from "./api";

export const fetchLeadSource = async (accessToken: string, webKey: string) => {
  const response = await axios.get(
    `${apiBaseUrl}/client-tether/lead-sources?accessToken=${accessToken}&webKey=${webKey}`
  );

  return response?.data;
};
