import React, { FormEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { ChangeEventType } from '../../types';
import { resetPassword } from '../../services/user';
import { PrimaryButton } from '../../components/Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../../components/PageHeader';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleOnShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const handleOnShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await resetPassword({ newPassword, confirmPassword, token });
      if (data) {
        navigate('/login');
        dispatch(
          toggleAlert({
            toggle: true,
            message: data.message,
          }),
        );
        setLoading(false);
      }
    } catch (error: any) {
      const err = error.response.data;
      setErrors({
        ...errors,
        newPassword: err?.newPassword,
        confirmPassword: err?.confirmPassword,
      });
      dispatch(
        toggleAlert({
          toggle: true,
          message: err?.message,
          type: 'error',
        }),
      );
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%', p: '20px', marginTop: '20px' }}
      className={styles.form}
    >
      <PageHeader title="Reset Password" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            size="small"
            type={showNewPassword ? 'text' : 'password'}
            id="newPassword"
            name="newPassword"
            label="New password"
            onChange={(e: ChangeEventType) => setNewPassword(e.target.value)}
            value={newPassword}
            InputLabelProps={{ shrink: true }}
            error={errors.newPassword ? true : false}
            helperText={errors.newPassword}
            sx={{
              '& .MuiInputBase-root': {
                '&:has(> input:-webkit-autofill)': {
                  backgroundColor: 'rgb(232, 240, 254)',
                },
              },
            }}
            InputProps={{
              sx: { borderRadius: '49px' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleOnShowNewPassword} edge="end">
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            size="small"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm password"
            onChange={(e: ChangeEventType) =>
              setConfirmPassword(e.target.value)
            }
            value={confirmPassword}
            InputLabelProps={{ shrink: true }}
            error={errors.confirmPassword ? true : false}
            helperText={errors.confirmPassword}
            sx={{
              '& .MuiInputBase-root': {
                '&:has(> input:-webkit-autofill)': {
                  backgroundColor: 'rgb(232, 240, 254)',
                },
              },
            }}
            InputProps={{
              sx: { borderRadius: '49px' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleOnShowConfirmPassword} edge="end">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <div className={`${styles.action} ${styles['-public']}`}>
        <PrimaryButton title="Save" loading={loading} size="large" rounded />
      </div>
    </Box>
  );
};

export default ResetPassword;
