import { Box, Button, Modal } from '@mui/material';
import * as React from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import styles from '../../assets/styles/components/Modal/Modal.module.scss';

const StyledBox = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: 2,
  [theme.breakpoints.down('md')]: {
    width: '95%',
    maxHeight: '500px',
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    maxHeight: '600px',
  },
}));

/**
 * Navigation Blocker for Dirty Forms
 * @author Angelo David <angelod@codev.com>
 * @since 1.13.1
 * @reference https://ui.dev/react-router-preventing-transitions
 */
export const useBlocker = (blocker: any, when = true) => {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!when) return;

    const unblock = (navigator as any).block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const usePrompt = (
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
  setAutoUnblockingTx: React.Dispatch<React.SetStateAction<any>>,
  when = true,
) => {
  const blocker = React.useCallback((tx) => {
    setAutoUnblockingTx(tx);
    setOpenModal(true);
  }, []);

  useBlocker(blocker, when);
};

interface ConfirmNavigationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (tx: any) => void;
}

const ConfirmNavigationModal: React.FC<ConfirmNavigationModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledBox>
        <div className={styles.modal}>
          <h3 className={styles.header}>{'Unsaved Changes'}</h3>

          <p className={styles.subtitle}>
            Are you sure you want to proceed without saving your changes?
          </p>

          <p className={styles.subtitle}>
            Click "Continue" to discard unsaved changes, or "Cancel" to go back
            and save your work.
          </p>

          <div>
            <Button
              onClick={onConfirm}
              sx={{ marginRight: '10px' }}
              size="small"
              variant="contained"
              color="error"
            >
              Continue
            </Button>

            <Button
              onClick={onClose}
              size="small"
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </div>
      </StyledBox>
    </Modal>
  );
};

export default ConfirmNavigationModal;
