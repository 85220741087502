import moment from 'moment';
import { SET_END_RANGE, SET_START_RANGE } from '../ActionNames';

const startRange = (
  state: any = { date: moment().subtract(30, 'days') },
  action: any,
) => {
  switch (action.type) {
    case SET_START_RANGE:
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
};

const endRange = (state: any = { date: moment() }, action: any) => {
  switch (action.type) {
    case SET_END_RANGE:
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
};

export { startRange, endRange };
