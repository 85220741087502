import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Brand,
  Country,
  FBLeadGenForm,
  FbAssetFeedSpec,
  LeadFormData,
  LeadNotification,
} from '../../../types';
import {
  AdsTemplate,
  DiyError,
  DiySettings,
  TemplateError,
} from '../../../types/IDiy';
import Spinner from '../../Spinner';
import {
  createLeadForms,
  getBrandLeadForms,
  getFormLeadNotification,
  getSingleLeadForms,
} from '../../../services/lead';
import PopupModal from '../../Modal';
import { useForm } from 'react-hook-form';
import {
  ThankYouPageButtonType,
  buildNotificationBodyFields,
} from '../../../utils/helpers/LeadHelpers';
import { phoneNoSpaceFormat } from '../../../utils/phoneNumberFormatter';
import { topfireMediaId } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import { adsProvider } from '../../../utils/constants/facebookAds';
import DuplicateLeadForm from './DuplicateLeadForm';

interface LeadGenProps {
  brand: Brand;
  accountId: string;
  assetFeedSpec: FbAssetFeedSpec;
  setAssetFeedSpec?: Dispatch<SetStateAction<FbAssetFeedSpec>>;
  selectedTemplate?: AdsTemplate;
  setSelectedTemplate?: Dispatch<SetStateAction<AdsTemplate>>;
  isDiy?: boolean;
  error?: TemplateError;
  diyError?: DiyError;
  isEditAds?: boolean;
  diySettings: DiySettings;
}

const LeadGen: React.FC<LeadGenProps> = ({
  brand,
  accountId,
  assetFeedSpec,
  setAssetFeedSpec,
  isDiy = false,
  selectedTemplate,
  setSelectedTemplate,
  error,
  diyError,
  isEditAds = false,
  diySettings,
}) => {
  const campaignProvider =
    useSelector((state: any) => state?.campaignProvider?.campaignProvider) ||
    adsProvider.FACEBOOK;
  const dispatch = useDispatch();
  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
  } = useForm({ mode: 'onSubmit' });
  const [leadGenForms, setLeadGenForms] = useState<LeadFormData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [createFormLoading, setCreateFormLoading] = useState<boolean>(false);
  const [openLeadForm, setOpenLeadForm] = useState<boolean>(false);
  const [leadForm, setLeadForm] = useState<LeadFormData>(null);
  const [leadFormValues, setLeadFormValues] = useState<FBLeadGenForm>(null);
  const [leadNotificationFormValues, setLeadNotificationFormValues] =
    useState<LeadNotification>(null);
  const [selectedCountry, setSelectedCountry] = useState<Country>({
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  });
  const isTFM = brand?.agency?._id === topfireMediaId;

  useEffect(() => {
    if (brand && accountId) {
      getLeadGenForms();
    }
  }, [brand, accountId]);

  const handleOpenCreateLeadForm = async (form: LeadFormData) => {
    setLeadForm(form);

    let temp: FBLeadGenForm = {
      ...leadFormValues,
      name: `${form?.name}-${brand?.brand}`,
      allow_organic_lead: form?.fields?.allow_organic_lead,
      block_display_for_non_targeted_viewer:
        form?.fields?.block_display_for_non_targeted_viewer,
      privacy_policy: form?.fields?.privacy_policy,
      questions: form?.fields?.questions,
      thank_you_page: {
        business_phone_number: phoneNoSpaceFormat(brand?.phoneNumber) || '',
        button_type:
          form?.fields?.thank_you_page?.button_type ||
          form?.cta === ThankYouPageButtonType.CALL_BUSINESS
            ? form?.cta
            : ThankYouPageButtonType.VIEW_WEBSITE,
        website_url:
          form?.cta === ThankYouPageButtonType.SCHEDULE_CALL
            ? diySettings?.defaultCalendarUrl || ''
            : diySettings?.defaultUrl || '',
        title: form?.fields?.thank_you_page?.title,
        body: form?.fields?.thank_you_page?.body,
        button_text: form?.fields?.thank_you_page?.button_text,
      },
      question_page_custom_headline:
        form?.fields?.question_page_custom_headline,
      locale: form?.fields?.locale || 'EN_US',
      is_optimized_for_quality: form?.fields?.is_optimized_for_quality,
      enableSendLead: form?.enableSendLead,
      enableZapierWebhook: form?.enableZapierWebhook,
      cta: form?.cta,
      description: form?.description,
      brandId: brand?._id,
      baseTemplate: form?._id,
    };

    setLeadFormValues(temp);

    let tempNotification: LeadNotification = { ...leadNotificationFormValues };

    if (form?.enableSendLead) {
      const data = await getLeadNotification(form);

      const bodyFields = buildNotificationBodyFields(
        form?.fields?.questions,
        campaignProvider,
      );

      if (data) {
        tempNotification = {
          ...tempNotification,
          bodyFields: data?.bodyFields || bodyFields,
          enableZapierWebhook: data?.enableZapierWebhook || false,
          zapierWebhookUrl: data?.zapierWebhookUrl,
        };
      } else {
        tempNotification = {
          ...tempNotification,
          bodyFields,
          enableZapierWebhook: false,
          zapierWebhookUrl: null,
        };
      }

      tempNotification = {
        ...tempNotification,
        recipients: [],
        cc: [],
        bcc: [],
        subject: `${isTFM ? 'TopFire Media' : 'Sales Chatz'} Facebook Lead: ${
          brand?.__type === 'location'
            ? `${brand?.franchisor?.brand}-${brand?.brand}`
            : brand?.brand
        }`,
      };
    }

    setLeadNotificationFormValues(tempNotification);

    if (
      (form?.cta === ThankYouPageButtonType.CALL_BUSINESS &&
        brand?.phoneNumber) ||
      (ThankYouPageButtonType.VIEW_WEBSITE === form?.cta &&
        diySettings?.defaultUrl) ||
      (ThankYouPageButtonType.SCHEDULE_CALL === form?.cta &&
        diySettings?.defaultCalendarUrl) ||
      (form?.enableSendLead && diySettings?.defaultEmailRecipients?.length > 0)
    ) {
      handleCreateForm(temp, tempNotification);
    } else {
      setOpenLeadForm(true);
    }
  };

  /**
   * TODO: Refactor this create form logic to merge with handleOnSubmit
   * @param form
   * @param notification
   */
  const handleCreateForm = async (
    form: FBLeadGenForm,
    notification: LeadNotification,
  ) => {
    try {
      setCreateFormLoading(true);
      let temp: FBLeadGenForm = { ...form };

      if (
        [
          ThankYouPageButtonType.VIEW_WEBSITE,
          ThankYouPageButtonType.SCHEDULE_CALL,
        ].includes(temp?.cta)
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            country_code: null,
            business_phone_number: null,
            button_type: ThankYouPageButtonType.VIEW_WEBSITE,
          },
        };
      }

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.CALL_BUSINESS
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: null,
            business_phone_number: `+${
              selectedCountry?.phone
            }${phoneNoSpaceFormat(
              temp?.thank_you_page?.business_phone_number || '',
            )}`,
            country_code: selectedCountry?.code,
          },
        };
      }

      const response = await createLeadForms(temp, notification);

      let tempTemplate: AdsTemplate = {
        ...selectedTemplate,
        isTemplateChanged: true,
      };

      tempTemplate.creativeTemplate.asset_feed_spec.call_to_actions[0] = {
        ...tempTemplate.creativeTemplate.asset_feed_spec.call_to_actions[0],
        value: {
          lead_gen_form_id: response.data.id,
        },
      };

      setSelectedTemplate(tempTemplate);
      setLeadGenForms([...leadGenForms, response.data]);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Lead form created successfully',
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);

      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setCreateFormLoading(false);
    }
  };

  const handleCloseCreateLeadForm = () => {
    setLeadForm(null);
    setOpenLeadForm(false);
  };

  const handleSelectLeadForm = (value: LeadFormData) => {
    if (isDiy) {
      let temp: AdsTemplate = { ...selectedTemplate, isTemplateChanged: true };
      temp.creativeTemplate.asset_feed_spec.call_to_actions[0] = {
        ...temp.creativeTemplate.asset_feed_spec.call_to_actions[0],
        value: {
          lead_gen_form_id: value.id,
        },
      };

      setSelectedTemplate(temp);
    } else {
      let temp: FbAssetFeedSpec = { ...assetFeedSpec };
      temp.call_to_actions[0] = {
        ...(temp.call_to_actions[0] || {
          type: '',
          adlabels: [{ name: 'cta_1' }],
        }),
        value: { lead_gen_form_id: value.id },
      };

      setAssetFeedSpec(temp);
    }
  };

  const getLeadGenForms = async () => {
    setLoading(true);
    try {
      const response = await getBrandLeadForms(brand?._id);

      let form: LeadFormData;

      form = response.data?.find(
        (form: LeadFormData) =>
          form?.id ===
          selectedTemplate?.creativeTemplate?.asset_feed_spec
            ?.call_to_actions[0]?.value?.lead_gen_form_id,
      );

      if (isDiy && !form && brand?.__type === 'location') {
        const form: LeadFormData = response.data?.find(
          (form: LeadFormData) =>
            form.baseTemplate?.id ===
            selectedTemplate?.creativeTemplate?.asset_feed_spec
              ?.call_to_actions[0]?.value?.lead_gen_form_id,
        );

        if (!form) {
          const data = await getLeadGenForm(
            selectedTemplate?.creativeTemplate?.asset_feed_spec
              ?.call_to_actions[0]?.value?.lead_gen_form_id,
            brand?.__type === 'location' ? brand?.franchisor?._id : brand?._id,
          );

          handleOpenCreateLeadForm(data);
        } else {
          let temp: AdsTemplate = {
            ...selectedTemplate,
            isTemplateChanged: true,
          };
          temp.creativeTemplate.asset_feed_spec.call_to_actions[0] = {
            ...temp.creativeTemplate.asset_feed_spec.call_to_actions[0],
            value: {
              lead_gen_form_id: form.id,
            },
          };

          setSelectedTemplate(temp);
        }
      }

      setLeadGenForms(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getLeadGenForm = async (formId: string, brandId: string) => {
    try {
      const response = await getSingleLeadForms(null, formId, brandId);

      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  };

  const getLeadNotification = async (form: LeadFormData) => {
    try {
      const response = await getFormLeadNotification(
        form?._id,
        brand?.__type === 'location' ? brand?.franchisor?._id : brand?._id,
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleOnSubmit = async () => {
    setCreateFormLoading(true);
    try {
      let temp: FBLeadGenForm = { ...leadFormValues };

      if (
        [
          ThankYouPageButtonType.VIEW_WEBSITE,
          ThankYouPageButtonType.SCHEDULE_CALL,
        ].includes(temp?.cta)
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            country_code: null,
            business_phone_number: null,
            button_type: ThankYouPageButtonType.VIEW_WEBSITE,
          },
        };
      }

      if (
        temp?.thank_you_page?.button_type ===
        ThankYouPageButtonType.CALL_BUSINESS
      ) {
        temp = {
          ...temp,
          thank_you_page: {
            ...temp?.thank_you_page,
            website_url: null,
            business_phone_number: `+${
              selectedCountry?.phone
            }${phoneNoSpaceFormat(
              temp?.thank_you_page?.business_phone_number || '',
            )}`,
            country_code: selectedCountry?.code,
          },
        };
      }

      const response = await createLeadForms(temp, leadNotificationFormValues);

      let tempTemplate: AdsTemplate = {
        ...selectedTemplate,
        isTemplateChanged: true,
      };

      tempTemplate.creativeTemplate.asset_feed_spec.call_to_actions[0] = {
        ...tempTemplate.creativeTemplate.asset_feed_spec.call_to_actions[0],
        value: {
          lead_gen_form_id: response.data.id,
        },
      };

      setSelectedTemplate(tempTemplate);
      setLeadGenForms([...leadGenForms, response.data]);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Lead form created successfully',
        }),
      );

      handleCloseCreateLeadForm();
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);

      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setCreateFormLoading(false);
    }
  };

  const handleOnSelectCountry = (e: any, value: any) => {
    setLeadFormValues({
      ...leadFormValues,
      thank_you_page: {
        ...leadFormValues.thank_you_page,
        country_code: `+${value.phone}`,
      },
    });

    setSelectedCountry(value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold">
          Instant form
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption">
          Make connections with people by letting them send contact information
          and other details to you through a form.
        </Typography>
      </Grid>

      {/**
        * TODO: Re-enable if we allowing users to create form 
      <Grid item xs={12}>
        <PrimaryButton
          title="Create From"
          type="button"
          handleOnClick={handleOpenLeadGenForm}
        />
      </Grid>
      */}

      {loading ? (
        <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
          <Spinner />
        </Grid>
      ) : null}

      {!loading && !createFormLoading ? (
        <Grid item xs={12}>
          <Autocomplete
            id="leadGenForm"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option.name} (${option.id})`}
                </li>
              );
            }}
            options={leadGenForms.sort(
              (a, b) => -b.name?.localeCompare(a.name),
            )}
            value={
              (isDiy
                ? selectedTemplate?.creativeTemplate?.asset_feed_spec
                : assetFeedSpec
              )?.call_to_actions?.length > 0
                ? leadGenForms.find(
                    (form: LeadFormData) =>
                      form.id ===
                      (isDiy
                        ? selectedTemplate?.creativeTemplate?.asset_feed_spec
                        : assetFeedSpec
                      )?.call_to_actions[0]?.value?.lead_gen_form_id,
                  )
                : null
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Lead Gen Form"
                fullWidth
                size="small"
                placeholder="Select Form Lead"
                required
                InputLabelProps={{ shrink: true }}
                error={
                  error?.leadFormError || diyError?.leadFormError ? true : false
                }
                helperText={
                  error?.leadFormError
                    ? error?.leadFormError
                    : diyError?.leadFormError
                    ? diyError?.leadFormError
                    : ''
                }
              />
            )}
            disableClearable
            onChange={(e: any, value: any) => handleSelectLeadForm(value)}
          />
        </Grid>
      ) : null}

      <PopupModal open={openLeadForm} handleClose={handleCloseCreateLeadForm}>
        <DuplicateLeadForm
          brand={brand}
          diySettings={diySettings}
          notification={leadNotificationFormValues}
          setNotification={setLeadNotificationFormValues}
          leadForm={leadForm}
          leadFormValues={leadFormValues}
          setLeadFormValues={setLeadFormValues}
          country={selectedCountry}
          onSelectCountry={handleOnSelectCountry}
          onSubmit={handleSubmit(handleOnSubmit)}
          loading={createFormLoading}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
        />
      </PopupModal>
    </Grid>
  );
};

export default LeadGen;
