import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { PrimaryButton } from '../../Buttons';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../redux/actions';

interface StripePaymentUIFormProps {
  clientSecret: string;
  onPay: (stripe: any, elements: any) => void;
  loading: boolean;
  transactionType: string;
}

const StripePaymentUIForm: React.FC<StripePaymentUIFormProps> = ({
  clientSecret,
  onPay,
  loading,
  transactionType,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const paymentElementOptions = {
    layout: 'tabs',
  };

  useEffect(() => {
    if (stripe) {
      if (!stripe) {
        return;
      }

      if (!clientSecret) {
        return;
      }

      if (transactionType === 'payment') {
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          switch (paymentIntent?.status) {
            case 'succeeded':
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: 'Payment succeeded!',
                }),
              );
              break;
            case 'processing':
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: 'Your payment is processing.',
                }),
              );
              break;
            case 'requires_payment_method':
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: 'Your payment was not successful, please try again.',
                  type: 'error',
                }),
              );
              break;
            default:
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: 'Something went wrong.',
                  type: 'error',
                }),
              );
              break;
          }
        });
      } else if (transactionType === 'setup') {
        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
          switch (setupIntent.status) {
            case 'succeeded':
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: 'Success! Your payment method has been saved',
                }),
              );

              break;
            case 'processing':
              dispatch(
                toggleAlert({
                  toggle: true,
                  message: `Processing payment details. We'll update you when processing is complete`,
                }),
              );

              break;
          }
        });
      }
    }
  }, [stripe]);

  return (
    <div>
      <Box>
        <Box component="div" sx={{ marginBottom: '20px' }}>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions as any}
          />
        </Box>

        <PrimaryButton
          disabled={loading || !stripe || !elements}
          loading={loading}
          title={transactionType === 'setup' ? 'Add Payment Method' : 'Pay Now'}
          type="button"
          handleOnClick={() => {
            onPay(stripe, elements);
          }}
        />
      </Box>
    </div>
  );
};

export default StripePaymentUIForm;
