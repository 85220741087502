import { Autocomplete, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { Dispatch, SetStateAction } from 'react';
import { XsOnly } from '../../utils/breakpoints';
import { PrimaryButton } from '../Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import FormHeader from './FormHeader';
import { humanizeString } from '../../utils/stringModifier';

interface ProviderEmailFormProps {
  emails: string[];
  setLoginEmails: Dispatch<SetStateAction<string[]>>;
  loading: boolean;
  handleSubmit: (provider: string) => void;
  provider: string;
}

const ProviderEmailForm: React.FC<ProviderEmailFormProps> = ({
  emails,
  setLoginEmails,
  loading,
  handleSubmit,
  provider,
}) => {
  return (
    <Box
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
      sx={{
        flexDirection: 'column',
        marginTop: '20px',
      }}
    >
      <FormHeader title={`${humanizeString(provider)} Login Emails`} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="loginEmails"
            multiple
            disableClearable
            freeSolo
            options={[]}
            value={emails}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={`${humanizeString(provider)} Login Emails`}
                placeholder={`Enter allowed ${humanizeString(provider)} emails`}
              />
            )}
            onChange={(e: any, value: any[]) => {
              setLoginEmails(value);
            }}
          />
        </Grid>
      </Grid>

      <div className={`${styles.action} ${styles['-unsticky']}`}>
        <PrimaryButton
          title="Save"
          loading={loading}
          type="button"
          handleOnClick={() => {
            handleSubmit(provider);
          }}
          rounded
        />
      </div>
    </Box>
  );
};

export default ProviderEmailForm;
