import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import { darken, lighten, styled } from '@mui/system';
import { constantStringToHumanized } from '../../../utils/stringModifier';
import { TargetSearchKey } from '../../../types';
import { PrimaryButton } from '../../Buttons';
import { PopoverModal } from '../../Modal';
import TargetBrowseForm from './TargetBrowseForm';
import Spinner from '../../Spinner';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

interface TargetSearchFormProps {
  index?: number;
  options: TargetSearchKey[];
  value: TargetSearchKey;
  onChange: (
    value: TargetSearchKey,
    type: string,
    flexibleIndex?: number,
  ) => void;
  flexibleIndex?: number;
  type?: string;
  id: string;
  onInput: (value: string, type: string, index?: number) => void;
  loading: boolean;
  searchQuery: string;
  onSuggestion?: () => void;
  browsedData: any;
  disabledOptions: (
    option: any,
    type: string,
    browse?: boolean,
    flexibleIndex?: number,
  ) => boolean;
  browseLoading: boolean;
}

const TargetSearchForm: React.FC<TargetSearchFormProps> = ({
  index = 0,
  options,
  value,
  onChange,
  flexibleIndex,
  type,
  id,
  onInput,
  loading,
  searchQuery,
  onSuggestion,
  browsedData,
  disabledOptions,
  browseLoading,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const handleInputValue = (value: string) => {
    if (value.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Autocomplete
        open={open}
        blurOnSelect
        onClose={() => setOpen(false)}
        id={id}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {`${constantStringToHumanized(
                option.name,
              )} (${constantStringToHumanized(option.type)})`}
            </li>
          );
        }}
        options={options || []}
        groupBy={(option) => option.path.join(' > ')}
        value={value}
        getOptionLabel={(option) =>
          `${constantStringToHumanized(
            option.name,
          )} (${constantStringToHumanized(option.type)})`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Search Targeting"
            placeholder="Search Targeting"
            size="small"
            value={searchQuery}
            onChange={(e: any) => {
              onInput(e.target.value, type, flexibleIndex);
              handleInputValue(e.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress size={20} sx={{ marginRight: '10px' }} />
                  ) : null}

                  {browseLoading ? (
                    <Spinner />
                  ) : (
                    <Button
                      aria-describedby={popoverId}
                      variant="text"
                      type="button"
                      onClick={handleOpenPopover}
                    >
                      Browse
                    </Button>
                  )}

                  {type === 'targets' ? (
                    <PrimaryButton
                      variant="text"
                      title="Suggestion"
                      marginRight10
                      type="button"
                      handleOnClick={() => {
                        onSuggestion();
                        if (!loading) {
                          setOpen(true);
                        }
                      }}
                    />
                  ) : null}
                </>
              ),
            }}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>

            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        disableClearable
        onChange={(e: any, value: any) => {
          onChange(value, type, flexibleIndex);
          setOpen(false);
        }}
        sx={{ marginBottom: '10px' }}
        getOptionDisabled={(option) =>
          disabledOptions(option, type, false, flexibleIndex)
        }
      />

      <PopoverModal
        id={popoverId}
        open={openPopover}
        handleClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorVertical="bottom"
        anchorHorizontal="right"
        transformHorizontal="right"
        transformVertical="top"
      >
        <Box component="div" sx={{ width: '600px' }}>
          <TargetBrowseForm
            data={browsedData}
            index={index}
            type={type}
            onChange={onChange}
            flexibleIndex={flexibleIndex}
            disableOptions={disabledOptions}
          />
        </Box>
      </PopoverModal>
    </>
  );
};

export default TargetSearchForm;
