import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AverageResponse,
  Brand,
  BrandAnalytics as Analytics,
  GoogleAdsMetrics,
  Report,
} from '../../types';
import {
  getBrandAnalytics,
  getBrandCampaignAnalytics,
  getGoogleCampaignMetrics,
} from '../../services/report';
import {
  buildGoogleTableRows,
  buildLeadTableRows,
} from '../../utils/helpers/BrandAnalyticsHelper';
import styles from '../../assets/styles/pages/Reports.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setEndRange, setStartRange, toggleAlert } from '../../redux/actions';
import { adsProvider } from '../../utils/constants/facebookAds';
import PageHeader from '../../components/PageHeader';
import CampaignOverview from '../../components/Tables/ReportList/CampaignOverview';
import { AuthContext } from '../../context';
import { IS_DIY_ADZ } from '../../utils';
import { buildRefreshToken } from '../../utils/helpers/DefaultTokenBuilder';
import CircularLoading from '../../components/CircularLoading';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FacebookCampaignAnalytics from '../../components/Reporting/FacebookCampaignAnalytics';
import DateRangePicker from '../../components/DateAndTimePickers/DateRangePicker';
import GoogleCampaignOverview from '../../components/Tables/ReportList/GoogleCampaignOverview';
import GoogleCampaignMetrics from '../../components/Reporting/GoogleCampaignMetrics';
import { NoData } from '../NoContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BusinessType } from '../../utils/helpers/BrandHelpers';
import { DEFAULT_COLOR_THEME } from '../../utils/Styling';
import { DrawerMenu } from '../../components/Menu';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import ChatzBudgets from '../Budgets/ChatzBudgetReport';

const Reports: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider: string =
    useSelector((state: any) => state?.campaignProvider?.campaignProvider) ||
    adsProvider.FACEBOOK;
  const startRange = useSelector((state: any) => state.startRange.date);
  const endRange = useSelector((state: any) => state.endRange.date);
  const dispatch = useDispatch();
  const { state } = useContext(AuthContext);
  const refreshToken = state.authUser?.refreshToken;
  const theme = useTheme();
  const isMdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false);
  const [leadInitiativeRate, setLeadInitiativeRate] = useState<number>(0);
  const [completedEngagementRate, setCompletedEngagementRate] =
    useState<number>(0);
  const [averageResponseTime, setAverageResponseTime] =
    useState<AverageResponse>(null);
  const [analyticsData, setAnalyticsData] = useState<Analytics>(null);
  const [leadCampaigns, setLeadCampaigns] = useState<Report>(null);
  const [salesCampaigns, setSalesCampaigns] = useState<Report>(null);
  const [messengerCampaigns, setMessengerCampaigns] = useState<Report>(null);
  const [trafficCampaigns, setTrafficCampaigns] = useState<Report>(null);
  const [previousBrand, setPreviousBrand] = useState<string>(null);
  const [googleCampaigns, setGoogleCampaigns] =
    useState<GoogleAdsMetrics>(null);
  const [selectedAd, setSelectedAd] = useState<string>(null);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [defaultRefreshToken, setDefaultRefreshToken] = useState<string>('');
  const [tabValue, setTabValue] = useState<string>('1');
  const [tabs, setTabs] = useState<string[]>([]);
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;
  const isGoogle = campaignProvider === adsProvider.GOOGLE;
  const [selectedBrand, setSelectedBrand] = useState<Brand>(null);
  const [expanded, setExpanded] = useState<string>('panel1');

  const isNewBrand = selectedBrand?._id !== previousBrand;
  const isFrandev: boolean =
    (location || brand)?.businessType === BusinessType.FRANDEV;

  useEffect(() => {
    handleBuildProviderTokens();
  }, []);

  useEffect(() => {
    if (location || brand) {
      const delayDebounceFn = setTimeout(() => {
        setSelectedBrand(location || brand);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [brand, location]);

  useEffect(() => {
    if (
      selectedBrand &&
      campaignProvider &&
      startRange &&
      endRange &&
      defaultRefreshToken
    ) {
      resetData();
      fetchBrandAnalytics().then(() => {
        setPreviousBrand(selectedBrand?._id);
      });
    }
  }, [
    startRange,
    endRange,
    selectedBrand,
    campaignProvider,
    defaultRefreshToken,
  ]);

  useEffect(() => {
    let temp: string[] = [];
    if (leadCampaigns?.campaigns?.length > 0) {
      temp = [...temp, 'Lead Campaigns'];
    }

    if (messengerCampaigns?.campaigns?.length > 0) {
      temp = [...temp, 'Messenger Campaigns'];
    }

    if (trafficCampaigns?.campaigns?.length > 0) {
      temp = [...temp, 'Traffic Campaigns'];
    }

    if (salesCampaigns?.campaigns?.length > 0) {
      temp = [...temp, 'Sales Campaigns'];
    }

    setTabs(temp);
  }, [
    leadCampaigns,
    messengerCampaigns,
    trafficCampaigns,
    salesCampaigns,
    startRange,
    endRange,
  ]);

  const handleBuildProviderTokens = () => {
    buildRefreshToken(refreshToken, setDefaultRefreshToken);
  };

  const resetData = () => {
    setAnalyticsData(null);
    setLeadCampaigns(null);
    setMessengerCampaigns(null);
    setTrafficCampaigns(null);
    setGoogleCampaigns(null);
    setSalesCampaigns(null);
  };

  const fetchBrandAnalytics = async () => {
    try {
      setAnalyticsLoading(true);
      const result = await getBrandAnalytics(
        selectedBrand?._id,
        startRange,
        endRange,
      );

      const parsedResponse: Analytics = {
        leadCount: result?.result?.leadCount[0].total,
        scheduledCallCount: result.result.scheduledCallCount[0].total,
        partialEngagementCount: result.result.partialEngagementCount[0].total,
        completedEngagementCount:
          result.result.completedEngagementCount[0].total,
        averageResponseTime: result.result.averageResponseTime[0].average,
      };
      computeRate(parsedResponse);

      setAnalyticsData(parsedResponse);

      if (isFacebook) {
        const data = await getBrandCampaignAnalytics(
          selectedBrand?._id,
          startRange,
          endRange,
        );

        if (data?.errors?.length > 0) {
          let msg: string = '';
          data.errors.forEach((error: any) => {
            msg += error.error.message;
          });
          dispatch(
            toggleAlert({
              toggle: true,
              message: msg,
              type: 'error',
            }),
          );
        } else {
          setLeadCampaigns(data.leadAds);
          setMessengerCampaigns(data.messengerAds);
          setTrafficCampaigns(data.trafficAds);
          setSalesCampaigns(data.salesAds);
        }
      }

      if (isGoogle) {
        const data = await getGoogleCampaignMetrics(
          selectedBrand?._id,
          defaultRefreshToken,
          startRange,
          endRange,
        );

        if (data?.errors?.length > 0) {
          let msg: string = '';
          data.errors.forEach((error: any) => {
            msg += error;
          });
          dispatch(
            toggleAlert({
              toggle: true,
              message: msg,
              type: 'error',
            }),
          );
        } else {
          setGoogleCampaigns(data);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setAnalyticsLoading(false);
    }
  };

  const handleOpenPreview = async (adId: string) => {
    setSelectedAd(adId);
    handleOpenAdPreviewModal();
  };

  const computeAverageResponseTime = (responseTime: any) => {
    if (responseTime / 86400 >= 1) {
      const average = responseTime / 86400;
      return { average, unit: average > 1 ? 'days' : 'day' };
    }
    if (responseTime / 3600 >= 1) {
      const average = responseTime / 3600;
      return { average, unit: average > 1 ? 'hours' : 'hour' };
    }
    if (responseTime / 60 >= 1) {
      const average = responseTime / 60;
      return { average, unit: average > 1 ? 'minutes' : 'minute' };
    }

    return {
      average: responseTime || 0.0,
      unit:
        responseTime > 1
          ? 'seconds'
          : `${responseTime === 0 ? '' : 'second'}` || '',
    };
  };

  const computeRate = (parsedResponse: Analytics) => {
    setLeadInitiativeRate(
      parsedResponse.leadCount === 0
        ? 0
        : Number(
            (
              (parsedResponse.partialEngagementCount /
                parsedResponse.leadCount) *
              100
            ).toFixed(2),
          ),
    );
    setCompletedEngagementRate(
      parsedResponse.partialEngagementCount === 0
        ? 0
        : Number(
            (
              (parsedResponse.completedEngagementCount /
                parsedResponse.partialEngagementCount) *
              100
            ).toFixed(2),
          ),
    );

    setAverageResponseTime(
      computeAverageResponseTime(parsedResponse.averageResponseTime),
    );
  };

  const handleChangeDateRange = (dates: Moment[] | []) => {
    dispatch(setStartRange(dates[0]));
    dispatch(setEndRange(dates[1]));
  };

  const handleOpenAdPreviewModal = () => {
    setOpenPreviewModal(true);
  };

  const handleCloseAdPreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const handleChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setTabValue(newValue);
  };

  const handleOnExpand = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {IS_DIY_ADZ &&
      selectedBrand?.__type === 'brand' &&
      selectedBrand?.allowLocations ? (
        <ChatzBudgets />
      ) : (
        <div className={styles.report}>
          <CircularLoading loading={analyticsLoading} />

          <div className={styles.base}>
            <div className={styles.header}>
              <PageHeader title={IS_DIY_ADZ ? 'Reportz' : 'Reports'} />

              <div className={styles.controls}>
                <DateRangePicker
                  toDate={endRange}
                  fromDate={startRange}
                  onChange={handleChangeDateRange}
                  maxDate={moment()}
                  separator="-"
                />
              </div>
            </div>

            {analyticsLoading &&
            isNewBrand &&
            isFacebook ? null : (leadCampaigns ||
                messengerCampaigns ||
                trafficCampaigns ||
                salesCampaigns) &&
              isFacebook ? (
              <>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  textAlign="center"
                  mb={2}
                >
                  Summary
                </Typography>

                <div className={styles.component}>
                  <CampaignOverview
                    rows={buildLeadTableRows(isFrandev && IS_DIY_ADZ)}
                    lead={analyticsData?.leadCount || 0}
                    isSummary
                    leadAds={leadCampaigns}
                    messengerAds={messengerCampaigns}
                    trafficAds={trafficCampaigns}
                    salesAds={salesCampaigns}
                    showQL={isFrandev && IS_DIY_ADZ}
                  />
                </div>
              </>
            ) : null}

            {analyticsLoading && isNewBrand ? null : googleCampaigns?.data
                .length > 0 && isGoogle ? (
              <>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  textAlign="center"
                  mb={2}
                >
                  Summary
                </Typography>

                <div className={styles.component}>
                  <GoogleCampaignOverview
                    rows={buildGoogleTableRows()}
                    data={googleCampaigns?.data || []}
                    isSummary
                  />
                </div>
              </>
            ) : null}

            {tabs.length === 1 && isFacebook ? (
              tabs.map((tab: string) => {
                const type =
                  tab === 'Lead Campaigns'
                    ? 'lead'
                    : tab === 'Messenger Campaigns'
                    ? 'messenger'
                    : tab === 'Traffic Campaigns'
                    ? 'traffic'
                    : 'sales';
                const report =
                  tab === 'Lead Campaigns'
                    ? leadCampaigns
                    : tab === 'Messenger Campaigns'
                    ? messengerCampaigns
                    : tab === 'Traffic Campaigns'
                    ? trafficCampaigns
                    : salesCampaigns;

                return (
                  <FacebookCampaignAnalytics
                    campaignReport={report}
                    fromDate={startRange}
                    toDate={endRange}
                    analyticsData={analyticsData}
                    brand={selectedBrand}
                    averageResponseTime={averageResponseTime}
                    leadInitiativeRate={leadInitiativeRate}
                    completedEngagementRate={completedEngagementRate}
                    messengerCampaigns={messengerCampaigns}
                    onOpenPreview={handleOpenPreview}
                    campaignType={type}
                  />
                );
              })
            ) : (leadCampaigns ||
                messengerCampaigns ||
                trafficCampaigns ||
                salesCampaigns) &&
              isFacebook ? (
              <>
                {!isMdAndDown ? (
                  <TabContext value={tabValue}>
                    <TabList
                      onChange={handleChangeTabValue}
                      variant="scrollable"
                      allowScrollButtonsMobile
                      scrollButtons
                      textColor="secondary"
                      TabIndicatorProps={{
                        style: { backgroundColor: 'white' },
                      }}
                      TabScrollButtonProps={{
                        style: {
                          backgroundColor: DEFAULT_COLOR_THEME,
                          color: 'white',
                        },
                      }}
                      sx={{
                        mt: 2,
                        backgroundColor: DEFAULT_COLOR_THEME,
                        '.MuiTab-root': {
                          top: 5,
                          color: 'white',
                        },
                        '.Mui-selected': {
                          color: `${DEFAULT_COLOR_THEME} !important`,
                          backgroundColor: 'white',
                          top: 5,
                          borderRadius: 2,
                        },
                      }}
                    >
                      {tabs.map((tab: string, index: number) => (
                        <Tab label={tab} value={`${index + 1}`} key={index} />
                      ))}
                    </TabList>

                    {tabs.map((tab: string, index: number) => {
                      const type =
                        tab === 'Lead Campaigns'
                          ? 'lead'
                          : tab === 'Messenger Campaigns'
                          ? 'messenger'
                          : tab === 'Traffic Campaigns'
                          ? 'traffic'
                          : 'sales';
                      const report: Report =
                        tab === 'Lead Campaigns'
                          ? leadCampaigns
                          : tab === 'Messenger Campaigns'
                          ? messengerCampaigns
                          : tab === 'Traffic Campaigns'
                          ? trafficCampaigns
                          : salesCampaigns;
                      return (
                        <TabPanel
                          value={`${index + 1}`}
                          sx={{
                            backgroundColor: '#d9d9d933',
                          }}
                          key={`report-${index}`}
                        >
                          <FacebookCampaignAnalytics
                            campaignReport={report}
                            fromDate={startRange}
                            toDate={endRange}
                            analyticsData={analyticsData}
                            brand={selectedBrand}
                            averageResponseTime={averageResponseTime}
                            leadInitiativeRate={leadInitiativeRate}
                            completedEngagementRate={completedEngagementRate}
                            messengerCampaigns={messengerCampaigns}
                            onOpenPreview={handleOpenPreview}
                            campaignType={type}
                          />
                        </TabPanel>
                      );
                    })}
                  </TabContext>
                ) : (
                  <Box component="div" sx={{ marginTop: '10px' }}>
                    {tabs.map((tab: string, index: number) => {
                      const type =
                        tab === 'Lead Campaigns'
                          ? 'lead'
                          : tab === 'Messenger Campaigns'
                          ? 'messenger'
                          : 'traffic';
                      const report: Report =
                        tab === 'Lead Campaigns'
                          ? leadCampaigns
                          : tab === 'Messenger Campaigns'
                          ? messengerCampaigns
                          : tab === 'Traffic Campaigns'
                          ? trafficCampaigns
                          : salesCampaigns;

                      return (
                        <Accordion
                          expanded={expanded === `panel${index + 1}`}
                          onChange={handleOnExpand(`panel${index + 1}`)}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: 'white' }} />
                            }
                            aria-controls={`panel${index + 1}a-content`}
                            id={`panel${index + 1}a-header`}
                            sx={{ backgroundColor: DEFAULT_COLOR_THEME }}
                          >
                            <Typography sx={{ color: 'white' }}>
                              {tab}
                            </Typography>
                          </AccordionSummary>

                          <AccordionDetails
                            sx={{ backgroundColor: '#d9d9d933' }}
                          >
                            <FacebookCampaignAnalytics
                              campaignReport={report}
                              fromDate={startRange}
                              toDate={endRange}
                              analyticsData={analyticsData}
                              brand={selectedBrand}
                              averageResponseTime={averageResponseTime}
                              leadInitiativeRate={leadInitiativeRate}
                              completedEngagementRate={completedEngagementRate}
                              messengerCampaigns={messengerCampaigns}
                              onOpenPreview={handleOpenPreview}
                              campaignType={type}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                )}
              </>
            ) : isFacebook && !analyticsLoading ? (
              <NoData />
            ) : null}

            {googleCampaigns?.data.length > 0 && isGoogle ? (
              <GoogleCampaignMetrics
                brand={selectedBrand}
                fromDate={startRange}
                toDate={endRange}
                data={googleCampaigns?.data || []}
                refreshToken={defaultRefreshToken}
              />
            ) : isGoogle && !analyticsLoading ? (
              <NoData />
            ) : null}

            <DrawerMenu
              anchor="right"
              open={openPreviewModal}
              onClose={handleCloseAdPreviewModal}
            >
              <FacebookAdDetails
                adId={selectedAd}
                brand={location || brand}
                onClose={handleCloseAdPreviewModal}
                isMobile={xsOnly}
              />
            </DrawerMenu>
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
