import moment from "moment";
import { setLastSeen } from "../services/chats";
import { Brand, ChatUser } from "../types";
import { CANNED_RESPONSE_FIELDS } from "./constants";
import { chatStatus } from "./constants/chatStatus";

export const isWaitingForResponse = (
  partialEngagement: boolean,
  completedEngagement: boolean,
  lastChat: string,
  lastEngagement: Date
) => {
  return (
    isCompletedEngagement(partialEngagement, completedEngagement) &&
    moment.utc(lastChat).local().format() <
      moment.utc(lastEngagement).local().format()
  );
};

export const isCompletedEngagement = (
  partialEngagement: boolean,
  completedEngagement: boolean
) => {
  return partialEngagement === true && completedEngagement === true;
};

export const isPartialEngagement = (
  partialEngagement: boolean,
  completedEngagement: boolean
) => {
  return partialEngagement === true && completedEngagement === false;
};

export const isUnengagedMessage = (
  partialEngagement: boolean,
  completedEngagement: boolean
) => {
  return partialEngagement === false && completedEngagement === false;
};

export const isNewMessage = (lastChat: string, lastSeen: string) => {
  if (lastSeen === undefined || lastSeen === null) {
    return true;
  }

  return (
    moment.utc(lastChat).local().format() >
    moment.utc(lastSeen).local().format()
  );
};

export const chatResult = (
  result: string,
  investment: string,
  brand: Brand
) => {
  if (result === "LeadSentToBrand") {
    return chatStatus.Lead;
  }
  if (result === "CallScheduled") {
    return chatStatus.Scheduled;
  }
  if (investment) {
    if (parseFloat(investment) >= brand.investmentRequirement?.num) {
      return chatStatus.Lead;
    }
  }
};

export const messageBuilder = (text: string, chat: ChatUser) => {
  let message = text;
  CANNED_RESPONSE_FIELDS.forEach((value: string) => {
    if (text.match(value)) {
      if (value === "<<lead>>")
        message = message.replace(value, chat?.name || "");
      if (value === "<<salesperson>>")
        message = message.replace(value, `${chat?.salesperson?.name || ""}`);
      if (value === "<<brand>>")
        message = message.replace(value, chat?.brand?.brand || "");
      if (value === "<<calendly_link>>")
        message = message?.replace(value, chat?.brand?.calendly || "");
    }
  });

  return message;
};

export const setChatSeen = async (chatId: string) => {
  const seenAt = new Date().toISOString();
  try {
    await setLastSeen(chatId, seenAt);
  } catch (error: any) {
    console.log(error);
  }
};
