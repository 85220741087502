import { Button } from '@mui/material';
import React from 'react';
import Spinner from '../Spinner';
import styles from '../../assets/styles/components/Buttons/GoogleLogin.module.scss';
import { XsOnly } from '../../utils/breakpoints';

interface GoogleLoginProps {
  loading?: boolean;
  title: string;
  handleOnClick?: () => void;
  startIcon?: React.ReactNode | null;
}

const GoogleLogin: React.FC<GoogleLoginProps> = ({
  loading,
  title,
  handleOnClick,
  startIcon,
}) => {
  return (
    <Button
      className={styles.google}
      type="button"
      size="small"
      onClick={handleOnClick}
      sx={{
        width: '100%',
        minHeight: '100%',
        textTransform: 'unset',
        color: '#757575',
        padding: `0px 16px 0px 42px`,
        transition: 'background-color 0.3s, box-shadow 0.3s',
        fontSize: '14px',
        border: 'none',
        borderRadius: '3px',
        boxShadow:
          '0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25)',
        fontWeight: '500',
        backgroundPosition: XsOnly() ? '100px 8px' : '12px 8px',
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
      }}
      startIcon={startIcon ? startIcon : null}
    >
      {loading ? <Spinner /> : title}
    </Button>
  );
};

export default GoogleLogin;
