import React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { ChangeEventType, MinMaxMinute } from '../../types';
import { PrimaryButton } from '../Buttons';
import styles from '../../assets/styles/components/Forms/Form.module.scss';
import PageHeader from '../PageHeader';
import { XsOnly } from '../../utils/breakpoints';

interface MinMaxMinutesFormProps {
  handleOnSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  handleOnChange: (e: ChangeEventType) => void;
  values: MinMaxMinute;
  loading: boolean;
}

const MinMaxMinutesForm: React.FC<MinMaxMinutesFormProps> = ({
  handleOnSubmit,
  handleOnChange,
  values,
  loading,
}) => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleOnSubmit}
      sx={{ width: '100%' }}
      className={`${styles.form} ${XsOnly() ? `${styles['-mobile']}` : ''}`}
    >
      <PageHeader title="Admin Preferences" />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            size="small"
            type="number"
            id="minTime"
            name="min"
            label="Min Time (minute/s)"
            onChange={handleOnChange}
            value={values.min}
            InputLabelProps={{ shrink: true }}
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            variant="standard"
            size="small"
            type="number"
            id="maxTime"
            name="max"
            label="Max Time (minute/s)"
            onChange={handleOnChange}
            value={values.max}
            InputLabelProps={{ shrink: true }}
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            multiline
            variant="standard"
            size="small"
            type="text"
            id="message"
            name="message"
            label="Message"
            onChange={handleOnChange}
            value={values.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <div className={`${styles.action} ${styles['-unsticky']}`}>
        <PrimaryButton title="Update" loading={loading} rounded />
      </div>
    </Box>
  );
};

export default MinMaxMinutesForm;
