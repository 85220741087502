import { useMediaQuery, useTheme } from '@mui/material';

export const DEFAULT_COLOR_THEME: string = '#096f4d';

export const StyledDataGrid = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  let baseStyle = {
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: `${DEFAULT_COLOR_THEME} !important`,
      color: 'white',
    },
    width: '100%',
  };

  if (!xsOnly) {
    return baseStyle;
  }

  return {
    ...baseStyle,
    overflow: 'hidden',
  };
};

export const StyledPaper = () => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  if (xsOnly) {
    return { maxWidth: '330px', overflow: 'hidden' };
  }

  return {};
};
