import axios from "axios";
import { ResizerReturnType } from "../utils";
import { apiBaseUrl } from "./api";

export const uploadLogo = async (image: ResizerReturnType) => {
  const data = await axios.post(`${apiBaseUrl}/image/upload`, { image });

  return data?.data;
};

export const deleteLogo = async (public_id: string) => {
  const data = await axios.post(`${apiBaseUrl}/image/remove`, { public_id });

  return data?.data;
};
